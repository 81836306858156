/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import CollectionsVideo from 'components/Classes/CollectionsVideo.jsx'
import Features from 'components/Classes/Features.jsx'
import PlansDetailElevation from 'components/Classes/PlansDetailElevation.jsx'
import PlansDetailFloorPlan from 'components/Classes/PlansDetailFloorPlan.jsx'
import PlansDetailGallery from 'components/Classes/PlansDetailGallery.jsx'
import CollectionsGallery from 'components/Classes/CollectionsGallery.jsx'
import CollectionsGoBack from 'components/Classes/CollectionsGoBack.jsx'
import PlansDetailHeader from 'components/Classes/PlansDetailHeader.jsx'
import PlansDetailMantle from 'components/Classes/PlansDetailMantle.jsx'
import PlansDetailOptions from 'components/Classes/PlansDetailOptions.jsx'
import InteractiveFloorPlans from 'components/Classes/InteractiveFloorPlans.jsx'
import SidebarSignUp from 'components/Classes/SidebarSignUp.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import StickyToolbar from 'components/Classes/Sticky_toolbar.jsx'
import Breadcrumb from 'components/Classes/breadcrumb'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
import VideoSlider from 'components/Classes/VideoSlider.jsx';
import InteractiveMediaTabs from 'components/Classes/InteractiveMediaTabs.jsx'
import InteractiveMediaIcons from 'components/Classes/InteractiveMediaIcons.jsx'

class PlansDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            plan: {},
            loaded: false,
            success: false

        }
    }

    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "planBySlug/" + this.props.match.params.plan)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        plan: result.data,
                        loaded: true,
                        success: true
                    });


                },

                (error) => {

                }
            )

    }

    componentWillReceiveProps(nextProps) {

        this.props = nextProps;

        this.fetchResult();
        

    }


    componentDidMount() {

        this.fetchResult();
        CallTrk.swap();

        // dataLayer.push({
        //     'event': 'communityId',
        //     'e1CommunityId': this.state.plan.community_id ? this.state.plan.community_id : "",
        //     'salesforceId': this.state.plan.communitySalesforceId ? this.state.plan.communitySalesforceId : ""
        // });
    }

    componentDidUpdate(){

        if(this.state.success == true){
            dataLayer.push({
                'event': 'communityId',
                'e1CommunityId': this.state.plan.community_id ? this.state.plan.community_id : "",
                'salesforceId': this.state.plan.salesforce_id ? this.state.plan.salesforce_id : ""
            });
           }

        CallTrk.swap();
    }


    render() {

        var window_width = screen.width;

        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                {this.state.success == true ? [

                    <Helmet>
                        <title>{this.state.plan.post_title}</title>
                        <body className="plans-detail"/>
                    </Helmet>,

                    <h2 className="hide--visually">{this.state.plan.post_title}</h2>,

                    <div className="sidebar-columns">
                    <div className="wrap wrap--sidebar-columns">
                    <div className="sidebar-column sidebar-column--content">
                    <CollectionsGoBack
                    className="plans-detail"
                    />

                    <Breadcrumb
                          items={
                              [
                                  {
                                      to:"/collections",
                                      title:"Collections"
                                  },{
                                      to:"/collection/"+this.state.plan.neighbourhood.post_name,
                                      title:this.state.plan.neighbourhood.post_title
                                  },{
                                        to:"/collection/"+this.state.plan.neighbourhood.post_name+"/"+this.state.plan.post_name,
                                        title:this.state.plan.post_title
                                  }

                              ]
                          }

                          planclass="plan_bread"
                        />

                    <PlansDetailHeader
                    plan={this.state.plan}
                    />

                    {this.state.plan.description != "" || this.state.plan.Plan_Features != false ? [

                        <Features
                        message1={<p
                            dangerouslySetInnerHTML={{__html: this.state.plan.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>}
                        message2=""
                        brochures=""
                        title2="Features"
                        />


                    ]: ['']}
                    {this.state.plan.interactiveFloorPlans != false || this.state.plan.Envision != '' ? [

                        <InteractiveMediaIcons interactive_media={this.state.plan.interactiveFloorPlans} Envision={this.state.plan.Envision} Show_Envision={this.state.plan.Show_Envision}/>    

                        ]:['']}

   
                    <PlansDetailElevation
                    plan={this.state.plan.post_title}
                    elevations={this.state.plan.Elevation}
                    />
                    </div>
                    {(window_width > 767 ) ? (
                            <aside className="sidebar-column sidebar-column--sidebar">
                            <div className="wrap--sidebar-column">
                            <div className="sidebar-column-inner">
                            <SidebarSignUp communityOfInterest={this.state.plan.salesforce_id} referenceId={this.state.plan.community_id} new_home_specialist={this.state.plan.new_home_specialist} agent_dre={this.state.plan.agent_dre} agent_name={this.state.plan.agent_name} community_name={this.state.plan.neighbourhood.post_title} neigh_detail= {this.state.plan.neighbourhood}/>
                            </div>
                            </div>
                            </aside>
                        ) : ('')}
                    </div>
                    
                   {/* 5959 */}
                   <div className="wrap">
                    {this.state.plan.gallery_images != "" ||  this.state.plan.gallery_videos != "" ? (

                    <CollectionsGallery gallery_videos={this.state.plan.gallery_videos} gallery_images={this.state.plan.gallery_images}/>

                    ) : ('')}
                    </div>

                       { this.state.plan.interactive_tab_details && this.state.plan.interactive_tab_content_details ? [

                        <InteractiveMediaTabs interactive_tab_details={this.state.plan.interactive_tab_details} interactive_tab_content_details={this.state.plan.interactive_tab_content_details} />

                        ]: ['']}
                    </div>,

                    <PlansDetailOptions neighbourhood={this.state.plan.neighbourhood} nextPlanPost={this.state.plan.nextPost}/>



                ] : ['']}

            </div>
        )
    }
}

export default PlansDetail
