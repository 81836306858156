import React from 'react'
import PropTypes from 'prop-types'
import Eventcalender from 'components/Classes/Calendar.jsx';
import {Link} from 'react-router-dom'
class LatestEvents extends React.Component {
    render () {
    //   const message = this.props.message
    //   const title = this.props.title


    const {all_event_dates,events,fetchEvents,pdfurl} = this.props;
  
      return (
          <section className="Latest-events">
             <div className="wrap wrap-latest-event-post">
                    <div className="event-container latest-event-container">
                        <div className="new-event">
                        {events.length > 0 ? (


                            events.map((event)=>(


                                <div className="latest-event-box">
                                   <Link to={'/event/'+event.post_name}>
                                    <div className="event-image">
                                        <img src={(event.post_thumbnail != "") ? event.post_thumbnail: ''}/>
                                        <p className={"event-date "+event.event_metas.event_type+"_date"} ><span>{event.startDate.month}</span>{event.startDate.day}</p>
                                    </div>
                                    <div className={"event-heading "+event.event_metas.event_type+"_date"}>
                                        <p>{event.post_title}</p>
                                    </div>
                                    </Link>
                                </div>    



                            ))

                        

                        ) : (
                            <div className="latest-event-box no-event-box">
                                <img className="No-events" src={require('images/Altis_ Event_default.jpg')} alt="" />
                            </div>

                        )}
                            
                        </div>
                        <div className="event-calender">
                                <Eventcalender all_event_dates={all_event_dates} fetchEvents={fetchEvents}/> 
                                <div className="event_label_list">
                                    <div className="event_symbols">
                                        <p className="public_symbol event_symbol"><span className="a"></span> <span className="b">Future Homeowner Event</span></p>
                                        <p className="resident_symbol event_symbol"><span className="a"></span> <span className="b">Resident Event</span></p>
                                    </div>
                                    { pdfurl ? [
                                        <a href={pdfurl} target="_blank" className="daily_events">Daily <span>Activities</span></a>
                                    ]:['']
                                    }
                                    
                                </div>    
                        </div>    
                    </div>
                </div>    
          </section>
      )
    }
    }

    // UpcomingEvents.propTypes = {
    //     message: PropTypes.object,
    //     title: PropTypes.string
    //   }
      
      export default LatestEvents    