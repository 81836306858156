import React from 'react'
import PropTypes from 'prop-types'

class AltisSupport extends React.Component {

    constructor(){

        super();
        this.state = {

            show:true
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){

        this.setState({
            show:false
        });
    }

    render(){

        const message = this.props.message;

        return(
              <div>
                  {this.state.show == true ? [

                    <div className="altis_support_container">
                        <div className="supportMessage">{message}</div>
                        <span className="close_altis_support" onClick={this.handleClick}>X</span>
                    </div>
                  ]: ['']}

                 

              </div>
                
       )
    }

   
}

export default AltisSupport