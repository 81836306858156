import React from 'react'
import { Element } from 'react-scroll'
import {
    isMobile,
    isTablet,
    deviceDetect
  } from "react-device-detect";
class InteractiveFloorPlans extends React.Component {

    constructor(){
        super()
    }


    componentDidMount(){

        var that = this;

        this.updateIframeHeight();


        window.addEventListener("orientationchange", function() {
            that.updateIframeHeight();
        });


    }

    updateIframeHeight(){

    
        if(isMobile && window.screen.availWidth <= 1366 ){

            this.setState({height:window.screen.availWidth});
        }

       


    }

    render(){

        const forQMI = this.props.forQMI ? true : false;
        const forNbd = this.props.forNbd ? true : false;
    
        const customHeight = this.props.forheight ? this.props.forheight : false;
        
        var height = '';
        
        if(customHeight !='' && window.screen.availWidth >= 1280){
            height = this.props.forheight;
        }else{
            height = (this.state && this.state.height) ? this.state.height : 688;
        }
         const interactiveFloorPlans = this.props.interactiveFloorPlans
         var iframe__url;
         var iframe_link;
         iframe__url = iframe_link = '';
         iframe_link = interactiveFloorPlans;
         iframe_link.includes("&amp;") ? [
            iframe__url = iframe_link.replace("&amp;", "&")
         ]:[
            iframe__url = iframe_link
         ]
        return (
            <Element id="interactive-floors-plans">
                <div className="wrap">
                    <div className="interactive-floor-plans-container inner_wrap">
                    {/* 5959 */}
                    <h2 class="featured-plans-header__title1">
                    {forNbd == true ? ['Sitemap'] :['Interactive Floor Plan']}
                    </h2>
                    {interactiveFloorPlans.length > 0 ? [

                            // <ul class="floor_blueBtn">

                            //     {interactiveFloorPlans.map(plan=>(

                            //         <li><a href={plan.plan_interactive_url} target="__blank">{plan.plan_interactive_title}</a></li>

                            //     ))}
                        
                            // </ul>,

                            forNbd == true ? [
								<iframe className="floorplan_interactive" src={iframe__url +(isMobile ? "?mode=mobile" : "")} width="100%" height={height}/>
							] : [
                            forQMI == false ? [
                                
                                    <iframe className="floorplan_interactive" src={interactiveFloorPlans[0].plan_interactive_url+(isMobile ? "?mode=mobile" : "")} width="100%" height={height} />

                            ] : [

                                 <iframe className="floorplan_interactive" src={interactiveFloorPlans[0].qmi_interactive_url+(isMobile ? "?mode=mobile" : "")} width="100%" height={height} />
                            ]
                        ]

                            
                            


                    ] : ['']}
                
                    </div>
                </div>
           </Element>

        )
    }

}

export default InteractiveFloorPlans