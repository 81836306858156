import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class CollectionsGoBack extends React.Component {
  constructor (props) {
    super(props)
    this.handleGoBack = this.handleGoBack.bind(this)
  }

  handleGoBack () {
    this.props.history.goBack()
  }

  render () {
    const className = this.props.className ? `collections-back collections-back--${this.props.className}` : 'collections-back'

    return (
      <section className={className}>
        <div className="wrap wrap--collections-back">
          <button type="button" onClick={this.handleGoBack} className="collections-back__button">Back</button>
        </div>
      </section>
    )
  }
}

CollectionsGoBack.propTypes = {
  className: PropTypes.string
}

export default withRouter(CollectionsGoBack)
