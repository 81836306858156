import React from 'react'
import { Helmet } from 'react-helmet' // eslint-disable-line no-unused-vars
import FaqMain from 'components/Classes/FaqMain.jsx' // eslint-disable-line no-unused-vars
import SplitMantle from 'components/Classes/SplitMantle.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class FAQ extends React.Component {

    constructor(){
        super()

        this.state = {
            success:false,
            page: {},
            loaded:false
        }

    }

    componentDidMount(){

        fetch(apiConfig().API_URL + "faq_info")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        page: result.data,
                        success: result.success,
                        loaded:true
                    });

                    console.log("Faq APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();

    }

    componentDidUpdate(){

        CallTrk.swap();
    }


  render () {
    return (
      <div>
          {this.state.loaded == false ? <AltisLoader/> : ''}
        <Helmet>
          <title>{this.state.success ? this.state.page.post_title : '' }</title>
          <body className="faq" />
        </Helmet>
        {/* <Overlaychat /> */}
        <h2 className="hide--visually">FAQ</h2>

          {this.state.success ? [

              (this.state.page.page_meta.split_images.length > 1 ? [
              <SplitMantle
                  images={[{ image: this.state.page.page_meta.split_images[0].image.url }, { image:  this.state.page.page_meta.split_images[1].image.url }]}
                  message={this.state.page.page_meta.split_images_info[0].heading ? this.state.page.page_meta.split_images_info[0].heading : '' }
                  title={this.state.page.page_meta.split_images_info[0].sub_heading ? this.state.page.page_meta.split_images_info[0].sub_heading : '' }
              />

          ] : ('')),

              <FaqMain page={this.state.page} />

              ] : ('')}
      </div>
    )
  }
}

export default FAQ
