import React from 'react'
import PropTypes from 'prop-types'

class InfoBase extends React.Component {
  render () {
    const image = this.props.image
    const message = this.props.message
    const title = this.props.title

    return (
      <section className="info-block-base">
        <div className="info-block-base-shim"></div>
        <h2 className="hide--visually">{title}</h2>

        <div className="wrap--info-block-base">
          <div className="wrap-inner">
            <div className="info-block-base-columns">
              {image ? (
                <div className="info-block-base-column info-block-base-column--image">
                  <img src={image} className="info-block-base__image" alt={title} />
                </div>
              ):('')}
              {message ? (
              <div className="info-block-base-column info-block-base-column--content">
                <div className="info-block-base__info">
                  {message}
                </div>
              </div>
              ):('')}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

InfoBase.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default InfoBase
