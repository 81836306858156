import React from 'react'
import $ from 'jquery'

class PackagesList extends React.Component {

  componentDidMount() {
    if(screen.width > 480){
      var card_section1 = [];
      var card_section2 = [];
      
      $(document).find(".virtual_box").each(function() {
          card_section1.push($(this).find(".virtual_title").outerHeight());
          card_section2.push($(this).find(".virtual_btn").outerHeight());
      });
      
      var card_maxheight1 = Math.max.apply(Math, card_section1);
      var card_maxheight2 = Math.max.apply(Math, card_section2);
      $(document).find(".virtual_title").css("min-height", card_maxheight1);
      $(document).find(".virtual_btn").css("min-height", card_maxheight2);
  }
  }

  render () {
 
    const {packages,packageTitle,packagedescription,CollectionsDetail} = this.props;
    const package_class = CollectionsDetail == false ? 'virtual' : '';
    return (
      <section className={"packages "+package_class}>
        <h2 className="hide--visually">Packages</h2>
        <div className="wrap wrap--locations">
          <div className="wrap-inner">
                 { CollectionsDetail == true ? [
                   <div className="package_title">
                    { packageTitle ? [
                        <h3 className="upcoming-event-title">{packageTitle}</h3>
                    ] : ['']

                    }
                </div>,
                
                   packagedescription ? [
                       <div className="package_description upcoming-event-description">  
                        <p>{packagedescription}</p>
                       </div>
                   ] : [''],
                    
               <div className="package_wrapper">
                   {packages.map((collectionpackage) => (
                           <div className="package-list">
                               <div className="package-column package-column--image">
                                   <div className="package-image">
                                   { collectionpackage.package_url ? [
                                         <a href={collectionpackage.package_url} className="featured-plans__link" target="_blank">
                                               <img src={collectionpackage.post_thumbnail ? collectionpackage.post_thumbnail : require('media/default-160x307.png')}/>
                                               <span className="featured-plans-overlay"></span>
                                         </a>
                                       ]: [
                                         <img src={collectionpackage.post_thumbnail ? collectionpackage.post_thumbnail : require('media/default-160x307.png')}/>
                                       ] }
                                   </div>
                               </div>

                               <div className="package-column package-column--content">
                                     <div className="package-info-columns package-info-">
                                         <div className="package-info-column package-info-column--title">
                                             <h3 className="package-info__title">{collectionpackage.post_title}</h3>
                                         </div>
                                     </div>

                                     <div className="package-info-columns package-details">
                                         <small className="package-info__caption">Details</small>
                                         <div className="package-info__message" dangerouslySetInnerHTML={{__html: collectionpackage.short_description}}></div>
                                     </div>

                                     <div className="package-info-columns package-buttons">
                                     {
                                       collectionpackage.package_url ? [
                                           <a href={collectionpackage.package_url} target="_blank" className="package__button">Details</a>
                                       ]:['']
                                     }
                                         
                                     </div>
                               </div>
                           </div>
                   ))}
                </div>

                 ]:[
                  <div className="virtual_wrapper">
                      {packages.map((pack) => (
                          <div className="virtual_box">
                              {pack.post_title != '' &&  pack.post_title != null  ? [
                                  <h2 className="virtual_title">{pack.post_title}</h2>
                              ]:['']
                              }
                              <div className="virtual_image">
                                  { pack.package_url != '' && pack.package_url != null ? [
                                      <a href={pack.package_url} className="featured-plans__link" target="_blank">
                                              <img src={pack.post_thumbnail ? pack.post_thumbnail : require('media/default-160x307.png')} alt={pack.post_title}/>
                                              <span className="featured-plans-overlay"></span>
                                      </a>
                                      
                                  ]:[
                                    <img src={pack.post_thumbnail ? pack.post_thumbnail : require('media/default-160x307.png')} alt={pack.post_title}/>
                                  ] }
                              </div>
                              { pack.package_url != "" && pack.package_url != null ? [
                                  <div className="virtual_btn">
                                      <a href={pack.package_url} target="_blank">Details</a>
                                  </div> 
                              ]:[''] }   
                          </div>
                      ))}
                  </div>
                 ] }

          </div>
        </div>
      </section>
    )
  }
}

export default PackagesList
