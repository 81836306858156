import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class EventCategories extends React.Component {

    constructor(props){

        super(props)
        this.state = {
            width: window.innerWidth,
            responsive:{
                0:{
                    items:1,
                },
                480:{
                    items:2,
                },
                1024:{
                    items:3,
                },
            },
          };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }
    componentWillMount() {
        window.addEventListener('resize', this.updateWindowDimensions());
      }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
     }
    render () {
    const {heading,events} = this.props;
    const { width } = this.state;
 
      return (
          <section className="Events-list">
             <div className="wrap wrap-event-list-post">
                    <div className="event-container event-list-container">
                        <div className="event-title-wrapper">
                                    <h2 className="event-title">Past Events</h2>
                        </div>
                {events.length > 0 ? [
                    width <= 1024  ? (
                        <OwlCarousel
                            className={'owl-theme past-event'}
                            loop={false}
                            nav={true}
                            dots={false}
                            stagePadding={50}
                            responsive={this.state.responsive} 
                                >
                        
                            {events.map((event)=>(
                                <div className="item">
                                <div className="past-event-box">
                                    <a href={event.category_link}>
                                    <div className="event-image">
                                        <img src={(event.post_thumbnail && event.post_thumbnail != "") ? event.post_thumbnail: ''}/>
                                        <p className={"event-heading "}>{event.post_title}</p>
                                    </div>
                                    </a>
                                </div>  
                                </div>  
                                   
                            ))}

                        </OwlCarousel>
                    ) : (   
                        events.map((event)=>(
                            <div className="past-event-box">
                                <a href={event.category_link}>
                                <div className="event-image">
                                    <img src={(event.post_thumbnail && event.post_thumbnail != "") ? event.post_thumbnail: ''}/>
                                    <p className={"event-heading "}>{event.post_title}</p>
                                </div>
                                </a>
                            </div>   
                        ))
                    ) 
                         ] : ['']}
                    </div>
                </div>    
          </section>
      )
    }
    }


      
      export default EventCategories    