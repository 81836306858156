import React from 'react'
import { Link } from 'react-router-dom' 
class Breadcrumb extends React.Component {

    constructor(props){
        super(props)
        console.log("BreadCrumb Props");
        //console.log(props);
    }

    render(){

        const items = this.props.items ? this.props.items: false;

        const planClass = this.props.planclass ? this.props.planclass: '';
     

            return (
                    <React.Fragment>
                    {
                        items ? [
                            <section className={"breadcrumbs "+(planClass)}>   
                                <div className="wrap wrap--collections-back">                 
                                    {items.map((item,i)=>(

                                        i == 0 ? [
                                            <span><Link to={item.to}>{item.title}</Link></span>
                                        ] : [
                                            <span> / <Link to={item.to}>{item.title}</Link></span>
                                        ]
                                    
                                    ))}
                                </div> 
                            </section>
                        ] : ['']
                        
                    }
                    </React.Fragment>
            )
     }

}

export default Breadcrumb