/* eslint-disable no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import {AppContainer} from 'react-hot-loader'
import {BrowserRouter} from 'react-router-dom'
import App from './components/App.jsx'
import ScrollToTop from './components/Classes/ScrollToTop.jsx'

// Vendor
import FastClick from 'fastclick';
import Modernizr from 'modernizr';

import "babel-polyfill";
import "isomorphic-fetch";

// Promise polyfills
require('es6-promise').polyfill()

// Baseline styles
require('./app/fonts/fonts.scss')
require('./app/styles/app.scss')

// Component styles
var context = require.context('./components/', true, /\.(css|scss|sass|js|jsx)$/)
var modules = {}
context.keys().forEach(function (key) {
    var module = context(key)
    modules[key] = module
})

// Test for IOS
Modernizr.addTest('ios', () => {
    return navigator.userAgent.match(/(iPad|iPhone|iPod)/g)
})

// FastClick
FastClick.attach(document.body)

// React app
const render = () => {
    ReactDOM.render(
        <AppContainer>
            <BrowserRouter basename={REACT_CONFIG.NAMESPACE}
            >
                <ScrollToTop>
                    <App/>
                </ScrollToTop>
            </BrowserRouter>
        </AppContainer>,
        document.getElementById('root')
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./components/App.jsx', () => {
        var reloadedContext = require.context('./components/', true, /\.(css|scss|sass|js|jsx)$/)
        var changedModules = reloadedContext.keys()
            .map(function (key) {
                return [key, reloadedContext(key)]
            })
            .filter(function (reloadedModule) {
                return modules[reloadedModule[0]] !== reloadedModule[1]
            })
        changedModules.forEach(function (module) {
            modules[module[0]] = module[1]
        })
        render(App)
    })
}
