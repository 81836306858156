import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import BlogPosts from "../../Classes/BlogPosts"; // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import CategoryMantle from 'components/Classes/CategoryMantle.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class CategoryDetail extends React.Component {

    constructor(){
        super()
        this.state = {
            posts: [],
            category:{},
            success:false,
            pageTitle: '',
            loaded: false
        }
    }

    componentDidMount(){

        fetch(apiConfig().API_URL+ "category_info/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        posts: result.data.posts,
                        category:result.data.category,
                        pageTitle:result.data.pageTitle,
                        success:true,
                        loaded: true
                    });

                    console.log("Blog APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();


    }

    componentDidUpdate(){

        CallTrk.swap();
    }
    
    render () {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}

                {
                    (this.state.success && this.state.posts.length > 0) ? [

                        <Helmet>
                            <title>{this.state.pageTitle}</title>
                            <body className="blog" />
                        </Helmet>,

                        // <Overlaychat />,

                        <CategoryMantle title={this.state.category.cat_name}
                            message={this.state.category.category_description}
                        />,

                        <BlogPosts relatedPosts={this.state.posts} related={true} />

                    ] : ('')

                }



            </div>
        )
    }

}

CategoryDetail.propTypes = {
    related: PropTypes.bool
}

export default CategoryDetail
