import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars

import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class PlansDetailGallery extends React.Component {

  render () {
      const galleryImages = this.props.galleryImages
    return (
      <section className="plans-detail-gallery">
        <Element id="gallery">
          <div className="plans-detail-gallery-columns">
            <div className="plans-detail-gallery-column plans-detail-gallery-column--1">

                {galleryImages.length > 0 ? [

                    <div className="photo_gallery_thumbnail"><p>Photo Gallery</p><span>{galleryImages.length} Photos</span></div>,
                    <ul className="plans-detail-gallery__list">
                        {galleryImages.map((g)=>(

                                    <li className="plans-detail-gallery__item">
                                    <a href={g.neighborhood_gallery_image.url} data-fancybox data-fancybox-group="gallery" data-caption={g.image_caption} className="plans-detail-gallery__link">
                                        <img src={g.neighborhood_gallery_image.url} className="plans-detail-gallery__image" alt="Gallery" />
                                        <span className="plans-detail-gallery-overlay"></span>
                                    </a>
                                   </li>
                          

                        ))}

                    </ul>


                ] : ['']}

            </div>
          </div>
        </Element>
      </section>
    )
  }
}

export default PlansDetailGallery
