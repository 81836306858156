import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import BasicMantle from 'components/Classes/BasicMantle.jsx'
import EventDetailContent from 'components/Classes/EventDetailContent.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import PostDetailSlider from 'components/Classes/PostDetailSlider.jsx'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class EventDetail extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            event: [],
            success: false,
            loaded: false
        };
       
    }

    fetchEvent = () => {

        fetch(apiConfig().API_URL + "event/"+this.props.match.params.id)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({

                    event:result.data,
                    success: true,
                    loaded: true
                });


            },

            (error) => {

            }
        )

    }

    componentDidMount() {

        this.fetchEvent();

        CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {

        const {event,loaded,success} = this.state;

        return (
            <div>
                    {loaded == false ? <AltisLoader/> : ''}

                    {success ? (

                            <section className="EventDetail">              
                            <Helmet>
                                <title>{event.postTitle}</title>
                                <body className="event"/>
                            </Helmet>

                            {/* <Overlaychat /> */}
                            {/* <BasicMantle
                                    image={event.post_thumbnail}
                            /> */}
                            <div className="EventDetail__hero_slider">
                                <div className="">
                                        <PostDetailSlider gallery={event.event_metas.hero_slider_gallery} showDefaultImage={event.post_thumbnail ? event.post_thumbnail : false}  />
                                </div>
                            </div>
                           
                            <EventDetailContent event={event}/>
                            <h2 className="hide--visually">Event</h2>
                            </section>


                    ) : ('')}

                   
            </div>
        )
    }
}

export default EventDetail