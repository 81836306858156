import React from 'react'
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'
import fancybox from '@fancyapps/fancybox'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class PostDetailSlider extends React.Component {


    render() {
        const id = this.props.id

        const showDefaultImage = this.props.showDefaultImage ? this.props.showDefaultImage : false;

        console.log("Default Image");
        console.log(showDefaultImage);
        const images = [
            {image: require('media/location-1.jpg')},
            {image: require('media/location-2.jpg')},
            {image: require('media/location-3.jpg')},
            {image: require('media/location-4.jpg')},
            {image: require('media/location-5.jpg')},
            {image: require('media/location-6.jpg')}
        ]
        const name = this.props.name
        const settings = {
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        }
      
        return (
        <React.Fragment>
            {this.props.gallery  && this.props.gallery != 0 && this.props.gallery.length > 0 ? [


                <section className="collections-detail-slider">
                <Slider {...settings}>

                    {

                        this.props.gallery.map((g) => (

                            g.type == 'image' ? [

                            <div>
                                <div className="collections-detail-slider-holder">
                              
                                    <img src={ g.image.url}
                                        className="collections-detail-slider__image"
                                        />
                                    {/* <span className="collections-detail-slider-overlay">
                                        <h3 className="collections-detail-slider__title">{name}</h3>
                                    </span> */}
                                    {
                                        g.image_caption.trim() != "" ? [

                                            <div className="collections-caption"><p>{g.image_caption}</p></div>

                                        ] : ['']
                                    }
                                </div>
                            </div>



                            ] : [

                            <div>
                                <div className="collections-detail-slider-holder">
                              
                                    <img src={g.video_image.url}
                                        className="collections-detail-slider__image"
                                        />

                                        <a href={g.video_url} data-fancybox data-arrows="false" data-infobar="false" data-type="iframe"  data-iframe='{"preload":false}'
                                                            className="amenities-slider-1__link" draggable="true">


                                                                <i className="icon icon--play">
                                                                    <span className="hide--visually">Play Video</span>
                                                                </i>
                                        </a>

                                    {/* <span className="collections-detail-slider-overlay">
                                        <h3 className="collections-detail-slider__title">{name}</h3>
                                    </span> */}
                                    {/* <div className="collections-caption"><p>{}</p></div> */}
                                </div>
                            </div>


                            ]
                            


                        ))


                   }


                </Slider>

                </section>



            ] : [

                showDefaultImage ? [
                    <section className="collections-detail-slider">
                        <div>
                            <div className="collections-detail-slider-holder">
                      
                                <img
                                    src={showDefaultImage}
                                    className="collections-detail-slider__image" />
                                                    {/* <span className="collections-detail-slider-overlay">
                                    <h3 className="collections-detail-slider__title">{name}</h3>
                                </span> */}
                            </div>
                        </div>
                    </section>


                ] : ['']

            ]}
           
            </React.Fragment>
        )
    }
}

PostDetailSlider.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
}

export default PostDetailSlider
