import React from 'react'
import PropTypes from 'prop-types'
import {Link, NavLink} from 'react-router-dom' // eslint-disable-line no-unused-vars
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars
import {getBreakpoint, toggleClass, apiConfig} from 'components/Classes/Helpers.jsx'
import AltisJoinForm from 'components/Classes/AltisJoinForm.jsx'
import StickyToolbar from 'components/Classes/Sticky_toolbar.jsx'
import Altisappointment from 'components/Classes/Altisappointment.jsx'
import ScheduleTour from 'components/Classes/ScheduleTour.jsx'
import $ from 'jquery'

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.removeClass = this.removeClass.bind(this);

        this.state = {
            menus: [],
            siteInfo: [],
            agent_info: [],
            logoState:0,
            show_covid:false,
            success:false
        }

        this.handleScroll = this.handleScroll.bind(this);

        
    }

    componentDidMount() {

        window.addEventListener('scroll', this.handleScroll.bind(this));

        fetch(apiConfig().API_URL + "header_info")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        menus: result.data.menus,
                        siteInfo: result.data.siteInfo,
                        neighbourhoods: result.data.neighbourhoods,
                        agent_info: result.data.agent_info[0],
                        agent_name: result.data.agent_name,
                        agent_dre: result.data.agent_dre,
                        show_covid: result.data.show_covid,
                        success:true
                    });

                    console.log("Header APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

           
    }



    handleScroll(event) {
        
        if($(window).scrollTop() >= 160){
           this.setState({ 
                logoState:1
            });
            $('.altis-joinBtn_top').show();
        }else{
           this.setState({
               logoState:0
           });
           $('.altis-joinBtn_top').hide();
        }
       
     }

    removeClass(className, transitionName, transitionDuration) {
        if (getBreakpoint() < 768) {
            toggleClass(className, transitionName, transitionDuration)
        }
    }

    render() {
        const title = this.props.title ? this.props.title : 'Citro';
        var window_width = screen.width;
        var page_slug;

        return (
            <header id="header" className="header">
                <nav className="header-nav">
                    <button
                        className="mobile-menu__button-close"
                        onClick={() => toggleClass('menu-active', 'menu-transition', 300)}
                        title="Close Menu"
                        type="button"
                    >Close Menu
                    </button>

                    <div className="wrap wrap--header-nav">
                        <div className="altis-main-logo">
                            {this.state.logoState == 1 ? [
                                <NavLink to={"/home"}>
                                    <img src={require('images/logo-top.png')} />
                                </NavLink>
                            ] : ['']}
                        </div>
                        <ul className="header-nav__list">                   
                            {
                                this.state.menus.map(item => (
                                    item.menu_item_parent == 0 ? [ 
                                    <li className="header-nav__item">
                                        <NavLink
                                            activeClassName="header-nav__link--active"
                                            className="header-nav__link"
                                            onClick={() => this.removeClass('menu-active', 'menu-transition', 300)}
                                            to={(item.type != "custom") ? "/"+item.page_slug : item.url}
                                        >{item.title}{ item.child_count > 0 ? [<span className="header_caret"></span>]:[''] }</NavLink>

                                        { item.child_count > 0 ? [
                                             <ul className="header_dropdown">
                                                { item.sub_menus.map(sub_item => (
                                                        <li className="dropdown__item">
                                                            <NavLink
                                                                activeClassName="dropdown__link--active"
                                                                className="dropdown__link"
                                                                onClick={() => this.removeClass('menu-active', 'menu-transition', 300)}
                                                                to={(sub_item.type != "custom") ? "/"+sub_item.page_slug : sub_item.url}
                                                            >{sub_item.title}</NavLink>
                                                        </li>
                                                ))
                                                } 
                                            </ul>
                                        ]:['']
                                        }

                                    </li>
                                    ]:[''] 
                                ))
                            }
                        </ul>
                        <div className="altis-joinBtn_top">
                         {/* {this.state.logoState == 1 ? [  */}
                            <a className="header__join join_btn_header join_btn_new" href="javascript:void(0)">Join the interest list</a>
                          {/* ] : ['']}   */}
                        </div>
                    </div>
                </nav>

                <button
                    className="mobile-menu__button-overlay"
                    onClick={() => toggleClass('menu-active', 'menu-transition', 300)}
                    title="Close"
                    type="button"
                >Close
                </button>

                <div className="header-content">
                    <div className="wrap wrap--header-content">
                        <div className="header-columns">
                            <div className="header-column header-column--logo">
                                <button
                                    className="mobile-menu__button-open"
                                    onClick={() => toggleClass('menu-active', 'menu-transition', 300)}
                                    title="Menu"
                                    type="button"
                                >Menu
                                </button>

                                <div className="logo" title={title} itemScope itemType="http://schema.org/Organization">
                                    <Link to="/" className="logo__link" itemProp="url">{title}</Link>
                                    <meta itemProp="logo" content={require('./images/citro-logo.png')}/>
                                </div>
                            </div>

                            <div className="header-column header-column--message">
                                {/* <p className="header__message">{this.state.siteInfo.tagline != "undefined" ? this.state.siteInfo.tagline : ''}</p> */}
                            </div>

                            <div className="header-column header-column--join">
                            <a className="header__join join_btn_header join_btn_new" href="javascript:void(0)">Join the interest list</a>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.success ? [
                // <AltisJoinForm communityOfInterest={this.state.agent_info.community_of_interest} referenceId={this.state.agent_info.community_id} agent_info={this.state.agent_info} agent_name={this.state.agent_name} agent_dre={this.state.agent_dre} community_name="Citro"/>,
                (window_width < 767 ) ? [  
                    <StickyToolbar agent_info={this.state.agent_info} communityOfInterest={this.state.agent_info.community_of_interest} referenceId={this.state.agent_info.community_id} post={this.state.neighbourhoods} />,
                    this.state.neighbourhoods.map(item => (
                        page_slug = '',
                        page_slug = item.post_name,
                        location.href.indexOf(page_slug) > -1 ? [
                            <ScheduleTour post={item} />
                        ]:['']
                    ))
                ] :['']
           
                ] : [''] }

                <Altisappointment show_covid={this.state.show_covid} message="To mitigate the spread of the coronavirus, we are currently open BY APPOINTMENT ONLY.
                    Please call our New Home Specialist to schedule your one-on-one appointment."/>
            </header>
        )
        
    }
}

Header.propTypes = {
    title: PropTypes.string.isRequired
}

export default Header
