import React from 'react'
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'

import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class QmiDetailSlider extends React.Component {
    render() {
        const id = this.props.id
        const images = [
            {image: require('media/location-1.jpg')},
            {image: require('media/location-2.jpg')},
            {image: require('media/location-3.jpg')},
            {image: require('media/location-4.jpg')},
            {image: require('media/location-5.jpg')},
            {image: require('media/location-6.jpg')}
        ]
        const name = this.props.name
        const settings = {
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        }
 
        return (
            <section className="collections-detail-slider">
                <Slider {...settings}>

                    {this.props.gallery.length > 0 ? [

                        this.props.gallery.map((g) => (


                            <div>
                                <div className="collections-detail-slider-holder">
                               
                                    <img src={g.image.url}
                                         className="collections-detail-slider__image"
                                         alt={name}/>
                                    {/* <span className="collections-detail-slider-overlay">
                                        <h3 className="collections-detail-slider__title">{name}</h3>
                                    </span> */}
                                    {g.image_caption ? (
                                        <div className="collections-caption"><p>{g.image_caption}</p></div>
                                    ):('')}
                                </div>
                            </div>


                        ))


                    ] : [


                        <div>
                            <div className="collections-detail-slider-holder">
                          
                                <img
                                    src={this.props.post_thumbnail ? this.props.post_thumbnail : require('media/default-699x447.png')}
                                    className="collections-detail-slider__image"
                                    alt={name}/>
                                {/* <span className="collections-detail-slider-overlay">
                <h3 className="collections-detail-slider__title">{name}</h3>
              </span> */}
                            </div>
                        </div>


                    ]}
                </Slider>

                {this.props.qmi_headline != '' ?(
                <div className="collections-detail-headline">
                     <p>{this.props.qmi_headline}</p>
                 </div>
                ):
                ('')}  


                <div className="collections-detail-slider-info">
                    <ul className="collections-detail-slider-info__list">
                        {this.props.qmi_details.bed != "" && this.props.qmi_details.bed != null && this.props.qmi_details.bed != 0 ? [

                            <li className="collections-detail-slider-info__item">
                                <p className="collections-detail-slider-info__title">
                                    {this.props.qmi_details.bed > 1 ? ['Beds'] : ['Bed']}
                                </p>
                                <p className="collections-detail-slider-info__info">{this.props.qmi_details.bed}</p>
                                
                            </li>

                            ] : ['']}

                        {this.props.qmi_details.bath != ""  && this.props.qmi_details.bath != null && this.props.qmi_details.bath != 0 ? [

                        <li className="collections-detail-slider-info__item">
                            <p className="collections-detail-slider-info__title">
                                {this.props.qmi_details.bath > 1 ? ['Baths'] : ['Bath']}
                            </p>
                            <p className="collections-detail-slider-info__info">{this.props.qmi_details.bath}</p>                               
                        </li>
                        ] : ['']}

                        {this.props.qmi_details.sqft != "" && this.props.qmi_details.sqft != null && this.props.qmi_details.sqft != 0 ? [

                        <li className="collections-detail-slider-info__item">
                            <p className="collections-detail-slider-info__title">Sq Ft</p>
                            <p className="collections-detail-slider-info__info"><NumberFormat value={this.props.qmi_details.sqft} displayType={'text'} thousandSeparator={true} /></p>
                        </li>

                        ] : ['']}

                        {this.props.planInfo.post_title != "" ? [

                        <li className="collections-detail-slider-info__item">
                            <p className="collections-detail-slider-info__title">Plan</p>
                            <p className="collections-detail-slider-info__info">{this.props.planInfo.post_title}</p>
                        </li>

                        ] : ['']}

                        {(this.props.qmi_price != null && this.props.qmi_price != '') || this.props.pricing_prefixes == "Pricing...We're working on it." ? (
                            <li className="collections-detail-slider-info__item">
                                <p className="collections-detail-slider-info__title">{this.props.pricing_prefixes != 'None' ? this.props.pricing_prefixes : 'Price'}</p>
                                {
                                    this.props.qmi_price != null && this.props.qmi_price != '' && this.props.pricing_prefixes != "Pricing...We're working on it." ? [
                                        <p className="collections-detail-slider-info__info"><NumberFormat value={this.props.qmi_price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                                    ]:['']
                                }
                            </li>
                        ) : ('')}
                    </ul>
                </div>

                {this.props.qmi_address != '' ?(
                                    <div className="collections-detail-headline">
                                    <p><i class="fas fa-map-marker-alt"></i> {this.props.qmi_address}</p>
                                </div>
                ):
                ('')}              
            </section>
        )
    }
}

QmiDetailSlider.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
}

export default QmiDetailSlider
