import React from 'react'
import PropTypes from 'prop-types'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import AltisJoinForm from 'components/Classes/AltisJoinForm.jsx'
import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import $ from 'jquery';
class StickyToolbar extends React.Component {

  constructor(props){
    super(props);

    console.log("Sticky toolbar props");
    console.log(props);
    this.state = {
      post: this.props.post
    }
    //this.setChatButton = this.setChatButton.bind(this)
}

  componentDidMount() {
   $(".join_interest_form_div .join_interest").click(function(){
     $("#form-box-div").show();
     $("body").addClass("body_overflow");
   })
   
   $(".join_interest_form_div .signup_close").click(function(){
      $("#form-box-div").hide();
      $("body").removeClass("body_overflow");
    })

    //this.setChatButton(this.props.referenceId, 1, "join_chat");
  }
  // setChatButton(referenceId, engagementId, chatContainer) {

  //         var that = this;

  //         var time = new Date().getTime();

  //         var chatButton = document.getElementById(chatContainer);

  //         var overlayChat = document.getElementById("chat-overlay");

  //         var chatEngConfiguration = {
  //                 "referenceId":referenceId,
  //                 "engagementId":1,
  //                 "channel":"chat"
  //             };
          

  //     chatButton.onclick = function() {



  //       if($("#"+chatContainer).text() == "Live Chat"){

  //                 lpTag.sdk.exec("caoEngager.startChat", chatEngConfiguration);

  //       }

  //         };


  //       //   overlayChat.onclick = function() {

  //       //         //  alert(referenceId);

  //       // //if($("#"+chatContainer).text() == "Live Chat"){


  //       //           lpTag.sdk.exec("caoEngager.startChat", chatEngConfiguration);

  //       // //}

  //       //   };
          


  //     function presenceCallback(presenceData) {

  //       console.log("Presence data");
            
  //       console.log(presenceData);

  //       if(presenceData.presenceStatus === 'online') {

  //                 chatButton.innerHTML = "Live Chat";

  //                 $("#chat-overlay").show();

  //               //  that.animateChat();


  //       } else {

  //                 chatButton.innerHTML = "Chat Offline";
  //       }
  //     }
  //     function presenceErrorCallback() {
  //       console.log("Error checking presence");
  //     }
  //     var presenceReq = {
  //       "referenceId":referenceId,
  //       "engagementId":time,
  //       "onSuccess": presenceCallback,
  //       "onError":presenceErrorCallback
  //     };
  //     lpTag.sdk.exec("caoPresence.getPresence", presenceReq);
  //     }
  calltoaction_data_layer_push(param) {
    this.setState({ value:param })
    if(param && param==='clickToCall') {
        window.dataLayer.push({'event':'clickToCall'});
    }
 }

    render () {
  
      const agent_info = this.props.agent_info
      var page_slug;
      return (
          <section className="join_interest_form_div">
                <div className="join_interest">
                  <a href="javascript:;" className="join_interst_btn">
                      <span>Join the interest list</span>
                      <span class="side_arrow"><img src={require('images/side-arrow.png')} /></span>
                  </a>
                </div>
                  {/* <AltisJoinForm communityOfInterest={communityOfInterest} referenceId={referenceId} new_home_specialist={new_home_specialist}/> */}
                  <div className="join_toolbar_section">
                    <div className="join_interest_num toolbar_btn">
                      <a href={"tel:"+(agent_info.agent_contact_number)} onClick={() => this.calltoaction_data_layer_push('clickToCall')}>
                      <span><img src={require('images/call-icon.png')} /></span>
                      <span>{agent_info.agent_contact_number}</span>
                      </a>
                    </div>
                    {/* 6439 */}
                    {this.props.post.map(item => (
                            page_slug = '',
                            page_slug = item.post_name,
                            location.href.indexOf(page_slug) > -1 ? [
                              (item.show_tour_btn == true ) ? (
                                <div className="join_interest_tour toolbar_btn">
                                <a href="#tour_form" data-fancybox="tour-form" data-src="#tour_form" data-fancybox-group="tour-form" className="tour_btn mobile">
                                  <span><i className="far fa-calendar-alt"></i></span>
                                  <span>Tour</span>
                                </a>
                              </div>
                            ):('')
                            ]:['']
                        ))}
           
                  </div>
          </section>
      )
    }
    }
      
      export default StickyToolbar  