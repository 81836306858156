import React from 'react'
import PropTypes from 'prop-types'
import { getBreakpoint } from 'components/Classes/Helpers.jsx'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import $ from 'jquery'

class BlogArchives extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.state = {
      isHover: false
    }
  }

  handleClick (e) {
    if (getBreakpoint() < 768) {
      let $el = $(e.currentTarget)
      let $menu = $el.next('[data-filter-menu]')

      $el.closest('[data-filter]').toggleClass('active')
      $menu.slideToggle()
    }
  }

  handleGoBack () {
    this.props.history.goBack()
  }

  handleMouseOver (e) {
    if (getBreakpoint() >= 768) {
      this.setState({ isHover: true })
    }
  }

  handleMouseOut () {
    if (getBreakpoint() >= 768) {
      this.setState({ isHover: false })
    }
  }

  handleTouchStart (e) {
    if (getBreakpoint() >= 768) {
      if (this.state.isHover) {
        this.setState({ isHover: false })
      } else {
        this.setState({ isHover: true })
      }
    }
  }

  render () {
    const back = this.props.back ? this.props.back : false
    const isHover = this.state.isHover

    return (
      <section className="blog-archives">
        <div className="wrap wrap--blog-archives">
          <div className="blog-archives-holder">
            <div className="blog-archives-columns">
              {back ? (
                <button
                  className="blog-archives__button"
                  onClick={this.handleGoBack}
                  type="button"
                >Back</button>
              ) : (
                <h2 className="title title--blog-archives-category">Recent</h2>
              )}

              <div className="blog-archives-column blog-archives-column--menu">
                <div
                  className={`blog-archives-menu ${isHover ? 'hover' : ''}`}
                  data-filter
                  onMouseOut={this.handleMouseOut}
                  onMouseOver={this.handleMouseOver}
                  onTouchStart={this.handleTouchStart}
                >
                  <h3
                    className="title title--blog-archives"
                    onClick={this.handleClick}
                  >Archives</h3>

                  <ul data-filter-menu className="blog-archives__list">
                      {this.props.archives.map((archive)=>(

                          <li className="blog-archives__item">
                              <Link className="blog-archives__link" to={"/"+archive.year+"/"+archive.month}>{archive.month_name + " "+archive.year}</Link>
                          </li>

                      ))}


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

BlogArchives.propTypes = {
  back: PropTypes.bool
}

export default withRouter(BlogArchives)
