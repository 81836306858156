import React from 'react'

class LifestyleDetailHeader extends React.Component {
  render () {
    return (
      <section className="lifestyle-detail-header">
        <div className="wrap wrap--lifestyle-detail-header">
          <div>
            <div className="lifestyle-detail-header-columns">
              <div className="lifestyle-detail-header-column lifestyle-detail-header-column--main">
                <div className="lifestyle-detail-header-info">
                  <h2 className="lifestyle-detail-header__title">{this.props.post.post_title}</h2>
                  <div className="lifestyle-detail-header__message">
                    <p dangerouslySetInnerHTML={{__html: this.props.post.post_content.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                  </div>
                </div>
              </div>
              <div className="lifestyle-detail-header-column lifestyle-detail-header-column--sidebar">

                  {
                      (this.props.postDetailsMeta.lifestyle_detail_header.length > 0 && this.props.postDetailsMeta.lifestyle_detail_header !=0) ? (

                        <ul className="lifestyle-detail-header__list">

                            {
                                this.props.postDetailsMeta.lifestyle_detail_header.map((g,index)=>(

                                    <li className={"lifestyle-detail-header__item lifestyle-detail-header__item--"+(index+1)}>
                                        <div
                                            className="lifestyle-detail-header__image"
                                            style={{ backgroundImage: 'url(' + g.gallery_image.url + ')' }}
                                        ></div>
                                    </li>
                                ))
                            }


                        </ul>

                    ) : ('')
                  }

              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default LifestyleDetailHeader
