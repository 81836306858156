/* eslint-disable no-unused-vars */
import React from 'react'
import {Switch, Route, withRouter, Redirect} from 'react-router-dom'
import Home from './views/Home.jsx'
import Blog from './views/Blog.jsx'
import BlogDetail from './views/BlogDetail.jsx'
import Contact from './views/Contact.jsx'
import Event from './views/Events.jsx'
import Awards from './views/Awards.jsx'
import EventDetail from './views/EventDetail.jsx'
import PastEventDetail from './views/PastEventDetail.jsx'
import FAQ from './views/FAQ.jsx'
import Collections from './views/Collections.jsx'
import CollectionsDetail from './views/CollectionsDetail.jsx'
import CollectionsQuickMoveIn from './views/CollectionsQuickMoveIn.jsx'
import Lifestyle from './views/Lifestyle.jsx'
import LifestyleDetail from './views/LifestyleDetail.jsx'
import LifestyleInterests from './views/LifestyleInterests.jsx'
import NoMatch from './views/NoMatch.jsx'
import PlansDetail from './views/PlansDetail.jsx'
import QmiDetail from './views/QmiDetail.jsx'
import CategoryDetail from './views/CategoryDetail.jsx'
import ArchiveDetail from './views/ArchiveDetail.jsx'
import LifestyleCategoryDetail from './views/LifestyleCategoryDetail.jsx'
import PointsOfInterest from './views/PointsOFInterest'
import Resident from './views/Resident'
import AwardsDetails from '../Classes/AwardsDetails.jsx'
import Realtors from './views/Realtors'
import {Helmet} from 'react-helmet'
class Main extends React.Component {

    constructor(props) {
        super(props);


    }

    shouldComponentUpdate(nextProps) {

        return this.onRouteChanged(nextProps);

    }


    onRouteChanged(nextProps) {




        let redirectStatus = false;

        try {

            let redirectItems = JSON.parse(redirectionList);


            redirectItems.map(function (item) {


                if (item.url == nextProps.location.pathname) {

                    redirectStatus = true;


                }

            });

            if (redirectStatus == true) {
                window.location.reload();
                return false;
            } else {
                return true;
            }


        } catch (e) {

            return true;

        }


    }


    render() {
        return (
            <main id="main" className="main">
            <Helmet>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/home" component={Home}/>
                    <Route exact path="/blog" component={Blog}/>
                    <Route exact path="/blog/:id" component={BlogDetail}/>
                    <Route exact path="/residents" component={Resident}/>
                    <Route exact path="/collections" component={Collections}/>
                    <Route exact path="/collections/quick-move-in" component={CollectionsQuickMoveIn}/>
                    <Route exact path="/collection/:slug" component={CollectionsDetail}/>
                    <Route exact path="/collection/:neighbourhood/:plan" component={PlansDetail}/>
                    <Route exact path="/collection/:neighbourhood/:plan/:qmi" component={QmiDetail}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route exact path="/events" component={Event}/>
                    <Route exact path="/awards" component={Awards}/>
                    <Route exact path="/eventcategory/:id" component={PastEventDetail}/>
                    <Route exact path="/event/:id" component={EventDetail}/>
					<Route exact path="/points-of-interest" component={PointsOfInterest}/>
                    <Route exact path="/faq" component={FAQ}/>
                    <Route exact path="/lifestyles" component={Lifestyle}/>
                    <Route exact path="/realtors" component={Realtors}/>
                    <Route exact path="/lifestyles/interests" component={LifestyleInterests}/>
                    <Route exact path="/lifestyle/:id" component={LifestyleDetail}/>
                    <Route exact path="/category/:id" component={CategoryDetail}/>
                    <Route exact path="/lifestyle-category/:id" component={LifestyleCategoryDetail}/>
                    <Route exact path="/:year/:month" component={ArchiveDetail}/>
                    <Route exact path="/:id" component={BlogDetail}/>
                    <Route component={NoMatch}/>
                </Switch>
            </main>
        )
    }
}

export default withRouter(Main)
