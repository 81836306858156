import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import BasicMantle from 'components/Classes/BasicMantle.jsx' // eslint-disable-line no-unused-vars
import AwardsDetails from 'components/Classes/AwardsDetails.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Awards extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            success: false,
            post: {},
            message: "",
            loaded: false
        }
    }

    componentWillReceiveProps(nextProps) {

        console.log("updated props");
        console.log(nextProps);

        this.props = nextProps;

        this.fetchResult();

    }
    
    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "get_awards" )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        post: result.data,
                        page_meta: result.data.page_meta,
                        success: result.success,
                        message: result.message,
                        loaded: true
                    });


                },

                (error) => {

                }
            )

    }


    componentDidMount() {

        this.fetchResult();
        CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    {this.state.success ? [
                        <Helmet>
                            <title>{this.state.post.post_title}</title>
                            <body className="awards-detail"/>
                        </Helmet>,

                        // <Overlaychat/>,

                        <h2 className="hide--visually">{this.state.post.post_title}</h2>,


                        (this.state.page_meta.banner_image) ? [

                            <BasicMantle
                                image={this.state.page_meta.banner_image} message={this.state.page_meta.banner_text} customClass="Awards_detail_mantle"
                            />

                        ] : (''),
                        (this.state.page_meta.header_image) ? [

                            <BasicMantle
                                image={this.state.page_meta.header_image} customClass="Awards_header_image"
                            />

                        ] : (''),
                        <AwardsDetails page_meta={this.state.page_meta}/>,


                    ] : (this.state.message)}

 
            </div>
        )
    }
}

export default Awards
