import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import {toVisualHtml} from 'components/Classes/Helpers.jsx'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'
import $ from 'jquery'
class MantleSlider extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            slider : props.slider
        }
    }

    componentDidMount(){

        $('.mantle-slider-container .slick-arrow').wrapAll('<div class="slider_navigation"></div>');
    }



    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 8000,
            dots: false,
            fade: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }

        return (
            <section className="mantle-slider">
                <div className="wrap--mantle-slider">
                    <div className="mantle-slider-container">
                        <Slider {...settings}>

                            {
                                (this.state.hasOwnProperty('slider') &&  this.state.slider.length >  0)?

                                     this.state.slider[0].slides.map(s=>(
                                         <div>
                                             <a className="mantle-slider__link">
                                                 <img src={s.slide_image.url}
                                                      className="mantle-slider__image" alt="Slider Hero Image"/>
                                                 <div className="mantle-slider-overlay">
                                                     {this.state.slider[0].slider_heading && <h1 className="mantle-slider__title">{this.state.slider[0].slider_heading}</h1>}
                                                     {s.slide_title && <div className="mantle-slider__sub_title" dangerouslySetInnerHTML={toVisualHtml(s.slide_title)}></div>}
                                                     {s.cta_link && s.cta_label && <a className="find_your_home_btn" href={s.cta_link}>{s.cta_label}</a>}
                                                     {s.enable_avocado_shape===true && <img src={require('images/avocado_shape.png')} class="slider__overlay_image" alt="Avocado Shape" />}
                                                 </div>
                                             </a>
                                         </div>


                                     ))

                                     : ""



                            }



                        </Slider>
                        {/* <div className="mantle-slider-menu">
                            {
                                (this.state.hasOwnProperty('slider') &&  this.state.slider.length >  0) ? <p dangerouslySetInnerHTML={toVisualHtml(this.state.slider[0].slider_heading)}></p> : ''
                            }

                        </div> */}
                    </div>
                </div>
            </section>
        )
    }
}

export default MantleSlider
