/* eslint-disable no-unused-vars */
import React from 'react'
import Slider from 'react-slick'
import fancybox from '@fancyapps/fancybox'

import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'
import $ from 'jquery'
class Amenities extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            nav1: null,
            nav2: null
        }

    }

    componentDidMount() {

        $('.amenities-slider-2 .slick-arrow').wrapAll('<div class="slider_navigation"></div>');

        // $(".amenities-slider-2 .slick-slider").append("<div class='slider_border'><img src='"+require('images/green-bar.jpg')+"' /></div>");

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        })
    }


    render() {
        const slider1Settings = {
            asNavFor: this.state.nav2,
            arrows: false,
            dots: false,
            draggable: false,
            fade: true,
            infinite: true,
            ref: slider => (this.slider1 = slider),
            slidesToShow: 1,
            slidesToScroll: 1
        }

        const slider2Settings = {
            asNavFor: this.state.nav1,
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            focusOnSelect: true,
            infinite: true,
            ref: slider => (this.slider2 = slider),
            slidesToShow: 1,
            slidesToScroll: 1
        }


        const show_amenities_nav_label = this.props.show_amenities_nav_label;
        return (
            <section className="amenities wrap-inner">
                <div className="amenities-shim"></div>
                <div className="wrap--amenities">
                        <div className="amenities-sliders">
                            <div className="amenities-slider-1">
                                <Slider {...slider1Settings}>
                                    {this.props.amenities.map((amenity, index) => (


                                        amenity.video_url.includes('vidyard') ? [
                                            <div>
                                                <div className="amenities-slider-1-image">
                                                    <img src={amenity.video_image.url}
                                                         className="amenities-slider-1__image"
                                                         alt="Mid Page Image"/>
                                                    {amenity.video_url != "" ? [
                                                        <a href={amenity.video_url} id={"play-" + (index + 1)}
                                                           data-fancybox data-type="iframe"  data-iframe='{"preload":false}'
                                                           className="amenities-slider-1__link" draggable="true">


                                                            <i className="icon icon--play">
                                                                <span className="hide--visually">Play Video</span>
                                                            </i>


                                                        </a>
                                                    ] : ['']}
                                                </div>
                                            </div>

                                        ] : [

                                            <div>
                                                <div className="amenities-slider-1-image">
                                                    <img src={amenity.video_image.url}
                                                         className="amenities-slider-1__image"
                                                         alt="Mid Page Image"/>
                                                    {amenity.video_url != "" ? [
                                                        <a href={amenity.video_url} id={"play-" + (index + 1)}
                                                           data-fancybox
                                                           className="amenities-slider-1__link" draggable="true">


                                                            <i className="icon icon--play">
                                                                <span className="hide--visually">Play Video</span>
                                                            </i>

                                                        </a>
                                                    ] : ['']}
                                                </div>
                                            </div>

                                        ]


                                    ))}

                                </Slider>
                            </div>

                            <div className="amenities-slider-2">
                            
                                <Slider {...slider2Settings}>
                                    {this.props.amenities.map((amenity, index) => (

                                        amenity.video_url.includes('vidyard') ? [

                                            <div>
                                                <div className="amenities-slider-2-info">

                    

                                                <div className="amenities-slider-2_description">
                                                {show_amenities_nav_label == true ? [

                                                    <h2 className="title title--amenities-slider-2">Click Arrows to Discover Citro</h2>

                                                ] : ('')}
                                                    <p className="amenities-slider-2__subtitle">{amenity.name}</p>
                                                    <p className="amenities-slider-2__message">{amenity.description}</p>

                                                    {
                                                        amenity.learn_more_url != "" ? [

                                                            <a className="amenities-slider-2__link" onClick={() => {
                                                                window.location.href = amenity.learn_more_url
                                                            }}>Learn More</a>

                                                        ] : ['']
                                                    }

                                                    </div>

                                                </div>
                                            </div>

                                        ] : [

                                            <div>
                                                <div className="amenities-slider-2-info">
                                               

                                                <div className="amenities-slider-2_description">
                                                {show_amenities_nav_label == true ? [

                                                    <h2 className="title title--amenities-slider-2">Click Arrows to Discover Citro</h2>

                                                ] : ('')}
                                                    <p className="amenities-slider-2__subtitle">{amenity.name}</p>
                                                    <p className="amenities-slider-2__message">{amenity.description}</p>
                                                    {
                                                        amenity.learn_more_url != "" ? [

                                                            <a className="amenities-slider-2__link" onClick={() => {
                                                                window.location.href = amenity.learn_more_url
                                                            }}>Learn More</a>

                                                        ] : ['']
                                                    }

                                                    </div>
                                                </div>
                                            </div>
                                        ]

                                    ))}

                                </Slider>
                            </div>
                        </div>
                </div>
            </section>
        )
    }
}

export default Amenities
