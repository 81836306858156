import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import UpcomingEvents from 'components/Classes/UpcomingEvents.jsx'
import LatestEvents from 'components/Classes/LatestEvents.jsx' // eslint-disable-line no-unused-vars
import EventsList from'components/Classes/EventsList.jsx'
import EventCategories from'components/Classes/EventCategories.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class PastEventDetail extends React.Component {

    constructor(props) {
        super(props)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.state = {

            events: [],
            success: false,
            loaded: false
        };

        console.log("Events Props");
        console.log(this.props);
       
    }

    fetchEvents = (date) => {

       // this.setState({loaded: false});

      

        // var endpoint = "events"

        // if(typeof date != 'undefined'){

        //    var y = date.getFullYear();
        //    var m = (date.getMonth() + 1)
        //    var d = date.getDate();

        //     if(date.getDate() < 10){

        //         d = "0"+date.getDate();
        //     }

        //     if((date.getMonth()+1) < 10){

        //         m = "0"+(date.getMonth()+1);
        //     }


        //     endpoint = endpoint+'?feature='+y+m+d;

        // }

       

    
        // if(location.search.indexOf('group-event') > -1){
            

        //     var groupType = this.getUrlParameter('group-event');

           
        //     if(endpoint.indexOf("?feature") > -1){

        //         endpoint = endpoint+'&group-event='+groupType;

        //     }else{
        //         endpoint = endpoint+'?group-event='+groupType;
        //     }

        // }


        fetch(apiConfig().API_URL + "eventcategory/" + this.props.match.params.id)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    events:result.data,
                    success: true,
                    loaded: true
                });

                console.log("Events APi Response");
                console.log(result);

            },

            (error) => {

            }
        )

    }

    componentDidMount() {

        this.fetchEvents();

        CallTrk.swap();
    }


    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    
    componentDidUpdate(){

        CallTrk.swap();
    }

    handleGoBack () {
        this.props.history.goBack();
    }

    render() {

        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}

                {this.state.success ? (

                    <section className="Events">          
                    <Helmet>
                        {/* <title>{page.pageTitle}</title> */}
                        <body className="event"/>
                    </Helmet>
                    {/* <Overlaychat /> */}
                    <h2 className="hide--visually">Event</h2>

                    <div className="wrap wrap--lifestyle-back">
                        <div className="lifestyle-back-holder">
                            <button type="button" onClick={this.handleGoBack} className="lifestyle-back__button">Back</button>
                        </div>
                    </div>
                        { this.state.events.past_events != '' ? [
                            <EventsList heading={'Past Events: '+this.state.events.category_details.post_title} events={this.state.events.past_events}/>
                        ] : [
                            <div className="no_category_events">
                                <h2>No Events are there related to this category</h2>
                            </div>   
                        ]}

                    </section>

                ) : ('')}

               
            </div>
        )
    }
}

export default PastEventDetail