import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import {Element} from 'react-scroll' // eslint-disable-line no-unused-vars
import $ from 'jquery';
// import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
// import "lightgallery.js/dist/css/lightgallery.css";
import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class CollectionsGallery extends React.Component {


    componentDidMount() {

        JQUERY("#lightgallery").lightGallery({
            download:false
        });
        // JQUERY("#lightgallery1").lightGallery();  
    }
    componentDidUpdate(){
        $(".lg-prev").click(function(){
            window.dataLayer.push({'event':'imageGalleryPrev'});
        })
    
        $(".lg-next").click(function(){
            window.dataLayer.push({'event':'imageGalleryNext'});
       })  
    }

    componentWillUnmount() {
        // $(this.lightGallery).data('lightGallery').destroy(true);
    }
    imageGalleryClick_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='imageGalleryClick') {
            window.dataLayer.push({'event':'imageGalleryClick'});
        }
     }
    render() {

        const gallery_video = this.props.gallery_videos;
        const gallery_images = this.props.gallery_images;
       
        return (
            <div className="collection-gallery inner_wrap" id="gallery">
                {this.props.gallery_images ? [
                gallery_images.length > 0 ? [
                <section className="collections-images">
                        <div className="collections-holder">
                            <div id="lightgallery">
                            
                                {gallery_images.map((g) => (
                                  
                                    <a href={g.image.url} data-sub-html={g.image_caption} className="collections-images__link">
                                        <img src={g.image.url}    className="collections-images-slider__image"
                                            alt={g.image.name} onClick={() => this.imageGalleryClick_data_layer_push('imageGalleryClick')}/>
                                    </a>
                                ))}
                           
                            </div>
                        </div>
                        <p className="count-text">{gallery_images.length}{gallery_images.length == 1 ? ' Photo' : ' Photos'}</p>
                </section>
                 ]:['']]:['']}
                 {gallery_video ? [
                    gallery_video.cover_image && gallery_video.url ? [
                <section className="collections-videos">
                        <div className="collections-holder">
                        <img src={gallery_video.cover_image} className="collections-video__image" alt="Video"/>
                        <a href={gallery_video.url} data-type="iframe" data-fancybox data-iframe='{"preload":false}'
                        data-fancybox-group="collections-video" className="collections-video__link gallery_video_0">
                                <i className="icon icon--play">
                                    <span className="hide--visually">Play Video</span>
                                </i>
                        </a>
                        </div>
                        <p className="count-text">1 Video</p>
                </section>
               ]:['']]:['']}
            </div>
        )
    }
}

export default CollectionsGallery