import React from 'react'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars

class LifestyleInterestsPosts extends React.Component {
  render () {
    return (
      <section className="lifestyle-interests-posts">
        <div className="lifestyle-interests-posts-shim"></div>
        <div className="wrap wrap--lifestyle-interests-posts">

          <ul className="lifestyle-interests-posts__list">
              {
                this.props.terms.map((term)=>(

                    <li className="lifestyle-interests-posts__item">
                        <Link to={"/lifestyle-category/"+term.slug} className="lifestyle-interests-posts__link">
                            <figure className="lifestyle-interests-posts__figure">
                                <img src={term.image} className="lifestyle-interests-posts__image" alt="" />
                                <figcaption className="lifestyle-interests-posts-overlay">
                    <span className="lifestyle-interests-posts-overlay-inner">
                      <h3 className="lifestyle-interests-posts__title">{term.name}</h3>
                    </span>
                                </figcaption>
                            </figure>
                        </Link>
                    </li>
                ))
              }


          </ul>

        </div>
      </section>
    )
  }
}

export default LifestyleInterestsPosts
