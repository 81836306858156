import React from 'react'
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars
import CollectionsMapContainer from 'components/Classes/CollectionsMapContainer.jsx' // eslint-disable-line no-unused-vars

class FinderLocator extends React.Component {
  render () {
    return (
      <section className="collections-map">
        <h2 className="hide--visually">Collections Finder</h2>

        <Element id="map">
          <div className="wrap wrap--collections-map">
            <div className="collections-map-holder">
              <div className="collections-map__map">
                <CollectionsMapContainer />
              </div>
            </div>
          </div>
        </Element>
      </section>
    )
  }
}

export default FinderLocator
