/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import CollectionsDetailSlider from 'components/Classes/CollectionsDetailSlider.jsx'
import CollectionsGoBack from 'components/Classes/CollectionsGoBack.jsx'
import CollectionsPosts from 'components/Classes/CollectionsPosts.jsx'
import CollectionsVideo from 'components/Classes/CollectionsVideo.jsx'
import CollectionsGallery from 'components/Classes/CollectionsGallery.jsx'
import FeaturedPlans from 'components/Classes/FeaturedPlans.jsx'
import InteractiveFloorPlans from 'components/Classes/InteractiveFloorPlans.jsx'
import Features from 'components/Classes/Features.jsx'
import Info from 'components/Classes/Info.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import SidebarSignUp from 'components/Classes/SidebarSignUp.jsx'
import {apiConfig,toVisualHtml} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import SingleCollectionQuick from 'components/Classes/SingleCollectionQuick'
import Breadcrumb from 'components/Classes/breadcrumb'
import PackagesList from 'components/Classes/PackagesList'
import StickyToolbar from 'components/Classes/Sticky_toolbar.jsx'
import $ from 'jquery'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
import VideoSlider from 'components/Classes/VideoSlider.jsx';
class CollectionsDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            name: null,
            loaded: false,
            success: false,
            neighbourhood: {},
            qmiFound:false,
            post_title:"",
            showQMI:false,
            showPackages:false,
            isQueryStringProcessed:false
        }

        this.activeQuickMoveIns = this.activeQuickMoveIns.bind(this)
        this.activePackages = this.activePackages.bind(this)
        this.reloadTab = this.reloadTab.bind(this)
    }

    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "neighbourhoodById/" + this.props.match.params.slug)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        neighbourhood: result.data,
                        post_title:result.postTitle,
                        qmiFound:result.data.qmi_found,
                        loaded: true,
                        success: true
                    });



                },

                (error) => {

                }
            )

    }

    activeQuickMoveIns(){

       this.setState({
           showQMI:true,
           showPackages:false,
       });

    }

    activePackages(){
        this.setState({
            showPackages:true,
            showQMI:false
        });

    }

    reloadTab(){
        this.setState({
            showQMI:false,
            showPackages:false
        });
    }

    checkforQueryString(){

        if(this.state.neighbourhood.qmis && this.state.neighbourhood.qmis.length > 0 && location.search.indexOf("feature_homes") > -1 && this.state.isQueryStringProcessed == false &&  $(".collections-mantle-nav__link").length > 0){

           
            $($(".collections-mantle-nav__link")[1]).trigger("click");
            $(".collections-mantle-nav__link")[1].click();

            this.setState({
                showQMI:true,
                isQueryStringProcessed:true
            });
            

        }

        if(this.state.neighbourhood.qmis && this.state.neighbourhood.qmis.length > 0 && location.search.indexOf("collection_packages") > -1 && this.state.isQueryStringProcessed == false &&  $(".collections-mantle-nav__link").length > 0){

           
            $($(".collections-mantle-nav__link")[2]).trigger("click");
            $(".collections-mantle-nav__link")[2].click();

            this.setState({
                showPackages:true,
                isQueryStringProcessed:true
            });
            

        }

    }


    componentDidMount() {

        this.fetchResult();
        CallTrk.swap();

       // this.checkforQueryString();

    //    if(this.state.success == true){
    //     dataLayer.push({
    //         'event': 'communityId',
    //         'e1CommunityId': this.state.neighbourhood.subdevision_details[0].community_id ? this.state.neighbourhood.subdevision_details[0].community_id : "",
    //         'salesforceId': this.state.neighbourhood.subdevision_details[1].crm_salesforce_id ? this.state.neighbourhood.subdevision_details[1].crm_salesforce_id : ""
    //     });
    //     alert("mount");
    //    }
    }

    componentDidUpdate(){

        if(this.state.success == true){
            dataLayer.push({
                'event': 'communityId',
                'e1CommunityId': this.state.neighbourhood.community_id ? this.state.neighbourhood.community_id : "",
                'salesforceId': this.state.neighbourhood.salesforce_id ? this.state.neighbourhood.salesforce_id : ""
            });
           }

        CallTrk.swap();

        this.checkforQueryString();
    }


    render() {
        const id = this.state.id ? this.state.id : 1
        const name = this.state.name ? this.state.name : 'Collections Detail'
        const slug = this.props.match.params.id
        const titleName = this.state.name ? `${this.state.name} | ` : ''
        const qmis = this.state.neighbourhood.qmis;
        const packages = this.state.neighbourhood.packages;
        const showQMI = this.state.showQMI;
        const showPackages = this.state.showPackages;

        const coming_soon_neigh = this.state.neighbourhood.neighborhood_status == 'Coming Soon' ? true : false;

        const showFeaturedHome = qmis && qmis.length > 0 ? true : false;
        const showPackagelist= packages && packages.length > 0 ? true : false; 
	    var window_width = screen.width;
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                <Helmet>
                    <title>{this.state.post_title}</title>
                    <body className="collections-detail"/>
                </Helmet>

                {
                    this.state.success === true ? [

                        <h2 className="hide--visually">{this.state.neighbourhood.post_title}</h2>,

                        <NavigationMantle
                            active="left"
                            image={require('images/orange.jpg')}
                            message={<h1 dangerouslySetInnerHTML={toVisualHtml(this.state.neighbourhood.post_title)}></h1>}
                            status ={this.state.neighbourhood.neighborhood_status}
                            navigation={
                                [
                                    {url: "/collection/"+this.state.neighbourhood.post_name, label: 'Collections','reloadTab':this.reloadTab},
                                    {url: "/collection/"+this.state.neighbourhood.post_name, label: 'Quick Move-in','activeQuickMoveIns':this.activeQuickMoveIns,show:showFeaturedHome},
                                    {url: "/collection/"+this.state.neighbourhood.post_name, label: 'Designer Packages','activePackages':this.activePackages,show:showPackagelist},
                                ]
                            }
                            title=""
                            customClass={"collection-detail-mantle"}
                        />,

                        <CollectionsGoBack/>,
                            <Breadcrumb
                            items={
                                [
                                    {
                                        to:"/collections",
                                        title:"Collections"
                                    },{
                                        to:"/collection/"+this.state.neighbourhood.post_name,
                                        title:this.state.neighbourhood.post_title
                                    }

                                ]
                            }
                        />,

                         (showQMI == false && showPackages == false) ? [

                            <div className="sidebar-columns">
                            <div className="wrap wrap--sidebar-columns">
                                <div className="sidebar-column sidebar-column--content">
                                    <CollectionsDetailSlider
                                        id={id}
                                        name={this.state.neighbourhood.post_title}
                                        gallery={this.state.neighbourhood.gallery_images}
                                        post_thumbnail={this.state.neighbourhood.post_thumbnail}
                                        post= {this.state.neighbourhood}
                                    />

                                    <Features
                                        message1={<p
                                            dangerouslySetInnerHTML={{__html: this.state.neighbourhood.long_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>}
                                        message2=""
                                        brochures={this.state.neighbourhood.brochures}
                                        title2="Features"
                                    />
                                </div>
                                {(window_width > 767 ) ? (
                                <aside className="sidebar-column sidebar-column--sidebar">                              
                                    <div className="wrap--sidebar-column">
                                        <div className="sidebar-column-inner">
                                            <SidebarSignUp communityOfInterest={this.state.neighbourhood.salesforce_id} referenceId={this.state.neighbourhood.community_id} new_home_specialist={this.state.neighbourhood.new_home_specialist} agent_dre={this.state.neighbourhood.agent_dre} agent_name={this.state.neighbourhood.agent_name} coming_soon_neigh={coming_soon_neigh} community_name={this.state.neighbourhood.post_title}  neigh_detail= {this.state.neighbourhood}/>
                                        </div>
                                    </div>
                                </aside> ) : ('') }
                            </div>
                        {/* 5959 */}
                        <div className="wrap">
                         {this.state.neighbourhood.gallery_images != "" ||  this.state.neighbourhood.video != "" ? (

                             <CollectionsGallery gallery_videos={this.state.neighbourhood.video} gallery_images={this.state.neighbourhood.gallery_images}/>

                         ) : ('')}
                            {this.state.neighbourhood.plans.length > 0 ? [

                                <FeaturedPlans
                                    slug={this.state.neighbourhood.post_name}
                                    plans={this.state.neighbourhood.plans}
                                />

                            ] : ['']}
                               </div>
							{ this.state.neighbourhood.neigh_interactive_media ? [

								<InteractiveFloorPlans interactiveFloorPlans = {this.state.neighbourhood.neigh_interactive_media} forNbd={true} forheight = {this.state.neighbourhood.customHeight} />

							]: ['']}
                        </div>


                        ] : [

                            (showQMI == true) ? [

                                <SingleCollectionQuick qmis={qmis} />
                            ] : [''],
    
                            (showPackages == true) ? [
    
                                <PackagesList packages={packages} packageTitle={this.state.neighbourhood.packagepageTitle} packagedescription={this.state.neighbourhood.packagepagedescription} CollectionsDetail={true}/>
    
                            ] : ['']
                        ],

                    ] : ['']
                }


            </div>
        )
    }
}

export default CollectionsDetail
