import React from 'react'
import $ from 'jquery'

class Overlaychat extends React.Component {

    constructor(props){
        super(props);

        //this.setChatButton = this.setChatButton.bind(this)
        this.state = {
            timeouts:[]
        }


    }


    componentDidMount(){
        
        $(".chat_btn .chat_txt").css("font-size","0");

        //this.setChatButton("73290000", 1, "altis_chat");
        


    }


    animateChat(){

        var tmOuts = [];

        $(".chat_btn.global_chat_btn").css({width:'60px',borderRadius:'50%'});

        $(".chat_btn .chat_txt.global_chat_txt").css("font-size","0");

        var t4 = "";
        var t3 = "";

        var t1 = setTimeout(
            function(){
                    
                    $(".chat_btn.global_chat_btn").animate({
                        width:"290px",
                        borderRadius:"50px",
                    },function(){
    
                        t3 = setTimeout(function(){
                            $(".chat_btn .chat_txt.global_chat_txt").css("font-size","0");
                            $(".chat_btn .chat_txt.global_chat_txt").css("font-size","16px");
                        },500)
                        tmOuts.push(t3);
                    });

                   t4 = setTimeout(function(){
                        $(".chat_btn .chat_txt.global_chat_txt").text("Chat with our specialist");
                    },3000)
                    tmOuts.push(t4);
            },1000);

            var t2 =  setTimeout(
            function(){
                $(".chat_btn.global_chat_btn").animate({
                    width:"60px",
                    borderRadius:"50%"
                },"fast");
                $(".chat_btn .chat_txt.global_chat_txt").css("font-size","0");
            },8000)


            

            tmOuts.push(t1);

            tmOuts.push(t2);

           

            this.setState({timeouts:tmOuts});

      
    }

    // setChatButton(referenceId, engagementId, chatContainer) {

    //     var that = this;

	// 	var time = new Date().getTime();

    //     var overlayChat = document.getElementById("chat-overlay");

	// 	var chatEngConfiguration = {
    //         "referenceId":referenceId,
    //         "engagementId":1,
    //         "channel":"chat"
    //     };
        

    //     overlayChat.onclick = function() {


    //             //alert("Overlay -->"+referenceId);

	// 		//if($("#"+chatContainer).text() == "Live Chat"){


    //             lpTag.sdk.exec("caoEngager.startChat", chatEngConfiguration);

	// 		//}

    //     };
        


	// 	function presenceCallback(presenceData) {

    //         console.log("Presence Data----->");

    //         console.log(presenceData);

    //         var showTimeout;


	// 		if(presenceData.presenceStatus === 'online') {

               
    //             showTimeout = setTimeout(function(){
    //                     $("#chat-overlay").show();
    //                      that.animateChat();
    //                      clearTimeout(showTimeout);
    //                 },1000);
                   

	// 		} 
	// 	}
	// 	function presenceErrorCallback() {
	// 		console.log("Error checking presence");
	// 	}
	// 	var presenceReq = {
	// 		"referenceId":referenceId,
	// 		"engagementId":time,
	// 		"onSuccess": presenceCallback,
	// 		"onError":presenceErrorCallback
	// 	};
	// 	lpTag.sdk.exec("caoPresence.getPresence", presenceReq);
    // }
    
    componentWillUnmount(){
      
       this.state.timeouts.map(function(t){

            clearTimeout(t);

       });

       $(".chat_btn").css({width:'60px',borderRadius:'50%'});

       $(".chat_btn .chat_txt").css("font-size","0");
    }

    render(){
        return (

            <div>
                 <div style={{display:"none",zIndex:112}} id='chat-overlay' className="chat_btn global_chat_btn"><a href="javascript:void(0)"><img src={require('images/chat_btn.svg')} /><span className="chat_txt global_chat_txt" style={{fontSize:0}} >Have Any Questions?</span></a></div>
            </div>

        )
    }

}


export default Overlaychat