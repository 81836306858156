import React from 'react'
import Calendar from 'react-calendar';
 
class Eventcalender extends React.Component {

   constructor(props){
       super(props)

   }

  state = {

     date: new Date()
  }


  filterEvents = (date) => {

     this.props.fetchEvents(date);

  }
 
  onChange = date => {

    this.filterEvents(date);
  }

  eventLables = (date,all_event_dates) => {

     var cl = "";
     var dateProcessed = [];
     var typeProcessed = [];

          for(var i=0; i<= all_event_dates.length;i++){


            if(typeof all_event_dates[i] != 'undefined'){

               if(all_event_dates[i].date.day == date.getDate() && all_event_dates[i].date.year == date.getFullYear() && all_event_dates[i].date.monthNum == (date.getMonth()+1)){
               
                     cl =  (all_event_dates[i].type === 'public') ? "public_event" : 'resident_event';


                    if(dateProcessed.indexOf(all_event_dates[i].date.day+all_event_dates[i].date.monthNum+all_event_dates[i].date.year) > -1){

            

                       if(typeProcessed.indexOf(all_event_dates[i].date.day+all_event_dates[i].date.monthNum+all_event_dates[i].date.year+cl) < 0){

                            cl = 'multi_event';
                            break;
                       }
             
                       
                    }


                     dateProcessed.push(all_event_dates[i].date.day+all_event_dates[i].date.monthNum+all_event_dates[i].date.year);
                     typeProcessed.push(all_event_dates[i].date.day+all_event_dates[i].date.monthNum+all_event_dates[i].date.year+cl);

                
                

 
               }

            }

           


          }

         
        return cl;

      

  }
 
  render() {

    const {all_event_dates} = this.props;

    return (
      <div>
        <Calendar
          onChange={this.onChange}
          className={'event_calendar'}
          tileClassName={({ date }) => this.eventLables(date,all_event_dates)}
        />
      </div>
    );
  }
}

export default Eventcalender;