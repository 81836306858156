import React from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {


  componentWillUpdate(newProps) {

    if(location.hash.indexOf("#newsletter") == -1){

      if(this.props.history.action == 'PUSH'){

          if (this.props.location.pathname !== newProps.location.pathname) {


                if(this.props.location.hash.indexOf('#newsletter') > -1){
                    setTimeout(function(){
                      window.scrollTo(0, 0)
                    },300);
                    
                }else{

                  window.scrollTo(0, 0)

                }

               
          }

      }else if(this.props.history.action == 'POP'){

          if (this.props.location.pathname !== newProps.location.pathname) {

                setTimeout(function(){
                  window.scrollTo(0, 0)
                },300);
          }

      }


    }

   
   
  }

  render () {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
