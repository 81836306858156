import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class LifestyleDetailGallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            width: window.innerWidth,
            responsive:{
                0:{
                    items:1,
                },
                568:{
                    items:3,
                },
            },
        };
    }
    render() {
        return (
            <section className="lifestyle-detail-gallery">
                <div className="wrap wrap--lifestyle-detail-gallery">
                    <div>

                        {(this.props.postDetailsMeta.lifestyle_detail_gallery.length > 0 && this.props.postDetailsMeta.lifestyle_detail_gallery != 0 && this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_image) ? [

                                <div className="lifestyle-detail-gallery-video">
                                        <img src={this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_image.url}
                                         className="lifestyle-detail-gallery__image"/>
                                    {this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_url ? (


                                        this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_url.includes('vidyard') ? [

                                            <a href={this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_url} data-fancybox data-iframe='{"preload":false}' data-type="iframe"
                                               className="lifestyle-detail-gallery__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
                                        ] : [

                                            <a href={this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_video_url} data-fancybox
                                               className="lifestyle-detail-gallery__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
                                        ]



                                    ) : ('')}

                                </div>,
                                
                                this.props.awards ? (
                                    (this.props.awards.length > 0) ? [
                                        <div className="awards-trophy">
                                            <img src={require('images/trophy.png')} />
                                            <p className="awards-heading">Award Winning Community</p>
                                        </div>,
                                        <div className="awards-main-div">
                                            <OwlCarousel
                                            className={'owl-theme awards'}
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            center={true}
                                            responsive={this.state.responsive}  
                                                >
                                            {this.props.awards.map((award)=>(
                                                <div className="item">
                                                    <div className="awards-div">
                                                        { award.awards_links != '' ? [
                                                        <a href={award.awards_links}>
                                                            <p className={"award-text"}>{award.award_description}</p>
                                                            <img src={award.carousel_image}/>
                                                        </a>]:[
                                                            <p className={"award-text"}>{award.award_description}</p>,
                                                            <img src={award.carousel_image}/>
                                                        ]}
                                                    </div>
                                                </div>  
                                            ))}
                                            </OwlCarousel>
                                        </div>
                                    ]:['']
                                ):(''),
                                
                                <div className="lifestyle-detail-gallery-columns">
                                    <div className="lifestyle-detail-gallery-column">
                                        <ul className="lifestyle-detail-gallery__list">

                                            {(this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images.length > 0 && this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images!=0) ? (

                                                <li className="lifestyle-detail-gallery__item">
                                                    <img src={this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images[0].image.url  }
                                                         className="lifestyle-detail-gallery__image"/>
                                                </li>

                                            ) : ('')}

                                            {this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_text ? (

                                                <li className="lifestyle-detail-gallery__item">
                                                    <div className="lifestyle-detail-gallery__message">
                                                        <p>{'"'+this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_text+'"'}</p>
                                                    </div>
                                                </li>


                                            ) : ('')}

                                        </ul>
                                    </div>

                                    <div className="lifestyle-detail-gallery-column">
                                        {(this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images.length > 0 && this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images !=0) ? (
                                        <ul className="lifestyle-detail-gallery__list">
                                            {this.props.postDetailsMeta.lifestyle_detail_gallery[0].gallery_images.map((g,index)=>(

                                                (index !=0) ? (

                                                    <li className="lifestyle-detail-gallery__item">
                                                        <img src={g.image.url ? g.image.url : ''}
                                                             className="lifestyle-detail-gallery__image"/>
                                                    </li>


                                                ) : ('')



                                            ))}


                                        </ul>
                                        ) : ('')}




                                    </div>
                                </div>


                            ]
                            : ('')}

                    </div>
                </div>
            </section>
        )
    }
}

export default LifestyleDetailGallery
