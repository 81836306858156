import React from 'react'

class ResidentsList extends React.Component {
  render () {
 
    const {residents,description} = this.props;

    return (
      <section className="packages">
        <h2 className="hide--visually">Residents</h2>

        <div className="wrap wrap--locations">
          <div className="wrap-inner">

                   
                      { description ? [
                          <div className="package_description upcoming-event-description">  
                           <p>{description}</p>
                          </div>
                      ] 
                      
                      : ['']

                      }
                 
                   

                  <div className="package_wrapper">
                      {residents.map((resident) => (
                              <div className="package-list">
                                  <div className="package-column package-column--image">
                                      <div className="package-image">
                                              <a href={resident.resident_url} target="_blank"><img src={resident.post_thumbnail}/></a>
                                      </div>
                                  </div>

                                  <div className="package-column package-column--content">
                                        <div className="package-info-columns package-info-">
                                            <div className="package-info-column package-info-column--title">
                                                <h3 className="package-info__title">{resident.post_title}</h3>
                                            </div>
                                        </div>

                                      { resident.short_description ? [
                                        <div className="package-info-columns package-details">
                                           <div className="package-info__message" dangerouslySetInnerHTML={{__html: resident.short_description}}></div>
                                        </div>
                                      ]: ['']

                                      }
                                        <div className="package-info-columns package-buttons">
                                        {
                                          resident.resident_url ? [
                                              <a href={resident.resident_url} target="_blank" className="package__button">Login</a>
                                          ]:['']
                                        }
                                            
                                        </div>
                                  </div>
                              </div>
                      ))}
                   </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ResidentsList
