import React from 'react'
import Locator from 'components/Classes/Locator.jsx'
import {Helmet} from 'react-helmet'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import $ from 'jquery'

class PointsOfInterest extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            success: false,
            locators: [],
            loaded: false
        }

    }


    componentDidMount() {

        $("#header,.newsletter,#footer").hide();
    

        fetch(apiConfig().API_URL + "home_info?slug=home")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    this.setState({
                        locators: result.data.locators,
                        success: result.success,
                        loaded: true
                    });

                   

                },

                (error) => {

                }

                
            )

    }

    render() {


            return (

                <React.Fragment>
                    <Helmet>
                        <title>Points Of Interest</title>
                    </Helmet>

                    {this.state.success == true ? [
                            <div class="locator-only-fo-iframe">
                                <Locator locators={this.state.locators}/>
                             </div>

                    ] : ['']}


                </React.Fragment>
            )
    }

}

export default PointsOfInterest