/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import Info from 'components/Classes/Info.jsx'
import InfoBase from 'components/Classes/InfoBase.jsx'
import Locations from 'components/Classes/Locations.jsx'
import CollectionsMap from 'components/Classes/CollectionsMap.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import LifestyleDetailGallery from 'components/Classes/LifestyleDetailGallery.jsx'
import LifestyleDetailTopGallery from 'components/Classes/LifestyleDetailTopGallery.jsx'
import LifestyleDetailHeader from 'components/Classes/LifestyleDetailHeader.jsx'
import CollectionsQuickMoveIn from './CollectionsQuickMoveIn'
import PackagesList from 'components/Classes/PackagesList.jsx'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
import VirtualList from 'components/Classes/VirtualList.jsx';
import $ from 'jquery'




class Collections extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            neighbourhoods: [],
            additionalData:[],
            community_details:[],
            loaded: false,
            success: false,
            qmiFound:false,
            tabDetail:false,
            tabDetailsMeta:false,
            activeQMIs:false,
            showVirtual:false,
            showPackage:false,
            showSitemap:false,
            isQueryStringProcessed:false
        }

        this.updateTab = this.updateTab.bind(this)
        this.reloadTab = this.reloadTab.bind(this)
        this.activeQuickMoveIns = this.activeQuickMoveIns.bind(this)
        this.activeVirtual = this.activeVirtual.bind(this)
        this.activePackage = this.activePackage.bind(this)
        this.activeSitemap = this.activeSitemap.bind(this)
    }

    fetchResults(){

        fetch(apiConfig().API_URL + "neighbourhoods_info?ids="+apiConfig().API_NEIGHBORHOODS)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        neighbourhoods: result.data.posts,
                        qmiFound:result.data.qmiFound,
                        additionalData:result.additionalData,
                        community_details : result.additionalData.community_details[0],
                        success: true,
                        loaded: true,
                        tabDetail:false,
                        tabDetailsMeta:false
                    });

                    console.log("Neighbourhood APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

    }

    componentDidCatch(error,errorInfo){

        console.log("Error is ----------->");
        console.log(errorInfo);

    }

    componentDidMount() {

        this.fetchResults();

        CallTrk.swap();
    }

    checkforQueryString(){

        if(location.search.indexOf("feature_homes") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[1]).trigger("click");
            $(".collections-mantle-nav__link")[1].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }
        if(location.search.indexOf("design_studio") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[2]).trigger("click");
            $(".collections-mantle-nav__link")[2].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }

        if(location.search.indexOf("living_smart") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[3]).trigger("click");
            $(".collections-mantle-nav__link")[3].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }
        if(location.search.indexOf("virtual_tours") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[2]).trigger("click");
            $(".collections-mantle-nav__link")[2].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }
        if(location.search.indexOf("packages") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[3]).trigger("click");
            $(".collections-mantle-nav__link")[3].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }
        if(location.search.indexOf("sitemap") > -1 && this.state.isQueryStringProcessed == false && $(".collections-mantle-nav__link").length > 0){
            $($(".collections-mantle-nav__link")[4]).trigger("click");
            $(".collections-mantle-nav__link")[4].click();
            this.setState({
                isQueryStringProcessed:true
            });
        }

    }


    componentDidUpdate(){

        if(this.state.success == true){
            dataLayer.push({
                'event': 'communityId',
                'e1CommunityId': this.state.community_details.community_id ? this.state.community_details.community_id  : "",
                'salesforceId': this.state.community_details.community_of_interest ? this.state.community_details.community_of_interest : ""
            });
           }

        CallTrk.swap();

        this.checkforQueryString();
    }

    reloadTab(){

        this.setState({loaded:false,tabDetail:false,tabDetailsMeta:false,activeQMIs:false,showVirtual:false,showPackage:false,showSitemap:false});

        this.fetchResults();

    }

    activeQuickMoveIns(){

        this.setState({tabDetail:false,tabDetailsMeta:false,activeQMIs:true,loaded:true,showVirtual:false,showPackage:false,showSitemap:false});

    }
    activeVirtual(){
        this.setState({tabDetail:false,tabDetailsMeta:false,activeQMIs:false,loaded:true,showVirtual:true,showPackage:false,showSitemap:false});
    }
    activePackage(){
        this.setState({tabDetail:false,tabDetailsMeta:false,activeQMIs:false,loaded:true,showVirtual:false,showPackage:true,showSitemap:false});
    }  
    activeSitemap(){
        this.setState({tabDetail:false,tabDetailsMeta:false,activeQMIs:false,loaded:true,showVirtual:false,showPackage:false,showSitemap:true});
    }
    updateTab(link){

        this.setState({loaded:false,tabDetail:false,tabDetailsMeta:false,activeQMIs:false,showVirtual:false,showPackage:false,showSitemap:false});

        fetch(apiConfig().API_URL + "lifestyle_detail/" + link)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        tabDetail:result.data.post,
                        tabDetailsMeta: result.data.post_details_meta,
                        loaded:true
                    });

                    console.log("LifeStyle Detail APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )



    }


    render() {

        const virtual_tours = this.state.additionalData.virtual_tours;
        const showQuickMovein = this.state.qmiFound;
        const showVirtuallist= virtual_tours && virtual_tours.length > 0 ? true : false;
        const packages = this.state.additionalData.packages;
        const showPackagelist= packages && packages.length > 0 ? true : false;
        const collection_map_data = this.state.additionalData.collection_sitemap_type ==="1" ? this.state.additionalData.interactive_map : this.state.additionalData.static_sitemap;
        const showSitemapdata= collection_map_data ? true : false;
        return (
            <div>
  
                {this.state.loaded == false ? <AltisLoader/> : ''}
                <Helmet>
                    <title>{this.state.additionalData.page_title}</title>
                    <body className="collections"/>
                </Helmet>


                <h2 className="hide--visually">Find Your Home</h2>

                {this.state.success === true ? [

                    // <Overlaychat />,

                    <NavigationMantle
                        active={"left"}
                        image={this.state.additionalData.collection_banner_image ? this.state.additionalData.collection_banner_image.url:require('images/orange.jpg')}
                        message={<p>{this.state.additionalData.collecation_banner_text}</p>}
                        navigation={
                            [
                                {url: '/collections', label: 'Collections', 'reloadTab':this.reloadTab},
                                {url: '#', label: 'Quick Move-in','activeQuickMoveIns':this.activeQuickMoveIns,show:showQuickMovein},
                                {url: '#', label: 'Virtual Tours','activeVirtual':this.activeVirtual,show:showVirtuallist},
                                {url: '#', label: 'Designer Packages','activePackage':this.activePackage,show:showPackagelist},
                                {url: '#', label: 'Site Map','activeSitemap':this.activeSitemap,show:showSitemapdata},
                                {url: '#', label: 'Design Studio', linkTo:'design-studio', 'updateTab':this.updateTab,show:false},
                                {url: '#', label: 'LivingSmart', linkTo:'livingsmart','updateTab':this.updateTab,show:false}
                            ]
                        }
                        title={this.state.additionalData.collection_banner_subtext}
                        customClass="collections_list"
                    />,

                ] : ['']}

                {this.state.success === true && this.state.tabDetail == false && this.state.activeQMIs == false && this.state.showVirtual == false && this.state.showPackage == false && this.state.showSitemap==false ? [


                    this.state.neighbourhoods.length > 0 ? [
                        <Locations neighbourhoods={this.state.neighbourhoods}/>
                    ] : [''],

                    this.state.additionalData && this.state.additionalData.design_smart.ID  && this.state.additionalData.design_smart.show_on_collections ? [

                        this.state.additionalData.design_smart.info_message != "" && this.state.additionalData.design_smart.info_message != null ? [

                            <Info
                                className="charcoal"
                                message={<p dangerouslySetInnerHTML={{__html: this.state.additionalData.design_smart.info_message.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>}
                                title={this.state.additionalData.design_smart.post_title}
                            />

                        ] : [''],



                        <InfoBase
                            image={this.state.additionalData.design_smart.post_thumbnail_url}
                            message={<p dangerouslySetInnerHTML={{__html: this.state.additionalData.design_smart.post_content.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>}
                            title="Design Smart"
                        />

                    ]: [''],


                    this.state.additionalData && this.state.additionalData.living_smart.ID  && this.state.additionalData.living_smart.show_on_collections ? [

                        this.state.additionalData.living_smart.info_message != "" && this.state.additionalData.living_smart.info_message != null ? [

                            <Info
                                className="charcoal"
                                message={<p dangerouslySetInnerHTML={{__html: this.state.additionalData.living_smart.info_message.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>}
                                title={this.state.additionalData.living_smart.post_title}
                            />

                        ] : [''],



                        <InfoBase
                            image={this.state.additionalData.living_smart.post_thumbnail_url}
                            message={<p dangerouslySetInnerHTML={{__html: this.state.additionalData.living_smart.post_content.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>}
                            title="Living Smart"
                        />

                    ]: ['']








                ] : ['']}

                {this.state.tabDetail ? [


                    this.state.tabDetailsMeta.lifestyle_detail_top_video ? [

                        <LifestyleDetailTopGallery postDetailsMeta={this.state.tabDetailsMeta}/>

                    ]: [''],

                    <LifestyleDetailHeader post={this.state.tabDetail}
                                           postDetailsMeta={this.state.tabDetailsMeta}/>,


                    <LifestyleDetailGallery postDetailsMeta={this.state.tabDetailsMeta}/>,


                ] : ['']}


                {this.state.activeQMIs ? [

                        <CollectionsQuickMoveIn/>

                ] : ['']}

                {this.state.showVirtual && showVirtuallist === true ? [

                    <VirtualList virtual_tours={virtual_tours}/>

                ] : ['']}

                {this.state.showPackage && showPackagelist === true ? [

                <PackagesList packages={packages}  CollectionsDetail={false}/>

                ] : ['']}
                {this.state.showSitemap && showSitemapdata === true ? [

                <div className="collection_sitemap wrap_width virtual">
                {this.state.additionalData.collection_sitemap_type ==="1" ? (
                <iframe 
                className="wrap-inner" id="sitemap_iframe" 
                frameborder="0"  
                src={collection_map_data}
                width="100%"
                ></iframe>
                ):(
                <img className="wrap-inner" src={collection_map_data.url} alt={collection_map_data.title} title={collection_map_data.title} />
                )
                }
                </div>

                ] : ['']}
                

            </div>
        )
    }
}
export default Collections
