import React from 'react'
import $ from 'jquery'
import AltisChat from 'components/Classes/AltisChat.jsx';
import ScheduleTour from 'components/Classes/ScheduleTour.jsx'
import AltisJoinForm from 'components/Classes/AltisJoinForm.jsx'
import {withRouter} from 'react-router-dom'
class SidebarSignUp extends React.Component {

    constructor(props) {

        super(props)
        this.state = {

            form_reload: false,
            referenceId: this.props.referenceId ? this.props.referenceId : false,
            clicked: false,
            popup_submission:false,
            neigh_detail: this.props.neigh_detail

        }

        this.loadPopUpFormOpen = this.loadPopUpFormOpen.bind(this);
        this.loadPopUpFormClose = this.loadPopUpFormClose.bind(this);

    }

    reloadMarketoForm() {

        $("#mktoForm_3655").find('[type="submit"]').prop('disabled', false);
        $("#mktoForm_3655").find('[type="submit"]').text("Submit >");
        // this.setState({form_reload: false});
        // this.loadMarketoForm();
    }


    componentWillUnmount(){


        $('#form-box-div form').attr('id', 'mktoForm_3655');
    }

    loadMarketoForm() {

        var that = this;

        if (MktoForms2 != "undefined") {

            MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 3655, function (form) {

                form.onSuccess(function () {
                    dataLayer.push({'event':'interestList'});
                    if ($("#mktoForm_3655 #openQuestion1").is(":checked")) {

                        let t = new Date();
                        t.setDate(t.getDate() + 2);
                        let month = "0" + (t.getUTCMonth() + 1);
                        let date = "0" + t.getUTCDate();
                        month = month.slice(-2);
                        date = date.slice(-2);
                        date = t.getUTCFullYear() + "-" + month + "-" + date;
                        let todayToDate = Date.parse(date);


                        if ($("#mktoForm_3655 #requestedDate").val() == "") {

                            alert("Please select request date.");
                            that.reloadMarketoForm();
                            return false;
                        } else if (Date.parse($("#mktoForm_3655 #requestedDate").val()) <= todayToDate) {

                            alert("Please select a date at least three days from today. Call to schedule earlier.");
                            that.reloadMarketoForm();
                            return false;

                        } else {

                            $("#mktoForm_3655").html("");

                            that.setState({form_reload: true});
                            that.setState({popup_submission: true});

                            JQUERY(window).scrollTop($(window).scrollTop()+1);

                            return false;

                        }


                    } else {

                        $("#mktoForm_3655").html("");

                        that.setState({form_reload: true});
                        that.setState({popup_submission: true});

                        JQUERY(window).scrollTop($(window).scrollTop()+1);
                     
                        return false;


                    }


                });

                $('#mktoForm_3655 #Brand__c').on('input', function(evt) {

                    console.log("input");

                    var inputValue = $("#mktoForm_3655 #Brand__c").val();
                    inputValue = inputValue.replace(/[^a-zA-Z ]/g, "");
                    $("#mktoForm_3655 #Brand__c").val(inputValue);
                    
                    var withoutSpclChar = new RegExp(/^[\w| |_-]+$/);
                    if (!inputValue.match(withoutSpclChar)) {
                    var newInputWithHas = $("#mktoForm_3655 #Brand__c").val();
                    var newInputWithoutHas = newInputWithHas.substring(0, newInputWithHas.length - 1);
                    return $("#mktoForm_3655 #Brand__c").val(newInputWithoutHas);
                    }
                    if (inputValue.match(/\s+/g)) {
                      $("#mktoForm_3655 #Brand__c").val(spaceHandle(inputValue))
                    }
                    
                    if ((inputValue.match(new RegExp(" ", "g")) || []).length > 1) {
                    console.log();
                    $("#mktoForm_3655 #Brand__c").val(spaceHandle(inputValue))
                    var newInputValue = $("#mktoForm_3655 #Brand__c").val();
                    //var noDoubleSpace = newInputValue.replace(/ +/g, '');
                    //var NoSpaceinEnd = noDoubleSpace.replace(/ $/, "");
                    return $("#Brand__c").val(newInputValue);
                    }
                    if (/\d/.test(inputValue)) {
                    console.log("it has number");
                    var noNumber = inputValue.replace(/[0-9]/g, '');
                    return $("#mktoForm_3655 #Brand__c").val(noNumber);
                    }
                    });
                    
                    function spaceHandle(s) {
                    console.log(s);
                    var replaced = s.split(' ');
                    console.log("Replaced------------>");
                    console.log(replaced);
                    var final_string = '';
                    $.each(replaced, function(index, value) {
                        console.log("value length------------>");
                        console.log(value.length);
                    if (index == 0 && value.length > 1) {
                        final_string = final_string + value + ' ';
                    } else {
                         final_string = final_string + value;
                    }
                    })
                    console.log("Final String");
                    console.log(final_string);
                    
                    return final_string;
                    }
                    
                    $('.mktoFormCol').addClass(function(i){
                    return 'Col'+(i+1);
                    });
                    form.onValidate(function() {
                    var vals = form.vals();
                    var email = form.vals().Email;
                    var fname = form.vals().FirstName;
                    var lname = form.vals().LastName;
                    var phone = form.vals().Phone;
                    var maxLength = 2;
                    var ContactName = form.vals().Brand__c;
                    var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@test.", "@rediff."];
                    var ContactFirstName = ContactName.substr(0, ContactName.indexOf(' '));
                    var ContactLastName = ContactName.substr(ContactName.indexOf(' ') + 1); 
                    if (/\w+\s+\w+/.test($("#mktoForm_3655 #Brand__c").val())) {
                    // Enable submission for those who met the criteria
                    $('input[name="FirstName"]').val(ContactFirstName);
                    $('input[name="LastName"]').val(ContactLastName); 
                    
                    var lname = $("input[name='LastName']");
                    var lvalue = lname[0].value.length;
                    if(lvalue < maxLength){
                    form.submittable(false);
                    var CcontactNameElem = $("#mktoForm_3655").find("#Brand__c");
                    form.showErrorMessage("Please enter at least 2 character of your last name", CcontactNameElem);
                    return false;
                    }else{
                    form.submittable(true);
                    }
                    } else {
                    form.submittable(false);
                    // Show error message, pointed at VehicleSize element
                    var CcontactNameElem = $("#mktoForm_3655").find("#Brand__c");
                    form.showErrorMessage("Please enter first and last name only.", CcontactNameElem);
                    return false;
                    }
                    });


            });

            MktoForms2.whenReady(function () {


                var j = 1;
                $('#mktoForm_3655 .mktoFormRow').each(function (i, e) {
                    $(this).addClass('row-class-' + j);
                    j++;
                });


                $("#mktoForm_3655").find('[name="Communities_of_Interest__c"]').val(that.props.communityOfInterest);
                $("#mktoForm_3655").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_3655").find('[name="Division__c"]').val("Tri Pointe Homes San Diego");
                $("#mktoForm_3655").find('[name="communityName"]').val(that.props.community_name);

                // $("#mktoForm_3655").find('.row-class-6').hide();

                // if(that.props.coming_soon_neigh && that.props.coming_soon_neigh == true){
                //     $("#mktoForm_3655 .row-class-5").hide();
                // }
                // else{
                //     $("#mktoForm_3655 .row-class-5").show();
                // }

            });

        }

    }

    loadPopUpFormOpen(){

        JQUERY(window).scrollTop($(window).scrollTop()+1);
        
        $(".sidebar_form .nhs-form form").html("");

        $('.sidebar_form .nhs-form form').removeAttr('id');

        $('#form-box-div form').attr('id', 'mktoForm_3655');

       this.loadMarketoForm();

       this.setState({form_reload: false});
       this.setState({popup_submission: false});


    }
    loadPopUpFormClose(){

        JQUERY(window).scrollTop($(window).scrollTop()+1);
        
        $("#form-box-div form").html("");

        $('#form-box-div form').removeAttr('id');

        $('.sidebar_form .nhs-form form').attr('id', 'mktoForm_3655');

        this.loadMarketoForm();

        this.setState({form_reload: false}); 
        this.setState({popup_submission: false});


    }

    componentDidMount() {

        var that = this;

        $("#form-box-div form").html("");

        $('#form-box-div form').removeAttr('id');

        this.loadMarketoForm();

        this.setState({
            referenceId: this.props.referenceId
        });

        JQUERY(window).scrollTop($(window).scrollTop()+1);

        // $('.signup_close').on("click",function(){

        //     $("#form-box-div form").html("");

        //     $('#form-box-div form').removeAttr('id');

        //     $('.sidebar_form form').attr('id', 'mktoForm_3655');

        //     that.loadMarketoForm();

        //     that.setState({form_form_reload: false}); 

        // });

    }

    componentWillReceiveProps(nextProps) {

        if(this.props.location.pathname != nextProps.location.pathname){

            $("#form-box-div form").html("");

            $('#form-box-div form').removeAttr('id');

        if (this.state.form_reload === true || this.state.popup_submission === true) {

            this.loadMarketoForm();
            this.setState({form_reload: false});
            this.setState({popup_submission: false});
        }

        this.setState({
            referenceId: this.props.referenceId
        });
    }

    }

    render() {

        const new_home_specialist = this.props.new_home_specialist
        const agent_dre = this.props.agent_dre
        const agent_name = this.props.agent_name
        const communityOfInterest = this.props.communityOfInterest
        var window_width = screen.width;

        return (
            <React.Fragment>
            {/* {(window_width > 767 ) ? [
            <AltisJoinForm communityOfInterest={false} referenceId={this.state.referenceId} new_home_specialist={new_home_specialist}/>, */}
            <section className="sidebar_form sidebar-signup">
                
                {this.state.referenceId ? [

                <AltisChat referenceId={this.state.referenceId} new_home_specialist={new_home_specialist} agent_dre={agent_dre} agent_name={agent_name} post= {this.state.neigh_detail} />,
                <ScheduleTour  post= {this.state.neigh_detail} communitySalesforceId={communityOfInterest}/>

                ] : ['']}

                <button className="pop_up_btn_open form_btn" data-submit={this.state.popup_submission} onClick={this.loadPopUpFormOpen}></button>
                <button className="pop_up_btn_close form_btn" onClick={this.loadPopUpFormClose}></button>

                

                <div className="sidebar-signup-panel">

                    <p className="sidebar-signup__title">Stay Informed</p>
                    <p className="sidebar-signup__info">Join the interest list to be kept informed of the latest
                        information.</p>


                    <div className="nhs-form">
                        <form id="mktoForm_3655" className="altis-nhs-form"></form>
                    </div>

                    {this.state.form_reload === false ? (
                        <div className="sidebar-signup__disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.
                            <div class="sidebar_broker_agent"><a href="https://www.tripointehomes.com/brokers/" target="_blank" >Broker or Agent? Stay Informed Here</a></div>
                        </div>
                        
                    ) : ('')}

                    {this.state.form_reload === true ? (

                        <small className="thankyou_message">
                            Thanks for signing up to hear all the latest on Citro as it happens. Together we’re creating
                            a new kind of community, so stay tuned!.
                        </small>
                    ) : ('')}
                </div>
            </section>
            {/*  ] :[
                <AltisJoinForm communityOfInterest={false} referenceId={this.state.referenceId} new_home_specialist={new_home_specialist}/>  
            ]} */}

            </React.Fragment>
        )
    }
}

export default  withRouter(SidebarSignUp)
