import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LifestyleCategoryDetail from "../Main/views/LifestyleCategoryDetail"; // eslint-disable-line no-unused-vars

class BlogPosts extends React.Component {
  render () {
    const related = this.props.related ? this.props.related : false
    const className = related ? 'blog-posts blog-posts--related' : 'blog-posts'

    return (
      <section className={className}>
        <div className="wrap wrap--blog-posts">
          {related === true ?(
            // this.props.recentPosts.length > 0 ? (
              <header className="blog-posts-header">
                <h2 className="title title--related-blog-posts">Related {(this.props.forLifeStyle === true) ? "Activities" : "Blogs"}</h2>
              </header>
            // ):('')
          ):('')
          }

          {related !== true ? (
            <div className="blog-posts-articles">
                {this.props.recentPosts.map((post,index)=>(

                    (index != 0) ? (

                        <article className="blog-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                            <Link to={"/blog/"+post.post_name} className="blog-posts__link">
                                <figure className="blog-posts__figure">
                                    <img src={(post.tile_image.url) ? post.tile_image.url: ''} className="blog-posts__image" itemProp="image" alt="" />
                                    <figcaption className="blog-posts-overlay">
                      <span className="blog-posts-overlay-inner">
                        <small className="title title--blog-posts" itemProp="description">{(post.category[0].name) ? post.category[0].name : ''}</small>
                        <h3 className="blog-posts__title" itemProp="headline">{post.post_title}</h3>
                      </span>
                                    </figcaption>
                                </figure>
                            </Link>
                        </article>


                    ) : ('')



                ))}

            </div>
          ) : (
            <div className="blog-posts-articles">

                {this.props.relatedPosts.map((post)=>(

                        <article className="blog-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                            <Link to={(this.props.forLifeStyle === true && post.post_type =='lifestyle') ? "/lifestyle/"+post.post_name :  "/blog/"+post.post_name} className="blog-posts__link">
                                <figure className="blog-posts__figure">
                                    <img src={(post.tile_image.url) ? post.tile_image.url : ''} className="blog-posts__image" itemProp="image" alt="" />
                                    <figcaption className="blog-posts-overlay">
                      <span className="blog-posts-overlay-inner">
                        <small className="title title--blog-posts" itemProp="description">{(post.categories[0].name) ? post.categories[0].name : ''}</small>
                        <h3 className="blog-posts__title" itemProp="headline">{post.post_title}</h3>
                      </span>
                                    </figcaption>
                                </figure>
                            </Link>
                        </article>

                ))}


            </div>
          )}

          {related !== true &&
            <div className="blog-posts-more">
              {/*<button type="button" className="blog-posts-more__button">View More</button>*/}
            </div>
          }
        </div>
      </section>
    )
  }
}

BlogPosts.propTypes = {
  related: PropTypes.bool
}

export default BlogPosts
