import React from 'react'
import $ from 'jquery'
import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'
class AltisChat extends React.Component {

    constructor(props){
        super(props);

        //this.setChatButton = this.setChatButton.bind(this)

        this.state = {
            timeouts:[],
            post: this.props.post
        }

    }

    componentDidMount(){
        
        //this.setChatButton(this.props.referenceId, 1, "altis_chat");

    }

    animateChat(){

        var tmOuts = [];

        var t4 = "";

        var t3 = "";

        var t1 = setTimeout(
            function(){
                    $(".chat_btn.altis_chat_btn").animate({
                        width:"290px",
                        borderRadius:"50px",
                    },function(){
                       t3 = setTimeout(function(){
                            $(".chat_btn .chat_txt.altis_chat_txt").css("font-size","16px");
                        },500)
                        tmOuts.push(t3);
                    });

                   t4 = setTimeout(function(){
                        $(".chat_btn .chat_txt.altis_chat_txt").text("Chat with our specialist");
                    },3000)
                    tmOuts.push(t4);
            },1000);

        var t2 = setTimeout(
            function(){
                $(".chat_btn.altis_chat_btn").animate({
                    width:"60px",
                    borderRadius:"50%"
                },"fast");
                $(".chat_btn .chat_txt.altis_chat_txt").css("font-size","0");
            },8000)

            

            tmOuts.push(t1);

            tmOuts.push(t2);

            this.setState({timeouts:tmOuts});
      
    }


	// setChatButton(referenceId, engagementId, chatContainer) {

    //     var that = this;

	// 	var time = new Date().getTime();

    //     var chatButton = document.getElementById(chatContainer);

    //     var overlayChat = document.getElementById("chat-overlay");

	// 	var chatEngConfiguration = {
    //         "referenceId":referenceId,
    //         "engagementId":1,
    //         "channel":"chat"
    //     };
        

	// 	chatButton.onclick = function() {



	// 		if($("#"+chatContainer).text() == "Live Chat"){

    //             lpTag.sdk.exec("caoEngager.startChat", chatEngConfiguration);

	// 		}

    //     };


    //     overlayChat.onclick = function() {

    //           //  alert(referenceId);

	// 		//if($("#"+chatContainer).text() == "Live Chat"){


    //             lpTag.sdk.exec("caoEngager.startChat", chatEngConfiguration);

	// 		//}

    //     };
        


	// 	function presenceCallback(presenceData) {
           

	// 		if(presenceData.presenceStatus === 'online') {

    //             chatButton.innerHTML = "Live Chat";

    //             if(screen.width > 767){

    //                 $("#chat-overlay").show();

    //                 that.animateChat();

    //             }


	// 		} else {

    //             chatButton.innerHTML = "Chat Offline";
	// 		}
	// 	}
	// 	function presenceErrorCallback() {
	// 		console.log("Error checking presence");
	// 	}
	// 	var presenceReq = {
	// 		"referenceId":referenceId,
	// 		"engagementId":time,
	// 		"onSuccess": presenceCallback,
	// 		"onError":presenceErrorCallback
	// 	};
	// 	lpTag.sdk.exec("caoPresence.getPresence", presenceReq);
    // }
    
    componentWillUnmount(){
      
        this.state.timeouts.map(function(t){
 
             clearTimeout(t);
 
        });
 
     }

    render() {

        const new_home_specialist = this.props.new_home_specialist
        const agent_dre = this.props.agent_dre
        const agent_name = this.props.agent_name

        return (

           <div>

              {/* <div style={{display:"none"}} id='chat-overlay' className="chat_btn altis_chat_btn"><a href="javascript:void(0)"><img src={require('images/chat_btn.svg')} /><span className="chat_txt altis_chat_txt">Have Any Questions?</span></a></div> */}

               <header className="sidebar-signup-header">
                    
                    {/* <a class="schedule_tour_button">Schedule a Tour</a> */}

                   <div className="sidebar-signup-header-image">
                       <img src={new_home_specialist.post_thumbnail} className="sidebar-signup-header__image"
                            alt="Agent"/>
                   </div>
                   <div className="sidebar-signup-header-info">
                       <p className="sidebar-signup__agent">{agent_name}</p>
                       <h2 className="title title--sidebar-signup agent_label">New Home Specialist</h2>
                       <small className="sidebar-signup__license">{agent_dre}</small>
                       <a href={"tel:"+new_home_specialist.agentInfo[4].phone}
                              className="sidebar-phone-button">{new_home_specialist.agentInfo[4].phone}</a>
                   </div>
               </header>
                 {/* 6439 */}
               
                    {(this.props.post.show_tour_btn == true ) ? (
                    <div className="tour_btn">
                        <a data-fancybox="tour-form" data-src="#tour_form" data-fancybox-group="tour-form" href="javascript:void(0)" className="schedule_tour_button fancybox"><i className="far fa-calendar-alt"></i>Schedule a Tour</a>
                    </div>
                    ):('')}
                


               {/* <div className="sidebar-signup-contact">
                   <ul className="sidebar-signup-contact__list">
                       <li className="sidebar-signup-contact__item sidebar-signup-contact__phone">
                           <a href={"tel:"+new_home_specialist.agentInfo[3].phone}
                              className="sidebar-signup-contact__link sidebar-signup-contact__link--phone">{new_home_specialist.agentInfo[3].phone}</a>
                       </li>
                       <li className="sidebar-signup-contact__item sidebar-signup-contact__chat">
                           <a className="sidebar-signup-contact__link sidebar-signup-contact__link--chat" id="altis_chat"></a>
                       </li>

                   </ul>
               </div> */}

		   </div>
            )

    }
}

export default AltisChat
