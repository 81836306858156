import React from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars

class Features extends React.Component {
  render () {
    const message1 = this.props.message1
    const message2 = this.props.message2
    const title1 = this.props.title1
    const title2 = this.props.title2
    const brochures = this.props.brochures[0] ? this.props.brochures[0] : ''
    return (
      <section className="features">
        <Element id="details">
          <div className="features-columns">
            <div className="features-column features-column--1">
              {title1 && title1.length &&
                <h2 className="features__title">{title1}</h2>
              }
              {message1}
            </div>

              {/* {
                message2 && message2.length > 0 ? [

                    <div className="features-column features-column--2">
                        {title2 && title2.length &&
                        <h2 className="features__title">{title2}</h2>
                        }
                        <span dangerouslySetInnerHTML={{__html: message2.replace(/(?:\r\n|\r|\n)/g, '')}}></span>
                    </div>

                ]  : ['']
              } */}
              {
                brochures != '' ? [
                  <li class="brochure"> <a className="download-brochure" target="blank" href={brochures.pdf}> <img src={require('images/brochure.png')} alt="broucher_image" title="broucher_image"/>View Brochure</a></li>
           
                ]  : ['']
              }
          </div>
        </Element>
      </section>
    )
  }
}

Features.propTypes = {
  message1: PropTypes.object.isRequired,
  message2: PropTypes.object.isRequired,
  title1: PropTypes.string,
  title2: PropTypes.string
}

export default Features
