import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import {toggleClass} from 'components/Classes/Helpers.jsx'
import LocatorMapContainer from 'components/Classes/LocatorMapContainer.jsx' // eslint-disable-line no-unused-vars

import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class Locator extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            locators: props.locators[0],
            panelInfo: {
                locatorInfo: [],
                selectedPlace: [],
                index: 0,
                size: 0
            }
        };


        this.updateLocation = this.updateLocation.bind(this)
        this.eventLink = this.eventLink.bind(this)
    }

    componentDidUpdate() {

        console.log("Updated states locator");
        console.log(this.state);

    }

    updateLocation(e) {
        let active = document.querySelector('[data-title].active')
        let el = e.currentTarget
        let title = document.getElementById('title')
        let value = el.getAttribute('data-title')
        let dataId = el.getAttribute('data-id')

        if (this.props.locators.length > 0 && (this.props.locators[dataId].locator_results != 0 || this.props.locators[dataId].locator_results.length > 0)) {
            this.setState({
                locators: this.props.locators[dataId]
            });
        }


        active.classList.remove('active')
        el.classList.add('active')
        title.textContent = value

        e.preventDefault()
    }

    eventLink(link) {

        window.location.href = link;
    }

    render() {

        const settings = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1499,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 1399,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 599,
                    settings: {
                        arrows: false,
                        dots: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        return (
            <section className="locator">
                <h2 className="hide--visually">Find Your Home</h2>

                <div className="wrap--locator">
                    <div className="wrap-inner">
                        <section className="locator-slider">
                            <Slider {...settings}>
                                {


                                    this.props.locators.map((locator, index) => (

                                        <div className="slick-slider-holder">
                                            <a href={"#locator-" + (index + 1)} onClick={this.updateLocation}
                                               data-title={locator.name}
                                               className={(index + 1) == 1 ? 'locator-slider__link active' : 'locator-slider__link'}
                                               data-id={index}>
                                                <img src={locator.image.url} className="locator-slider__image"
                                                     alt="Nature"/>
                                                <span className="locator-slider-overlay">
                      <p className="title title--locator-slider">{locator.name}</p>
                    </span>
                                            </a>
                                        </div>
                                    ))}


                            </Slider>
                        </section>

                        <section className="locator-finder">
                            <div className="locator-finder-columns">
                                <div className="locator-finder-column locator-finder-column--map">
                                    <div className="locator-finder__map">
                                        <LocatorMapContainer locators={this.state.locators}/>
                                    </div>
                                </div>

                                <div className="locator-finder-column locator-finder-column--sidebar">
                                    <div className="locator-finder-sidebar">
                                        <header className="locator-finder-header">
                                            <div className="locator-finder-header-inner">
                                                <h3 className="title title--locator-finder" id="title">{this.state.locators.name}</h3>
                                            </div>
                                        </header>

                                        <div className="locator-finder-results">
                                            <ul className="locator-finder-results__list">

                                                {
                                                    (this.state.locators.locator_results.length > 0 && this.state.locators.locator_results != 0) ? this.state.locators.locator_results.map((r, i) => (

                                                        <li className="locator-finder-results__item">
                                                            <h4 className="locator-finder-results__title" onClick={() => (toggleClass('panel-active', 'panel-transition', 300),

                                                                    this.setState({
                                                                        panelInfo: {
                                                                            locatorInfo: this.state.locators,
                                                                            selectedPlace: r,
                                                                            index: i,
                                                                            size: this.state.locators.locator_results.length
                                                                        }
                                                                    })

                                                            )}>{r.name}</h4>
                                                            <div className="locator-finder-results-info">
                                                                <p>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{__html: r.description.substr(0, 60).replace(/(?:\r\n|\r|\n)/g, '<br />') + "... "}}></span>
                                                                    <span
                                                                        className="locator-finder-results__more"
                                                                        onClick={() => (toggleClass('panel-active', 'panel-transition', 300),

                                                                                this.setState({
                                                                                    panelInfo: {
                                                                                        locatorInfo: this.state.locators,
                                                                                        selectedPlace: r,
                                                                                        index: i,
                                                                                        size: this.state.locators.locator_results.length
                                                                                    }
                                                                                })

                                                                        )}
                                                                    >more</span>
                                                                </p>
                                                            </div>
                                                        </li>


                                                    )) : ''

                                                }


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        {this.state.panelInfo.selectedPlace.length != 0 ? <aside className="locator-panel">
                            <div className="locator-panel__panel">
                                <button
                                    className="locator-panel__button-close"
                                    onClick={() => toggleClass('panel-active', 'panel-transition', 300)}
                                    title="Close Menu"
                                    type="button"
                                >Close Menu
                                </button>


                                <div className="locator-panel-image">
                                    <img src={this.state.panelInfo.selectedPlace.image.url}
                                         className="locator-panel__image"
                                         alt="The Bite"/>
                                    <div className="locator-panel-overlay">
                                        <p>{this.state.panelInfo.selectedPlace.image.alt}</p>
                                    </div>
                                </div>

                                <div className="locator-panel-info">
                                    <div className="locator-panel-buttons">
                                        <button
                                            className="locator-panel__button locator-panel__button--prev"
                                            type="button" onClick={()=>{

                                                let panelInfo = this.state.panelInfo;
                                                if(panelInfo.index -1 != -1){

                                                    let previousIndex = panelInfo.index - 1;

                                                    panelInfo.selectedPlace = this.state.locators.locator_results[previousIndex];
                                                    panelInfo.index = previousIndex;

                                                    this.setState({
                                                        panelInfo:panelInfo
                                                    });

                                                }
                                        }}
                                        >
                                            <span className="hide--visually">Previous</span>
                                        </button>

                                        <button
                                            className="locator-panel__button locator-panel__button--next"
                                            type="button" onClick={() => {

                                            let panelInfo = this.state.panelInfo;
                                            if((panelInfo.index +1) < panelInfo.size){

                                                let nextIndex = panelInfo.index +1;

                                                panelInfo.selectedPlace = this.state.locators.locator_results[nextIndex];
                                                panelInfo.index = nextIndex;

                                                this.setState({
                                                    panelInfo:panelInfo
                                                });

                                            }
                                        }}
                                        >
                                            <span className="hide--visually">Next</span>
                                        </button>
                                    </div>

                                    <header className="locator-panel-header">
                                        <p className="title title--locator-panel-header">{this.state.panelInfo.locatorInfo.name}</p>
                                        <h2 className="locator-panel-header__title">{this.state.panelInfo.selectedPlace.name}</h2>
                                    </header>

                                    <p dangerouslySetInnerHTML={{__html: this.state.panelInfo.selectedPlace.description.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>

                                    <small className="locator-panel__category">Distance</small>
                                    <p className="title title--locator-panel">{this.state.panelInfo.selectedPlace.distance}</p>
                                    <small className="locator-panel__category">Hours</small>

                                    {(this.state.panelInfo.selectedPlace.hours != 0 && this.state.panelInfo.selectedPlace.hours.length > 0) ?

                                        this.state.panelInfo.selectedPlace.hours.map(h => (
                                            <p className="title title--locator-panel">{h.hour}</p>
                                        ))

                                        : ''}


                                    {(this.state.panelInfo.selectedPlace.upcoming_events != 0 && this.state.panelInfo.selectedPlace.upcoming_events.length > 0) ? [

                                        <small className="locator-panel__category">Upcoming Events</small>,
                                        (this.state.panelInfo.selectedPlace.upcoming_events.map((event) => [

                                            <p className="title title--locator-panel">
                                                {event.date}
                                            </p>,
                                            <ul className="locator-panel__list">
                                                <li className="locator-panel__item locator-panel__item--info">
                                                    <p>{event.description}</p>
                                                </li>
                                                <li className="locator-panel__item locator-panel__item--button">
                                                    <button type="button" className="locator-panel__rsvp"
                                                            onClick={() => (this.eventLink(event.link))}>RSVP
                                                    </button>
                                                </li>
                                            </ul>

                                        ]))


                                    ] : ('')}

                                </div>
                            </div>

                            <button
                                className="locator-panel__button-overlay"
                                onClick={() => toggleClass('panel-active', 'panel-transition', 300)}
                                title="Close"
                                type="button"
                            >Close
                            </button>
                        </aside> : ''}

                    </div>
                </div>
            </section>
        )
    }
}

export default Locator
