import React from 'react'

class AltisLoader extends React.Component {

    render() {

        return (

            <div id="loader">
                <div id="loader-icon"><img src={require('images/pre-loader.gif')} /></div>
            </div>

            )

    }
}

export default AltisLoader