import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import { Link as ScrollLink } from 'react-scroll' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class Locations extends React.Component {
  render () {
    const settings = {
      arrows: true,
      dots: false,
      draggable: false,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <section className="locations">
        <h2 className="hide--visually">Locations</h2>

        <div className="wrap wrap--locations">
          <div className="wrap-inner">

              {this.props.neighbourhoods.map(neighbourhood =>(

                  <div className="locations-columns">
                      <div className="locations-column locations-column--image">
                          <div className="locations-slider">
                              <Slider {...settings}>


                                  { neighbourhood.gallery_images.length > 0 ? [

                                      neighbourhood.gallery_images.map((gal)=>(
                                        gal.image != false ? [
                                          <div>
                                              <Link to={"/collection/"+neighbourhood.post_name} className="locations__link">
                                         
                                                  <img src={gal.image.url} className="locations__image" alt={neighbourhood.post_title} />
                                                  <span className="locations-overlay"></span>
                                              </Link>
                                          </div>
                                        ]:['']

                                      ))


                                  ] : [
                                      <div>
                                      <Link to={"/collection/"+neighbourhood.post_name} className="locations__link">
                                          <img src={neighbourhood.post_thumbnail ? neighbourhood.post_thumbnail : require('media/default-526x337.png')} className="locations__image" alt={neighbourhood.post_title} />
                                          <span className="locations-overlay"></span>
                                      </Link>
                                  </div>
                                  ]}



                              </Slider>
                          </div>
                      </div>

                      <div className="locations-column locations-column--content">
                          <div className="locations-info-columns">
                              <div className="locations-info-column locations-info-column--title">
                                  <h3 className="locations-info__title">{neighbourhood.post_title}</h3>
                                  {(neighbourhood.pricing_prefixes != 'None'  && neighbourhood.display_price) || neighbourhood.pricing_prefixes == "Pricing...We're working on it." ? (
                                        <p className="locations-info__description">{neighbourhood.pricing_prefixes != 'None' ? neighbourhood.pricing_prefixes : ''} {neighbourhood.display_price && neighbourhood.pricing_prefixes != "Pricing...We're working on it." ? neighbourhood.display_price : ''}</p>
                                  ):('')}
                              </div>

                              <div className="locations-info-column locations-info-column--specs">
                                <ul className="locations-info__list">
                                        {neighbourhood.min_bed != '' || neighbourhood.max_bed != "" ? [
                                        <li className="locations-info__item">
                                           {neighbourhood.min_bed > 1 || neighbourhood.max_bed  > 1 ? (  
                                            <small className="locations-info__caption">Beds</small>
                                        ):(
                                            <small className="locations-info__caption">Bed</small>
                                        )}
                                            {neighbourhood.min_bed == neighbourhood.max_bed ? [

                                                <p className="locations-info__description">{neighbourhood.min_bed}</p>

                                            ] : [
                                                <p className="locations-info__description">
                                                    <span>{neighbourhood.min_bed}</span>
                                                    {neighbourhood.min_bed && neighbourhood.max_bed  ? ("-"):('')}
                                                    <span>{neighbourhood.max_bed  ? (neighbourhood.max_bed ) : ('')}</span>
                                                </p>
                                            ]}
                                            
                                        </li>
                                        ] : ['']}
                                        {neighbourhood.min_bath!= '' || neighbourhood.max_bath != "" ? [
                                        <li className="locations-info__item">
                                        {neighbourhood.min_bath > 1 || neighbourhood.max_bath  > 1 ? (  
                                            <small className="locations-info__caption">Baths</small>
                                        ):(
                                            <small className="locations-info__caption">Bath</small>
                                        )}
                                            {neighbourhood.min_bath == neighbourhood.max_bath ? [
                                                    <p className="locations-info__description">{neighbourhood.min_bath}</p>   
                                            ] : [
                                                    <p className="locations-info__description">
                                                        <span>{neighbourhood.min_bath}</span>
                                                        {neighbourhood.min_bath && neighbourhood.max_bath  ? ("-") :('')}
                                                        <span>{neighbourhood.max_bath  ? ( neighbourhood.max_bath ) : ('')}</span>
                                                    </p>
                                            ]}
                                            
                                        </li>
                                        ] : ['']}
                                        {neighbourhood.min_sqft != '' || neighbourhood.max_sqft != "" ? (

                                            <li className="locations-info__item">
                                                <small className="locations-info__caption">Sq Ft</small>
                                                <p className="locations-info__description"><NumberFormat value={neighbourhood.min_sqft} displayType={'text'} thousandSeparator={true} />{neighbourhood.min_sqft && neighbourhood.max_sqft  ? ("-") :('')}<NumberFormat value={neighbourhood.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                                            </li>


                                        ) : ('')}

                                 </ul>
                              </div>
                          </div>
                          {neighbourhood.long_description ? (
                                <div className="locations-details">
                                  <small className="locations-info__caption">Details</small>
                                  <p className="locations-info__message" dangerouslySetInnerHTML={{__html: neighbourhood.long_description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                </div>
                          ):('')}
                      
                          <div className="locations-buttons">
                              <Link to={"/collection/"+neighbourhood.post_name} className="locations__button">Details</Link>
                              {/*<ScrollLink className="locations__button" to="map"*/}
                                          {/*duration={750}*/}
                                          {/*offset={-60}*/}
                                          {/*smooth={true}*/}
                                          {/*spy={false}*/}
                              {/*>Map</ScrollLink>*/}
                          </div>
                      </div>
                  </div>

              ))}









          </div>
        </div>
      </section>
    )
  }
}

export default Locations
