/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import Info from 'components/Classes/Info.jsx'
import LifestyleInterestsPosts from 'components/Classes/LifestyleInterestsPosts.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class LifestyleInterests extends React.Component {


    constructor() {
        super()
        this.state = {
            page: {},
            success: false,
            terms: [],
            pageTitle:'',
            loaded: false

        }
    }

    componentDidMount() {

        fetch(apiConfig().API_URL + "lifestyle_interest")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        terms: result.data.terms,
                        page: result.data.page,
                        pageTitle:result.data.pageTitle,
                        success: result.success,
                        loaded: true

                    });

                    console.log("Lifestyle Interest APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    <Helmet>
                        <title>{this.state.pageTitle}</title>
                        <body className="lifestyle-interests"/>
                    </Helmet>

                    {
                        this.state.success ? [

                            // <Overlaychat />,

                            <h2 className="hide--visually">Lifestyle Interests</h2>,

                            <NavigationMantle
                                active="right"
                                image={this.state.page.thumbnail_url}
                                message={this.state.page.header_message.message}
                                navigation={
                                    [
                                        {url: '/lifestyles', label: 'Amenities'},
                                        {url: '/lifestyles/interests', label: 'Enthusiast Groups'}
                                    ]
                                }
                                title={this.state.page.header_message.text}
                            />,

                            <Info
                                className="lifestyle-interests"
                                message={<p dangerouslySetInnerHTML={{__html:this.state.page.info_message.message.replace(/(?:\r\n|\r|\n)/g, '<br />')}}></p>}
                                title={this.state.page.info_message.text}
                            />,

                            <LifestyleInterestsPosts terms={this.state.terms}/>


                        ] : ('')
                    }

            </div>
        )
    }
}

export default LifestyleInterests
