import React from 'react'
import { Helmet } from 'react-helmet' // eslint-disable-line no-unused-vars
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import {apiConfig} from 'components/Classes/Helpers.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import ResidentsList from 'components/Classes/ResidentsList.jsx'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Resident extends React.Component {


    constructor(){
        super()

        this.state = {
            success:false,
            loaded:false,
            residents:[],
            pageTitle:'',
            data:[]
          
        }

    }


    componentDidMount(){

        $(".newsletter").hide();

        $(document).on("click",".join_btn_new",function(){

            if($('.newsletter').css('display') == 'none'){

                window.location.href="/#newsletter";

            }
            
        });



        fetch(apiConfig().API_URL + "residents")
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    data:result.data,
                    residents: result.data.residents,
                    pageTitle:result.data.post_title,
                    success: result.success,
                    loaded:true
                });

                console.log("Resident APi Response");
                console.log(result);

            },

            (error) => {

            }
        )

      


    }

    componentWillUnmount(){
        
        $(".newsletter").show();
    }


    render(){

        const residents = this.state.residents
        const pageTitle = this.state.pageTitle
        const data = this.state.data

        return(

                <div className="residents_list">
                      {this.state.loaded == false ? <AltisLoader/> : ''}
                      <Helmet>
                            <title>{pageTitle}</title>
                            <body className="collections-detail"/>
                      </Helmet>
                      {/* <Overlaychat />, */}
                      {
                           this.state.success === true ? [

                            <NavigationMantle
                            active="left"
                            image={require('images/orange.jpg')}
                            message={<h2>Citro Residents</h2>}
                            navigation={
                                [
                                    {url: "#", label: 'Collections',show:false},
                                    {url: "#", label: 'Collections',show:false}

                                ]
                            }
                            title=""
                            customClass={"collection-detail-mantle"}
                        />,


                           ] : ['']

                      }


                      {
                          residents.length > 0 ? [

                            <ResidentsList residents={residents} description={data.description} />

                          ] : [

                            <div style={{textAlign:'center',padding:"50px"}}>
                                <h3>No Resident Found.</h3>
                            </div>
                          ]
                      }

                      
                      

                </div>
        )

    }


}

export default Resident