import React from 'react'
import PropTypes from 'prop-types'

class Info extends React.Component {
  render () {
    const className = this.props.className ? `info-block info-block--${this.props.className}` : 'info-block'
    const message = this.props.message
    const title = this.props.title
    const image = this.props.image ? this.props.image : false

    return (
      message && title ? (
      <section className={className}>
        <div className="wrap--info-block">
          <div className="wrap wrap-inner info-container">
            {title && title.length &&
              <h2 className="title title--info-block">{title}</h2>
            }
            <div className="info-block__message">
                <p>{message}</p>
                {
                  image ? [
                    <div className="info-msg-image">
                      <img src={image} />
                    </div>
                  ]: ['']
                }
            </div>
          </div>
        </div>
      </section>
    ):(''))
  }
}

Info.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
  title: PropTypes.string
}

export default Info
