import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import BlogPosts from "../../Classes/BlogPosts"; // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import CategoryMantle from 'components/Classes/CategoryMantle.jsx'
import LifestyleGoBack from 'components/Classes/LifestyleGoBack.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class LifestyleCategoryDetail extends React.Component {

    constructor() {
        super()
        this.state = {
            posts: [],
            category: {},
            pageTitle:"",
            success: false,
            loaded: false
        }
    }

    componentDidMount() {

        fetch(apiConfig().API_URL + "lifestyle_category/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        posts: result.data.posts,
                        category: result.data.category,
                        pageTitle:result.data.pageTitle,
                        success: true,
                        loaded: true
                    });

                    console.log("Life Style Category APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();


    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    {this.state.success ? [

                        <Helmet>
                            <title>{this.state.pageTitle}</title>
                            <body className="blog"/>
                        </Helmet>,
                        //  <Overlaychat />,
                        <LifestyleGoBack/>,
                        <CategoryMantle title={this.state.category.name}
                                        message={this.state.category.description}
                        />,

                    ] : ('')}


                    {
                        (this.state.success && this.state.posts.length > 0) ? (

                            <BlogPosts relatedPosts={this.state.posts} related={true} forLifeStyle={true}/>

                        ) : ('')

                    }



            </div>
        )
    }

}

LifestyleCategoryDetail.propTypes = {
    related: PropTypes.bool
}

export default LifestyleCategoryDetail
