import React from 'react'
import {getBreakpoint} from 'components/Classes/Helpers.jsx'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars
import $ from 'jquery'
import NumberFormat from 'react-number-format'

class QuickMoveIn extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleMouseOver = this.handleMouseOver.bind(this)
        this.handleMouseOut = this.handleMouseOut.bind(this)
        this.handleTouchStart = this.handleTouchStart.bind(this)
        this.state = {
            filter: null,
            isHover: false,
            activeNeighbourhood:{
                id:this.props.postData.currentId,
                title: this.props.postData.title
            },
            orderBy:this.props.postData.orderBy

        }

        this.updateQMIResults = this.updateQMIResults.bind(this)

        console.log("PostData--------->");
        console.log(this.state.activeNeighbourhood);
    }

    getFilter(e) {
        return e.currentTarget.getAttribute('data-filter')
    }

    handleClick(e) {
        if (getBreakpoint() < 768) {
            let $el = $(e.currentTarget)
            let $menu = $el.next('[data-filter-menu]')

            $el.closest('[data-filter]').toggleClass('active')
            $menu.slideToggle()
        }
    }

    handleMouseOver(e) {
        if (getBreakpoint() >= 768) {
            this.setState({
                filter: this.getFilter(e),
                isHover: true
            })
        }
    }

    handleMouseOut() {
        if (getBreakpoint() >= 768) {
            this.setState({
                filter: null,
                isHover: false
            })
        }
    }

    handleTouchStart(e) {
        if (getBreakpoint() >= 768) {
            if (this.state.isHover && this.state.filter === this.getFilter(e)) {
                this.setState({
                    filter: null,
                    isHover: false
                })
            } else {
                this.setState({
                    filter: this.getFilter(e),
                    isHover: true
                })
            }
        }
    }

    updateQMIResults(id,title,orderBy){

        this.setState({
            activeNeighbourhood:{
                id:id,
                title:title
            },
            orderBy:orderBy
        },function(){
            this.props.updateResults(id,this.state.orderBy);
            console.log("Active Neighborhood---->");
            console.log(this.state.activeNeighbourhood);
        });



    }

    componentWillReceiveProps(nextProps) {


        this.props = nextProps;

        let activeNeighbourhood = this.state.activeNeighbourhood;
        if(activeNeighbourhood.id == ""){

            activeNeighbourhood.id = nextProps.postData.currentId;
        }

        this.setState({

            activeNeighbourhood:activeNeighbourhood

        });


    }


    render() {
        const filter = this.state.filter
        const isHover = this.state.isHover

        const neighbourhoods = this.props.neighbourhoods
        const qmis = this.props.qmis
     

        return (
            <section className="quick">
                <h2 className="hide--visually">Quick Move In</h2>

                <div className="wrap wrap--quick">
                    <section className="quick-filters">
                        <div className="quick-filters-columns">
                            <div className="quick-filters-column quick-filters-column--collection">
                                <div
                                    className={`quick-filter ${isHover && filter === 'collection' ? 'hover' : ''}`}
                                    data-filter="collection"
                                    onMouseOut={this.handleMouseOut}
                                    onMouseOver={this.handleMouseOver}
                                    onTouchStart={this.handleTouchStart}
                                >

                                    <h3
                                        className="title title--quick-filter"
                                        onClick={this.handleClick}
                                    >Collection : {this.state.activeNeighbourhood.title}</h3>

                                    <ul data-filter-menu className="quick-filter__list">

                                        <li className="quick-filter__item">
                                            <a className={this.state.activeNeighbourhood.id == "" ? "quick-filter__link altis-active-a" : 'quick-filter__link'} onClick={()=>{this.updateQMIResults("","All",this.state.orderBy)}}>All</a>
                                        </li>
                                    
                                        {neighbourhoods.map((neighbourhood) => (
                                            <li className="quick-filter__item">
                                                <a className={this.state.activeNeighbourhood.id == neighbourhood.ID ? "quick-filter__link altis-active-a" : 'quick-filter__link'} onClick={()=>{this.updateQMIResults(neighbourhood.ID,neighbourhood.post_title,this.state.orderBy)}}>{neighbourhood.post_title}</a>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>

                            {/* <div className="quick-filters-column quick-filters-column--sort">
                                <div
                                    className={`quick-filter ${isHover && filter === 'sort' ? 'hover' : ''}`}
                                    data-filter="sort"
                                    onMouseOut={this.handleMouseOut}
                                    onMouseOver={this.handleMouseOver}
                                    onTouchStart={this.handleTouchStart}
                                > */}
                                    {/* <h3
                                        className="title title--quick-filter"
                                        onClick={this.handleClick}
                                    >Sort By</h3>

                                    <ul data-filter-menu className="quick-filter__list">
                                        <li className="quick-filter__item">
                                            <a className={this.state.orderBy == 'title' ? "quick-filter__link altis-active-a" : "quick-filter__link"} onClick={()=>{this.updateQMIResults(this.state.activeNeighbourhood.id,this.state.activeNeighbourhood.title,"title")}}>Title</a>
                                        </li>
                                        <li className="quick-filter__item">
                                            <a className={this.state.orderBy == 'date' ? "quick-filter__link altis-active-a" : "quick-filter__link"} onClick={()=>{this.updateQMIResults(this.state.activeNeighbourhood.id,this.state.activeNeighbourhood.title,"date")}}>Date</a>
                                        </li>
                                    </ul> */}
                                {/* </div>
                            </div> */}

                            {/* <div className="quick-filters-column quick-filters-column--filter"> */}
                                {/* <div
                                    className={`quick-filter ${isHover && filter === 'filter' ? 'hover' : ''}`}
                                    data-filter="filter"
                                    onMouseOut={this.handleMouseOut}
                                    onMouseOver={this.handleMouseOver}
                                    onTouchStart={this.handleTouchStart}
                                > */}
                                    {/*<h3*/}
                                        {/*className="title title--quick-filter"*/}
                                        {/*onClick={this.handleClick}*/}
                                    {/*>Filter By</h3>*/}

                                    {/*<ul data-filter-menu className="quick-filter__list">*/}
                                        {/*<li className="quick-filter__item">*/}
                                            {/*<a className="quick-filter__link">Option 1</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="quick-filter__item">*/}
                                            {/*<a className="quick-filter__link">Option 2</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="quick-filter__item">*/}
                                            {/*<a className="quick-filter__link">Option 3</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="quick-filter__item">*/}
                                            {/*<a className="quick-filter__link">Option 4</a>*/}
                                        {/*</li>*/}
                                        {/*<li className="quick-filter__item">*/}
                                            {/*<a className="quick-filter__link">Option 5</a>*/}
                                        {/*</li>*/}
                                    {/*</ul>*/}
                                {/* </div> */}
                            {/* </div> */}
                        </div>
                    </section>

                    <article className="quick-collection">
                        {qmis.length > 0 ? [

                            <ul className="quick-collection__list">

                                {qmis.map((qmi) => (
                                    qmi.qmistatus !='Sold' ? (
                                    <li className="quick-collection__item" itemScope
                                        itemType="http://schema.org/Product">
                                        <div className="quick-collection-header">
                                            <h3 className="quick-collection-header__title"
                                                itemProp="name">{ qmi.qmi_neigh + " " +qmi.post_title}</h3>
                                            { (qmi.qmi_price != "" && qmi.qmi_price != null) || qmi.pricing_prefixes == "Pricing...We're working on it."  ? [
                                                <div className="quick-collection-header__price" itemProp="offers"
                                                     itemScope
                                                     itemType="http://schema.org/Offer">
                                                    <span>{qmi.pricing_prefixes != "None" ? qmi.pricing_prefixes : ''}</span>
                                                    {qmi.qmi_price && qmi.pricing_prefixes != "Pricing...We're working on it." ? [
                                                        <span itemProp="priceCurrency" content="USD"> $</span>,
                                                        <span itemProp="price" content="400000.00">{qmi.qmi_price}</span>
                                                    ]:['']}
                                                </div>
                                            ] : ['']}
                                        </div>

                                        <Link to={"/collection/"+qmi.neighborhood.post_name+"/"+qmi.planInfo.post_name+"/"+qmi.post_name}  className="quick-collection__link">
                                    
                                            <img src={qmi.post_thumbnail ? qmi.post_thumbnail: require('media/default-555x370.png')} className="quick-collection__image"
                                                 itemProp="image" alt="Vista"/>
                                            <span className="quick-collection-overlay"></span>
                                        </Link>

                                        <div className="quick-collection-info">
                                            <ul className="quick-collection-info__list">
                                                {qmi.bed != "" && qmi.bed != null && qmi.bed != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">
                                                        {qmi.bed > 1 ? ['Beds'] : ['Bed']}
                                                        </p>
                                                        <p className="quick-collection-info__info">{qmi.bed}</p>
                                                    </li>

                                                ] : ['']}

                                                {qmi.bath != "" && qmi.bath != null && qmi.bath != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">
                                                        {qmi.bath > 1 ? ['Baths'] : ['Bath']}
                                                        </p>
                                                        <p className="quick-collection-info__info">{qmi.bath}</p>
                                                    </li>
                                                ] : ['']}

                                                {qmi.sqft != "" && qmi.sqft != null && qmi.sqft != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">Sq Ft</p>
                                                        <p className="quick-collection-info__info"><NumberFormat value={qmi.sqft} displayType={'text'} thousandSeparator={true} /></p>
                                                    </li>
                                                ] : ['']}

                                                <li className="quick-collection-info__item">
                                                    <p className="quick-collection-info__title">Plan</p>
                                                    <p className="quick-collection-info__info">{qmi.planInfo.post_title}</p>
                                                </li>

                                                {qmi.show_video_tour == true && qmi.qmi_video_tour_link != "" ? [
                                                    <li className="qmi_video_tour">
                                                    <a href={qmi.qmi_video_tour_link} target="_blank"><i class="fas fa-video"></i>Tour</a>
                                                    </li>
                                                ]:['']
                                                }

                                                {/* <li className="quick-collection-info__item">
                                                    <p className="quick-collection-info__title">Homesite</p>
                                                    <p className="quick-collection-info__info">{qmi[1].homesite_number}</p>
                                                </li> */}
                                            </ul>
                                        </div>

                                        {/*<div className="quick-collection-compare">*/}
                                            {/*<input id="vista" name="vista" type="checkbox"/>*/}
                                            {/*<label htmlFor="vista">Compare</label>*/}
                                        {/*</div>*/}
                                    </li>):('')

                                ))}


                            </ul>


                        ] : [
                            <div  class="not-found">
                                  <p>No Quick Move-in for this collection.</p>
                            </div>
                           
                        ]}

                    </article>
                </div>
            </section>
        )
    }
}

export default QuickMoveIn
