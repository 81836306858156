import React from 'react'
import PropTypes from 'prop-types'
import {Element} from 'react-scroll' // eslint-disable-line no-unused-vars
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'

class FeaturedPlans extends React.Component {
    render() {
        const slug = this.props.slug
      
        return (
            <article className="featured-plans inner_wrap">
                <Element id="plans">
                    <h2 className="hide--visually">Featured Plans</h2>

                    <div id="featured-plans">
                         {/* 5959 */}
                    <h2 class="featured-plans-header__title1">Floorplans</h2>
                        <ul className="featured-plans__list">
                            {this.props.plans.map(plan => (

                                <li className="featured-plans__item" itemScope itemType="http://schema.org/Product">
                                    <div className="featured-plans-header">
                                    <Link to={'/collection/'+slug+'/'+plan.post_name} className="featured-plans__link">
                                        <h3 className="featured-plans-header__title"
                                            itemProp="name">{plan.post_title}</h3>
                                    </Link>        

                                        {plan.base_price != "" || plan.pricing_prefixes == "Pricing...We're working on it." ? [

                                            <div className="featured-plans-header__price" itemProp="offers" itemScope
                                                 itemType="http://schema.org/Offer">
                                                <span>{plan.pricing_prefixes!= "None" ? plan.pricing_prefixes: ''} </span>
                                                {/* <span itemProp="priceCurrency" content="USD">$</span> */}
                                                <span itemProp="price"
                                                      content="400000.00">{plan.base_price != "" && plan.pricing_prefixes != "Pricing...We're working on it." ? <NumberFormat value={plan.base_price.slice(0,-3)} displayType={'text'} thousandSeparator={false} prefix={'$'} suffix={'s'} /> : ''}</span>
                                            </div>


                                        ] : ['']}

                                    </div>

                                    <Link to={'/collection/'+slug+'/'+plan.post_name} className="featured-plans__link">
                                  
                                        <img src={plan.post_thumbnail ? plan.post_thumbnail : require('media/default-555x370.png')} className="featured-plans__image"
                                             itemProp="image" alt={plan.post_title}/>
                                        <span className="featured-plans-overlay"></span>
                                    </Link>

                                    <div className="featured-plans-info">
                                        <ul className="featured-plans-info__list">

                                            {(plan.min_bed != '' || plan.max_bed != '') ? [

                                                <li className="featured-plans-info__item">
                                                    {plan.min_bed > 1 || plan.max_bed > 1 ? (   
                                                        <p className="featured-plans-info__title">Beds</p>
                                                    ):(
                                                        <p className="featured-plans-info__title">Bed</p>
                                                    )}
                                                 
                                                    {plan.min_bed == plan.max_bed ? [

                                                        <p className="featured-plans-info__info">{plan.min_bed}</p>

                                                    ] : [
                                                        <p className="featured-plans-info__info">{plan.min_bed}{plan.min_bed && plan.max_bed  ? ("-") :('')}{plan.max_bed}</p>
                                                    ]}
                                                    
                                                </li>

                                            ] : ['']}


                                            {(plan.min_bath != '' || plan.max_bath != "") ? [

                                                <li className="featured-plans-info__item">
                                                    {plan.min_bath > 1 || plan.max_bath > 1 ? (   
                                                       <p className="featured-plans-info__title">Baths</p>
                                                    ):(
                                                        <p className="featured-plans-info__title">Bath</p>
                                                    )}
                                                    
                                                    {plan.min_bath == plan.max_bath ? [

                                                        <p className="featured-plans-info__info">{plan.min_bath}</p>

                                                    ] : [
                                                        <p className="featured-plans-info__info">{plan.min_bath}{plan.min_bath && plan.max_bath  ? ("-") :('')}{plan.max_bath}</p>
                                                    ]}
                                                    
                                                </li>

                                            ] : ['']}


                                            {(plan.min_sqft != '' || plan.max_sqft !='') ? [

                                                <li className="featured-plans-info__item">
                                                    <p className="featured-plans-info__title">Sq Ft</p>
                                                    {plan.min_sqft == plan.max_sqft ? [

                                                        <p className="featured-plans-info__info"><NumberFormat value={plan.min_sqft} displayType={'text'} thousandSeparator={true} /></p>

                                                    ] : [

                                                        <p className="featured-plans-info__info"><NumberFormat value={plan.min_sqft} displayType={'text'} thousandSeparator={true} />{plan.min_sqft && plan.max_sqft  ? ("-") :('')}<NumberFormat value={plan.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                                                    ]}
                                                    
                                                </li>

                                            ] : ['']}
                                                    <li className="featured-plans-info__item flooplan_icon">
                                                        <Link to={'/collection/'+slug+'/'+plan.post_name}><img src={require('images/floor-plan-icon.png')}/></Link>
                                                    </li>

                                        </ul>
                                        {/*<div className="featured-plans-like">*/}
                                            {/*<button type="button" className="featured-plans-like__button">*/}
                                                {/*<i className="icon icon--heart"></i>*/}
                                                {/*<span className="hide--visually">Like</span>*/}
                                            {/*</button>*/}
                                        {/*</div>*/}
                                    </div>
                                </li>


                            ))}


                        </ul>
                    </div>
                </Element>
            </article>
        )
    }
}

FeaturedPlans.propTypes = {
    slug: PropTypes.string.isRequired
}

export default FeaturedPlans
