import React from 'react'
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs' // eslint-disable-line no-unused-vars

class PlansDetailFloorPlan extends React.Component {
  constructor (props) {
    super(props)
    this.handlePrint = this.handlePrint.bind(this)
    this.state = { tabIndex: 0 }
  }

  handlePrint (e) {
    e.preventDefault()
     window.print();
  }

  render () {

    const floorPlans = this.props.floorPlans
    return (
      <section className="plans-detail-floor-plan">

          {floorPlans.length > 0 ? [

              <Element id="plans">
                  <h2 className="hide--visually">Floor Plan</h2>

                  <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                      
                      {floorPlans.length > 1 ? [

                        <TabList>
                        {floorPlans.map((fp,index)=>(
                            <Tab>
                                <button type="button" className="plans-detail-floor-plan__button">{fp.image_title}</button>
                            </Tab>
                        ))}

                        </TabList>


                      ] : ['']}
                  

                      <div className="react-tabs-holder">
                          {floorPlans.map((fp,index)=>(
                          fp.image != false ? (
                         
                          <TabPanel>
                              <img src={fp.image.url} className="plans-detail-floor-plan__image" alt={"Floor "+index+1} />
                              {fp.floor_preliminary == true ? [
                                   <div id="watermark"><p id="watermark-bg-text">Preliminary</p></div>  
                              ]: ['']}
                                                   
                          </TabPanel>
                            ):('')
                          ))}


                          <div className="plans-detail-floor-plan-controls">
                              {/*<button type="button" className="plans-detail-floor-plan-controls__button icon--plus">*/}
                                  {/*<span className="hide--visually">Zoom Plus</span>*/}
                              {/*</button>*/}
                              {/*<button type="button" className="plans-detail-floor-plan-controls__button icon--minus">*/}
                                  {/*<span className="hide--visually">Zoom Minus</span>*/}
                              {/*</button>*/}
                              <button
                                  className="plans-detail-floor-plan-controls__button icon--printer"
                                  onClick={this.handlePrint}
                                  type="button"
                              >
                                  <span className="hide--visually">Print</span>
                              </button>
                          </div>
                      </div>
                  </Tabs>
              </Element>

          ] : ['']}

      </section>
    )
  }
}

export default PlansDetailFloorPlan
