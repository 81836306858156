import React from 'react'
import PropTypes from 'prop-types'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars

import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class PlansDetailElevation extends React.Component {
  render () {
    const plan = this.props.plan
    const elevations = this.props.elevations

    return (
      <section className="plans-detail-elevation">

          {elevations.length > 0 ? [

              <Element id="elevations">
                  <h2 className="hide--visually">Elevations</h2>

                  <ul className="plans-detail-elevation__list">

                      {elevations.map((el,i)=>(

                          <li className="plans-detail-elevation__item">
                              <a href={el.image.url} data-fancybox data-fancybox-group="elevation" className="plans-detail-elevation__link">
                                  <img src={el.image.url} className="plans-detail-elevation__image" alt="Elevation 1" />
                                  <span className="plans-detail-elevation-overlay"></span>
                              </a>
                              <div className="plans-detail-elevation-info">
                                  <h3 className="plans-detail-elevation-info__title">{plan}</h3>
                                  <p className="plans-detail-elevation-info__description">Elevation {i+1}</p>
                              </div>
                          </li>

                      ))}

                  </ul>
              </Element>


          ]: ['']}

      </section>
    )
  }
}

PlansDetailElevation.propTypes = {
  plan: PropTypes.string.isRequired
}

export default PlansDetailElevation
