import React from 'react'
import {Link} from 'react-router-dom' 
import NumberFormat from 'react-number-format'

class SingleCollectionQuick extends React.Component {
   

    render() {
    
        const qmis = this.props.qmis


        return (
            <section className="quick collection_quick">
                <h2 className="hide--visually">Quick Move In</h2>

                <div className="wrap wrap--quick">
                  
                    <article className="quick-collection">
                        {qmis.length > 0 ? [

                            <ul className="quick-collection__list">

                                {qmis.map((qmi) => (

                                    <li className="quick-collection__item" itemScope
                                        itemType="http://schema.org/Product">
                                        <div className="quick-collection-header">
                                            <h3 className="quick-collection-header__title"
                                                itemProp="name">{ qmi.qmi_neigh + " " +qmi.post_title}</h3>
                                            {(qmi.qmi_price != "" && qmi.qmi_price != null) || qmi.pricing_prefixes == "Pricing...We're working on it." ? [
                                                <div className="quick-collection-header__price" itemProp="offers"
                                                     itemScope
                                                     itemType="http://schema.org/Offer">
                                                    <span>{qmi.pricing_prefixes != "None" ? qmi.pricing_prefixes : ''}</span>
                                                    {qmi.qmi_price && qmi.pricing_prefixes != "Pricing...We're working on it." ? [
                                                        <span itemProp="priceCurrency" content="USD"> $</span>,
                                                    <span itemProp="price" content="400000.00">{qmi.qmi_price}</span>

                                                    ]:['']}
                                                 </div>
                                            ] : ['']}
                                        </div>

                                        <Link to={"/collection/"+qmi.neighborhood.post_name+"/"+qmi.planInfo.post_name+"/"+qmi.post_name} className="quick-collection__link">
                                            <img src={qmi.post_thumbnail ? qmi.post_thumbnail: require('media/default-555x370.png')} className="quick-collection__image"
                                                 itemProp="image" alt="Vista"/>
                                            <span className="quick-collection-overlay"></span>
                                        </Link>

                                        <div className="quick-collection-info">
                                            <ul className="quick-collection-info__list">
                                                {qmi.bed != "" && qmi.bed != null && qmi.bed != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">
                                                        {qmi.bed > 1 ? ['Beds'] : ['Bed']}
                                                        </p>
                                                        <p className="quick-collection-info__info">{qmi.bed}</p>
                                                    </li>

                                                ] : ['']}

                                                {qmi.bath != "" && qmi.bath != null && qmi.bath != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">
                                                        {qmi.bath > 1 ? ['Baths'] : ['Bath']}
                                                        </p>
                                                        <p className="quick-collection-info__info">{qmi.bath}</p>
                                                    </li>
                                                ] : ['']}

                                                {qmi.sqft != "" && qmi.sqft != null && qmi.sqft != 0 ? [

                                                    <li className="quick-collection-info__item">
                                                        <p className="quick-collection-info__title">Sq Ft</p>
                                                        <p className="quick-collection-info__info"><NumberFormat value={qmi.sqft} displayType={'text'} thousandSeparator={true} /></p>
                                                    </li>
                                                ] : ['']}

                                                <li className="quick-collection-info__item">
                                                    <p className="quick-collection-info__title">Plan</p>
                                                    <p className="quick-collection-info__info">{qmi.planInfo.post_title}</p>
                                                </li>

                                                {qmi.show_video_tour == true && qmi.qmi_video_tour_link != "" ? [
                                                    <li className="qmi_video_tour">
                                                    <a href={qmi.qmi_video_tour_link} target="_blank"><i class="fas fa-video"></i>Tour</a>
                                                    </li>
                                                ]:['']
                                                }

                                                {/* <li className="quick-collection-info__item">
                                                    <p className="quick-collection-info__title">Homesite</p>
                                                    <p className="quick-collection-info__info">{qmi[1].homesite_number}</p>
                                                </li> */}
                                            </ul>
                                        </div>

                                    </li>

                                ))}


                            </ul>


                        ] : [
                            <div  class="not-found">
                                  <p>No Quick Move-in for this collection.</p>
                            </div>
                           
                        ]}

                    </article>
                </div>
            </section>
        )
    }
}

export default SingleCollectionQuick
