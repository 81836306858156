import React from 'react'
import PropTypes from 'prop-types'

class BasicMantle extends React.Component {
  render () {
    const image = this.props.image
    const message = this.props.message
    const title = this.props.title
    const customClass = this.props.customClass ? this.props.customClass : ''
    return (
      <section className={"basic-mantle "+customClass}>
        <div className="wrap--basic-mantle">
          <div className="basic-mantle-holder">
            <img src={image} className="basic-mantle__image" alt="" />
            <div className="basic-mantle-overlay">
              <div className="basic-mantle-info">
                {title && title.length &&
                  <h1 className="title title--basic-mantle">{title}</h1>
                }
                {message &&
                  <div className="basic-mantle__message">
                    {message}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

BasicMantle.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.object,
  title: PropTypes.string
}

export default BasicMantle
