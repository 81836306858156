import React from 'react'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react' // eslint-disable-line no-unused-vars
import active from 'images/altis-map.png'

export class ContactMapContainer extends React.Component {
  render () {
    return (
      <Map
        className={'map'}
        google={this.props.google}
        initialCenter={{
          lat: 33.345311,
          lng: -117.151164
        }}
        zoom={14}
      >
        <Marker
          icon={{
            url: active,
            anchor: new google.maps.Point(48,69),
            scaledSize: new google.maps.Size(48,69)
          }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC-ILs_PxdVHTRQqgudhC8lydTXH_5PyGQ'
})(ContactMapContainer)
