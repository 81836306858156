import React from 'react'

class InteractiveMediaIcons extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {

    const interactive_media = this.props.interactive_media;
    const Envision = this.props.Envision;
    const Show_Envision = this.props.Show_Envision;
    const forqmi = this.props.forqmi == true ? true : false;
    var iframe__url;
    var iframe_link;
    return (
      <section className="interactive_media_icons">
          {( interactive_media && interactive_media.length > 0 ) || (Envision != '' && Show_Envision == true) ? [
                <ul id="interactive_icons">
                    {
                      ( interactive_media && interactive_media.length > 0 ) ? [
                        interactive_media.map((fp,index)=>(
                      iframe__url = iframe_link = '',
                      iframe_link = fp.interactive_url, 
                      iframe_link.includes("&amp;") ? [
                        iframe__url = iframe_link.replace("&amp;", "&")
                      ]:[
                        iframe__url = iframe_link
                      ], 
                      fp.interactive_title != '' && fp.interactive_url != '' ? [
                        <li>
                          <a href={iframe__url} target="_blank">
                            <span className={fp.interactive_type == 'VirtualWalkthrough' ? 'virtual-icon' : 'floorplan-icon'}></span>
                            <span>{fp.interactive_title}</span>
                          </a>
                        </li>
                      ]:['']
                    ))
                      ]:['']                    
                    }
                
                    {Envision != '' && Show_Envision == true  ? [
                        <li>
                          <a href={Envision} target="_blank">
                            <span className="envision-icon"></span>
                            <span>Personalize this Home</span>
                          </a>
                      </li>
                    ]:['']
                    }
                </ul>
          ] : ['']}
      </section>
    )
  }
}

export default InteractiveMediaIcons
