/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import CollectionsQuick from 'components/Classes/CollectionsQuick.jsx'
import Info from 'components/Classes/Info.jsx'
import InfoBase from 'components/Classes/InfoBase.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';

class CollectionsQuickMoveIn extends React.Component {

    constructor() {
        super()
        this.state = {
            postData:{
                ids:apiConfig().API_NEIGHBORHOODS,
                currentId:"",
                title:"",
                orderBy:"ID"
            },
            success: false,
            qmiCommunity:false,
            loaded: false,
            qmis: [],
            neighbourhoods: []

        }

        this.updateResults = this.updateResults.bind(this)
    }

    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "lotByNeighbourhood",{method:"POST",body:JSON.stringify(this.state.postData)})
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        qmis: result.data.quickmovein,
                        neighbourhoods: result.data.neighbourhoods,
                        success: result.success,
                        qmiCommunity:result.data.qmiCommunity,
                        loaded: true
                    });

                    let postData = this.state.postData;
                    postData.currentId = (postData.currentId == "" && result.data.qmiCommunity != false ) ? result.data.qmiCommunity :  postData.currentId;
                    postData.title = (postData.title == "" && result.data.qmiCommunityTitle != false ) ? result.data.qmiCommunityTitle :  postData.title;


                    this.setState({
                        postData:postData
                    });


                },

                (error) => {

                }
            )

    }

    componentWillReceiveProps(nextProps) {


        this.props = nextProps;

        this.fetchResult();

    }

    componentDidMount() {

        this.fetchResult();
        CallTrk.swap();
    }


    componentDidUpdate(){

        CallTrk.swap();
    }

    updateResults(id,orderBy = "ID"){



        let postData = this.state.postData;
        postData.currentId = id;
        postData.orderBy = orderBy;
       this.setState({
           postData:postData
       },function(){
           this.fetchResult();
       });

    }


    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                {this.state.success == true ? [

                    // <Overlaychat />,

                    // <Helmet>
                    //     <title>Find Your Home | Altis</title>
                    //     <body className="quick-move-in"/>
                    // </Helmet>,

                    <h2 className="hide--visually">Quick Move-In</h2>,

                    // <NavigationMantle
                    //     active="right"
                    //     image={require('images/bg-wood.jpg')}
                    //     message={<p>Once you fall in love with Altis, it's just a matter of choosing the home that fits
                    //         you and your lifestyle.</p>}
                    //     navigation={
                    //         [
                    //             {url: '/collections', label: 'Collections'},
                    //             {url: '/collections/quick-move-in', label: 'Featured Homes'}
                    //         ]
                    //     }
                    //     title="Find your home"
                    // />,

                    this.state.postData.title != "" ? [

                        <CollectionsQuick  postData={this.state.postData} updateResults={this.updateResults} neighbourhoods={this.state.neighbourhoods} qmis={this.state.qmis}/>,
                   
                    ] : ['']
                   

                    // <Info
                    //     className="charcoal"
                    //     message={<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed tortor justo,
                    //         ut tempus augue.</p>}
                    //     title="Design Studio"
                    // />,

                    // <InfoBase
                    //     image={require('media/collections-info-quick-move-in.jpg')}
                    //     message={<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed tortor justo,
                    //         ut tempus augue. Sed malesuada molestie sapien, eget pharetra tellus blandit vel. Duis
                    //         mattis semper viverra. Mauris id pharetra tortor. Fusce ultrices sapien felis, et tristique
                    //         diam. Nam gravida euismod convallis. Ut nunc sapien, egestas vitae suscipit eget, volutpat
                    //         lobortis nisi.</p>}
                    //     title="Design Studio"
                    // />


                ] : ['']}

            </div>
        )
    }
}

export default CollectionsQuickMoveIn
