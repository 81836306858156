import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import UpcomingEvents from 'components/Classes/UpcomingEvents.jsx'
import LatestEvents from 'components/Classes/LatestEvents.jsx' // eslint-disable-line no-unused-vars
import EventsList from'components/Classes/EventsList.jsx'
import EventCategories from'components/Classes/EventCategories.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Event extends React.Component {

    constructor(props) {
        super(props)

        this.state = {

            events: [],
            success: false,
            loaded: false
        };

        console.log("Events Props");
        console.log(this.props);
       
    }

    fetchEvents = (date) => {

       // this.setState({loaded: false});

      

        var endpoint = "events"

        if(typeof date != 'undefined'){

           var y = date.getFullYear();
           var m = (date.getMonth() + 1)
           var d = date.getDate();

            if(date.getDate() < 10){

                d = "0"+date.getDate();
            }

            if((date.getMonth()+1) < 10){

                m = "0"+(date.getMonth()+1);
            }


            endpoint = endpoint+'?feature='+y+m+d;

        }

       

    
        if(location.search.indexOf('group-event') > -1){
            

            var groupType = this.getUrlParameter('group-event');

           
            if(endpoint.indexOf("?feature") > -1){

                endpoint = endpoint+'&group-event='+groupType;

            }else{
                endpoint = endpoint+'?group-event='+groupType;
            }

        }


        fetch(apiConfig().API_URL + endpoint)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({

                    events:result.data,
                    success: true,
                    loaded: true
                });

                console.log("Events APi Response");
                console.log(result);

            },

            (error) => {

            }
        )

    }

    componentDidMount() {

        this.fetchEvents();

        CallTrk.swap();
    }


    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        const page = this.state.events.page;

        const groupEvents = this.state.events.group_events;

        const groupEventHeading =  this.state.events.group_event_heading;

        var isgrpeventQuery = false;

        let eventqueryString = location.search;

        console.log(eventqueryString);

        if(eventqueryString != ""){


            if(eventqueryString.indexOf("group-event") > -1){

                isgrpeventQuery = true;
            }

        }

        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}

                {this.state.success ? (

                    <section className="Events">          
                    <Helmet>
                        <title>{page.pageTitle}</title>
                        <body className="event"/>
                    </Helmet>
                    {/* <Overlaychat /> */}
                    <h2 className="hide--visually">Event</h2>


                        {isgrpeventQuery == false ? [

                            <UpcomingEvents
                            title={page.page_fields.heading}
                            message={<p>{page.page_fields.description}</p>}
                            />,

                                    
                            <LatestEvents all_event_dates={this.state.events.all_event_dates} events={this.state.events.feature_events} fetchEvents={this.fetchEvents}  pdfurl={page.page_fields.pdf_url}/>,


                        
                            <EventsList events={this.state.events.upcoming_events}/>,

                            <EventCategories events={this.state.events.Event_Categories}/>

                        ] : [
                            <div className="grp_event_result">
                                <EventsList
                                heading={groupEventHeading} events={groupEvents}
                                />,
                                <div className="view_all_events">
                                    <Link to={"/events"} className="view_allevents__button">View All Events</Link>
                                </div>
                            </div>    
                        ]}

                    </section>

                ) : ('')}

               
            </div>
        )
    }
}

export default Event