import React from 'react'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars

class AwardsDetails extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <section className="awards-detail-content">
                <div className="wrap ">
                    <div className="awards-detail-text">
                        {this.props.page_meta.awards.map((award)=>(
                        <div className="awards-div">
                             { award.awards_links != '' ? [
                                <a href={award.awards_links}>
                                    <p className={"award-text"}>{award.award_description}</p>
                                    <img src={award.award_image}/>
                                </a>]:[
                                    <p className={"award-text"}>{award.award_description}</p>,
                                    <img src={award.award_image}/>
                                ]}
                        </div>))}
                    </div>
                </div>
            </section>
        )
    }
}

export default AwardsDetails
