import React from 'react'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars

class BlogMantle extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault()
    }

    render() {
        return (
            <section className="blog-mantle">
                <div className="blog-mantle-wrap">

                    <Link to={"/blog/"+this.props.latestPost.post_name}>  <div className="blog-mantle-holder">
                        <img src={this.props.latestPost.post_thumbnail_url} className="blog-mantle__image" alt="Blog"/>
                        <span className="blog-mantle-overlay">
              <span className="blog-mantle-info">
                <small className="title title--blog-mantle" itemProp="description">{this.props.latestPost.category[0].name}</small>
                <h3 className="blog-mantle__title" itemProp="headline">{this.props.latestPost.post_title}</h3>
              </span>
            </span>
                    </div></Link>

                    <div className="blog-mantle-nav">
                        <h2 className="title title--blog-mantle-nav">Categories</h2>

                        <ul className="blog-mantle-nav__list">

                            {
                                this.props.categories.map(cat => (
                                    <li className="blog-mantle-nav__item">
                                        <Link to={"/category/"+cat.slug}
                                           className="blog-mantle-nav__link">{cat.name}</Link>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogMantle
