import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class EventsList extends React.Component {

    constructor(props){

        super(props)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.state = {
            width: window.innerWidth,
            responsive:{
                0:{
                    items:1,
                },
                480:{
                    items:2,
                },
                1024:{
                    items:3,
                },
            },
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }
    componentWillMount() {
        window.addEventListener('resize', this.updateWindowDimensions());
      }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
     }
    handleGoBack () {
        this.props.history.goBack();
    }
    render () {
    const {heading,events} = this.props;
    const { width } = this.state;
    const options = this.state.options
      return (
        events.length > 0 && heading ? [
          <section className="Events-list">
             <div className="wrap wrap-event-list-post">
                    <div className="event-container event-list-container">
                        <div className="event-title-wrapper">
                            {heading && heading.length &&
                                    <h2 className="event-title">{heading}</h2>                        
                            }
                        </div>
                {events.length > 0 ? [
                    width <= 1024  ? (
                        <OwlCarousel
                            className={'owl-theme past-event'}
                            loop={false}
                            nav={true}
                            dots={false}
                            stagePadding={50}
                            responsive={this.state.responsive} 
                                >
                        

                        {events.map((event)=>(

                                <div className={"event-box"}>
                                    <Link to={'/event/'+event.post_name}>
                                    <div className="event-image">
                                        <img src={(event.event_metas.image && event.event_metas.image != "") ? event.event_metas.image.url: ''}/>
                                        <p className={"event-date "+event.event_metas.event_type+"_date"}><span>{event.startDate.month}</span>{event.startDate.day}</p>
                                    </div>
                                    <div className={"event-heading "+event.event_metas.event_type+"_date"}>
                                        <p>{event.post_title}</p>
                                    </div>
                                    </Link>
                                </div>  
                        ))}
                        </OwlCarousel>
                ) : (   
                        events.map((event)=>(

                                <div className={"event-box"}>
                                    <Link to={'/event/'+event.post_name}>
                                    <div className="event-image">
                                        <img src={(event.event_metas.image && event.event_metas.image != "") ? event.event_metas.image.url: ''}/>
                                        <p className={"event-date "+event.event_metas.event_type+"_date"}><span>{event.startDate.month}</span>{event.startDate.day}</p>
                                    </div>
                                    <div className={"event-heading "+event.event_metas.event_type+"_date"}>
                                        <p>{event.post_title}</p>
                                    </div>
                                    </Link>
                                </div>  
                        ))
                    )
                         ] : ['']}
                </div>
            </div>    
        </section>
        ]:['']
      )
    }
    }

    // UpcomingEvents.propTypes = {
    //     message: PropTypes.object,
    //     title: PropTypes.string
    //   }
      
      export default EventsList    