import React from 'react'
import { Helmet } from 'react-helmet' // eslint-disable-line no-unused-vars

class NoMatch extends React.Component {
  render () {
    return (
      <div>
        <Helmet>
          <title>Error | Citro</title>
          <body className="error" />
        </Helmet>

        <h2 className="hide--visually">Sorry, Page not found.</h2>

        <section className="">
          <div className="wrap">
            Sorry, Page not found.
          </div>
        </section>
      </div>
    )
  }
}

export default NoMatch
