import React from 'react'
import PropTypes from 'prop-types'
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars
import {Link} from 'react-router-dom' 
import NumberFormat from 'react-number-format'

class PlansDetailHeader extends React.Component {
    render() {
        const plan = this.props.plan

        return (
            <section className="plans-detail-header">
                <div className="plans-detail-header-columns">
                    <header className="plans-detail-header-column plans-detail-header-column--header">
                        <h2 className="plans-detail-header__title">{plan.neighbourhood.post_title} {plan.post_title}</h2>
                        {plan.base_price != "" || plan.pricing_prefixes == "Pricing...We're working on it." ? (

                            <p className="plans-detail-header__description">{plan.pricing_prefixes != "" && plan.pricing_prefixes != "None" ? plan.pricing_prefixes : ''} {plan.base_price != "" && plan.pricing_prefixes != "Pricing...We're working on it." ? <NumberFormat value={plan.base_price.slice(0,-3)} displayType={'text'} thousandSeparator={false} prefix={'$'} suffix={'s'} /> : ''}</p>
                        ) : ('')}

                    </header>

                    <div className="plans-detail-header-column plans-detail-header-column--main">
                        <ul className="plans-detail-header__list">
                            {(plan.min_bed != '' || plan.max_bed != '') ? [
                                <li className="plans-detail-header__item">
                                    {plan.min_bed > 1 || plan.max_bed > 1 ? (   
                                        <small className="plans-detail-header__caption">Beds</small>
                                    ):(
                                        <small className="plans-detail-header__caption">Bed</small>
                                    )}
                                  
                                    {plan.min_bed == plan.max_bed ? [

                                        <p className="plans-detail-header__description">{plan.min_bed}</p>

                                    ] : [
                                        <p className="plans-detail-header__description">{plan.min_bed}{plan.min_bed && plan.max_bed ? "-":""}{plan.max_bed}</p>
                                    ] }
                                    
                                </li>
                            ] : ['']}

                            {(plan.min_bath != '' || plan.max_bath != "") ? [
                                <li className="plans-detail-header__item">
                                    {plan.min_bath > 1 || plan.max_bath > 1 ? (   
                                        <small className="plans-detail-header__caption">Baths</small>
                                    ):(
                                        <small className="plans-detail-header__caption">Bath</small>
                                    )}
                                   
                                    {plan.min_bath == plan.max_bath ? [

                                        <p className="plans-detail-header__description">{plan.min_bath}</p>

                                    ] : [

                                        <p className="plans-detail-header__description">{plan.min_bath}{plan.min_bath && plan.max_bath ? "-":""}{plan.max_bath}</p>

                                    ] }
                                    
                                </li>

                            ] : ['']}

                            {(plan.min_sqft != '' || plan.max_sqft !='') ? [
                                <li className="plans-detail-header__item">
                                    <small className="plans-detail-header__caption">Sq Ft</small>
                                    {plan.min_sqft == plan.max_sqft ? [

                                    <p className="plans-detail-header__description"><NumberFormat value={plan.min_sqft} displayType={'text'} thousandSeparator={true} /></p>

                                    ] : [

                                    <p className="plans-detail-header__description"><NumberFormat value={plan.min_sqft} displayType={'text'} thousandSeparator={true} />{plan.min_sqft && plan.max_sqft  ? ("-") :('')}<NumberFormat value={plan.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                                    ]}
                                    
                                </li>
                            ] : ['']}
                        </ul>

                        {/*<div className="plans-detail-header-like">*/}
                            {/*<button type="button" className="plans-detail-header-like__button">*/}
                                {/*<i className="icon icon--heart"></i>*/}
                                {/*<span className="hide--visually">Like</span>*/}
                            {/*</button>*/}
                        {/*</div>*/}
                    </div>
                </div>

                <nav className="plans-detail-header-nav">
                    <ul className="plans-detail-header-nav__list">
                    {plan.description != "" ? [
                        <li className="plans-detail-header-nav__item plans-detail-header-nav__item--details">
                            <ScrollLink className="plans-detail-header-nav__link" to="details"
                                        duration={750}
                                        offset={-60}
                                        smooth={true}
                                        spy={false}
                            >Details</ScrollLink>
                        </li>
                           ] : []}
                        


                        {plan.gallery_images[0] ? [

                        <li className="plans-detail-header-nav__item plans-detail-header-nav__item--gallery">
                            <ScrollLink className="plans-detail-header-nav__link" to="gallery"
                                        duration={750}
                                        offset={-60}
                                        smooth={true}
                                        spy={false}
                            >Gallery</ScrollLink>
                        </li>


                        ] : []}

                        {plan.interactive_tab_content_details.length > 0 ? [

                        <li className="plans-detail-header-nav__item plans-detail-header-nav__item--floor-plans">
                            <ScrollLink className="plans-detail-header-nav__link" to="interactive_tabs"
                                        duration={750}
                                        offset={-100}
                                        smooth={true}
                                        spy={false}
                            >Floor Plans</ScrollLink>
                        </li>


                        ]: ['']}



                        {plan.Elevation.length > 0 ? [

                            <li className="plans-detail-header-nav__item plans-detail-header-nav__item--elevations">
                                <ScrollLink className="plans-detail-header-nav__link" to="elevations"
                                            duration={750}
                                            offset={-60}
                                            smooth={true}
                                            spy={false}
                                >Elevations</ScrollLink>
                            </li>

                        ]: []}

                        {plan.hasQMI ? [
                            <li className="featurehomes_btn">
                                <Link to={"/collection/"+plan.neighbourhood.post_name+"?tab=feature_homes"}>
                                    Quick Move-in
                                </Link>
                            </li>
                        ]: []
                        }

                    </ul>
                </nav>
            </section>
        )
    }
}

PlansDetailHeader.propTypes = {
    plan: PropTypes.string.isRequired
}

export default PlansDetailHeader
