import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import $ from 'jquery'

class NavigationMantle extends React.Component {

  constructor(props){
    super(props);
    console.log("Navigation Count");
    console.log(props.navigation);

  }

  componentDidMount(){

    $(document).find(".collections-mantle-nav__link").click(function(){
        $(".collections-mantle-nav__link").removeClass('active');
        $(this).addClass('active');
    });

  }

  render () {


    const classCollections = this.props.active === 'left' ? 'active' : ''
    const classQuick = this.props.active === 'right' ? 'active' : ''


    const image = this.props.image
    const message = this.props.message
    const status = this.props.status
    
    const navigation = this.props.navigation;
      navigation[0].show =  navigation[0].show == false ?  false : true;


      navigation[1].show =  navigation[1].show == false ?  false : true;


      if(navigation[2]){

        navigation[2].show =  navigation[2].show == false ?  false : true;

      }

      if(navigation[3]){

        navigation[3].show =  navigation[3].show == false ?  false : true;

      }

      var navLength = 2;

      if(navigation.length > 0){

        console.log("Naviations are --------->");
        console.log(navigation);

        navLength = 0;

        navigation.map(function(v){

          console.log("v is---------->");
          console.log(v);

            if(v.show == true){
              navLength++;
            }

        });

      }
      
    const title = this.props.title

      const customClass = this.props.customClass ? this.props.customClass : ''
      const less = this.props.navigation[1].show ? 0 : 1

    return (
      <section className="collections-mantle">
        <div className={"wrap--collections-mantle "+customClass}>
          <div
            className="collections-mantle-holder"
            alt='Banner Image' style={{ backgroundImage: 'url(' + image + ')' }}
          >
            <div className="wrap-inner">
              {title ? (
                <h1 className="title title--collections-mantle">{title}</h1>
              ):('')}
              
              {message ? (
                <div className="collections-mantle__message">
                    <p>{message}</p>
                    {/* 5959 */}
                    {status !='' ? (
                        <p className="neigh-status">{status}</p>
                    ):('')}
                </div>
              ):('')}
            </div>

            <div className="collections-mantle-nav">
              <ul className="collections-mantle-nav__list">
              {
                navigation[0] && navigation[0].show ? [
                  <li className={"collections-mantle-nav__item part-"+(navLength)}>
                    <Link to={navigation[0].url} className={"collections-mantle-nav__link "+classCollections} onClick={(e)=>{ navigation[0].reloadTab ? [ e.preventDefault(), navigation[0].reloadTab()] : '' }}>{navigation[0].label}</Link>
                  </li>
                ] : ['']
              }
                  {navigation[1] && navigation[1].show ? [

                      <li className={"collections-mantle-nav__item part-"+(navLength)}>
                      {navigation[1].activeQuickMoveIns ? [

                          <Link to={navigation[1].url} className={"collections-mantle-nav__link "+classQuick } onClick={(e)=>{ e.preventDefault(), navigation[1].activeQuickMoveIns ? navigation[1].activeQuickMoveIns() : '' }}>{navigation[1].label}</Link>

                      ]: [

                         <Link to={navigation[1].url} className={"collections-mantle-nav__link "+classQuick }>{navigation[1].label}</Link>
                      ]}
                         
                      </li>

                  ] : ['']}

                  {navigation[2] && navigation[2].show  ? [

                      navigation[2].activePackages || navigation[2].activeVirtual ? [

                        navigation[2].activePackages ? [
                            <li className={"collections-mantle-nav__item part-"+(navLength)} >
                              <Link to={navigation[2].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(), navigation[2].activePackages ? navigation[2].activePackages() : '' }}>{navigation[2].label}</Link> 
                            </li>
                        ]:[
                            <li className={"collections-mantle-nav__item part-"+(navLength)}>
                              <Link to={navigation[2].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(),navigation[2].activeVirtual ? navigation[2].activeVirtual() : '' }}>{navigation[2].label}</Link>
                            </li>
                        ]
                      ]:[

                        <li className={"collections-mantle-nav__item part-"+(navLength)} >
                        <Link to={navigation[2].url}  className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(), navigation[2].updateTab ? navigation[2].updateTab(navigation[2].linkTo) : '' }}>{navigation[2].label}</Link>
                        </li>


                      ]
                   

                   

                  ] : ['']}

                  {navigation[3] && navigation[3].show  ? [
                    navigation[3].activePackage ? [
                      <li className={"collections-mantle-nav__item part-"+(navLength)} >
                        <Link to={navigation[3].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(), navigation[3].activePackage ? navigation[3].activePackage() : '' }}>{navigation[3].label}</Link> 
                      </li>
                  ]:[
                      <li className={"collections-mantle-nav__item part-"+(navLength)}>
                          <Link to={navigation[3].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(),navigation[3].updateTab ? navigation[3].updateTab(navigation[3].linkTo) : '' }}>{navigation[3].label}</Link>
                      </li>
                                          ]

                  ] : ['']}

            {navigation[4] && navigation[4].show  ? [
                    navigation[4].activeSitemap ? [
                      <li className={"collections-mantle-nav__item part-"+(navLength)} >
                        <Link to={navigation[4].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(), navigation[4].activeSitemap ? navigation[4].activeSitemap() : '' }}>{navigation[4].label}</Link> 
                      </li>
                  ]:[
                      <li className={"collections-mantle-nav__item part-"+(navLength)}>
                          <Link to={navigation[4].url} className={"collections-mantle-nav__link"} onClick={(e)=>{ e.preventDefault(),navigation[4].updateTab ? navigation[4].updateTab(navigation[4].linkTo) : '' }}>{navigation[4].label}</Link>
                      </li>
                                          ]

                  ] : ['']}



              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

NavigationMantle.propTypes = {
  active: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  navigation: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default NavigationMantle
