import React from 'react'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars

class BlogDetailContent extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault()
    }

    render() {
        return (
            <section className="blog-detail-content">
                <div className="wrap wrap--blog-detail-content">
                    <div className="">
                        <div className="blog-detail-content-columns">
                            <div className="blog-detail-content-column blog-detail-content-column--main">
                                <article className="blog-detail-content__article">
                                    {this.props.post.category.length > 0 ? (

                                        <p className="title title--blog-detail-content">{(this.props.post.category[0].name) ? this.props.post.category[0].name : ''}</p>

                                    ) : ('')}

                                    <h2 className="blog-detail-content__title">{this.props.post.post_title}</h2>

                                    <div className="blog-detail-text-editor"
                                         dangerouslySetInnerHTML={{__html: this.props.post.post_content.replace(/(?:\r\n|\r|\n)/g, '')}}>

                                    </div>
                                </article>
                            </div>

                            <div className="blog-detail-content-column blog-detail-content-column--sidebar">
                                <h2 className="title title--blog-detail-content">Categories</h2>
                                {this.props.categories.length > 0 ? (
                                    <ul className="blog-detail-nav__list">
                                        {this.props.categories.map((cat) => (

                                            <li className="blog-detail-nav__item">
                                                <Link to={"/category/" + cat.slug}
                                                      className="blog-detail-nav__link">{cat.name}</Link>
                                            </li>

                                        ))}

                                    </ul>

                                ) : ('')}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogDetailContent
