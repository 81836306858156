import React from 'react'
import $ from 'jquery'

class ContactForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

            formReload: false
        }

    }

    loadMarketoForm() {

        var that = this;
        if (MktoForms2 != "undefined") {

            MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 5236, function (form) {

                form.onSuccess(function () {

                    dataLayer.push({'event':'contactUs'});

                    $("#mktoForm_5236").html("");

                    that.setState({formReload: true});

                    return false;

                });


            });

            MktoForms2.whenReady(function () {
                var j = 1;
                $('#mktoForm_5236 .mktoFormRow').each(function (i, e) {
                    $(this).addClass('row-class-' + j);
                    j++;
                });

                //$("#mktoForm_5236").find('[name="Communities_of_Interest__c"]').val("a00f100000l7YE1");
                $("#mktoForm_5236").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_5236").find('[name="Division__c"]').val("Tri Pointe Homes San Diego");
            });
        }

    }

    componentDidMount() {

        this.loadMarketoForm();

    }

    componentWillReceiveProps() {

        if (this.state.formReload === true) {

            this.loadMarketoForm();
            this.setState({formReload: false});
        }

    }

    render() {
        return (
            <section className="contact-form">
                <div className="wrap wrap--contact-form">
                        <div className="contact-form-main-columns">
                            <div className="contact-form-main-column contact-form-main-column--image">
                                <div className="contact-form-image">
                                    <img
                                        src={(this.props.page.page_meta.marketo_form_image.ID) ? (this.props.page.page_meta.marketo_form_image.url) : require('media/contact-form.jpg')}
                                        className="contact-form__image" alt="Contact"/>
                                </div>
                            </div>

                            <div className="contact-form-main-column contact-form-main-column--form">
                                <h2 className="title title--contact-form">Have Questions</h2>
                 {this.props.page.page_meta.form_heading && <p className="contact-form__subtitle">{this.props.page.page_meta.form_heading}</p> }
                                <p className="contact-form__message">

        {this.props.page.page_meta.contact_form_email && <p><strong>EMAIL:</strong> {this.props.page.page_meta.contact_form_email}</p> }
        {this.props.page.page_meta.contact_form_phone && <p><strong>PHONE:</strong> {this.props.page.page_meta.contact_form_phone}</p>}<br/> 

                                    Fill out the form below and we’ll get back to you soon.

                                </p>

                                <div className="marketo_form">
                                    <form id="mktoForm_5236" className="contactpage-form"></form>
                                    {this.state.formReload === false ? (
                                        <small className="contact-form__disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.</small>
                                    ) : ('')}
                                </div>

                                {this.state.formReload === true ? (

                                    <small className="thankyou_message">
                                        Thanks for signing up to hear all the latest on Citro as it happens. Together we’re creating a new kind of community, so stay tuned!
                                    </small>
                                ) : ('')}


                            </div>
                        </div>
                </div>
            </section>
        )
    }
}

export default ContactForm
