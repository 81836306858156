import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import {Element} from 'react-scroll' // eslint-disable-line no-unused-vars

import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class CollectionsVideo extends React.Component {
    render() {

        const videoUrl = this.props.videoUrl
        const videoImage = this.props.videoImage

        return (
            <section className="collections-video">
                <Element id="media">
                    <div className="collections-video-holder">
                        <img src={videoImage} className="collections-video__image" alt="Video"/>
                        {

                            videoUrl != "" && videoUrl != null ? [

                                videoUrl.includes('vidyard') ? [

                                    <a href={videoUrl} data-type="iframe" data-fancybox data-iframe='{"preload":false}'
                                       data-fancybox-group="collections-video" className="collections-video__link">
                                        <i className="icon icon--play">
                                            <span className="hide--visually">Play Video</span>
                                        </i>
                                    </a>

                                ] : [

                                    <a href={videoUrl} data-fancybox data-fancybox-group="collections-video"
                                       className="collections-video__link">
                                        <i className="icon icon--play">
                                            <span className="hide--visually">Play Video</span>
                                        </i>
                                    </a>
                                ]


                            ] : ['']}

                    </div>
                </Element>
            </section>
        )
    }
}

export default CollectionsVideo
