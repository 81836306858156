/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import LifestyleDetailGallery from 'components/Classes/LifestyleDetailGallery.jsx'
import LifestyleDetailTopGallery from 'components/Classes/LifestyleDetailTopGallery.jsx'
import LifestyleDetailHeader from 'components/Classes/LifestyleDetailHeader.jsx'
import LifestyleGoBack from 'components/Classes/LifestyleGoBack.jsx'
import LifestylePosts from 'components/Classes/LifestylePosts.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class LifestyleDetail extends React.Component {

    constructor(props) {
        super(props)

        console.log("Life style details props ");
        console.log(props);
        this.state = {
            post: {},
            postDetailsMeta: [],
            moreLifeStyles: [],
            postTitle:'',
            success: false,
            loaded: false
        }
    }


    fetchLifeStyleDetailsResults() {

        fetch(apiConfig().API_URL + "lifestyle_detail/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        post: result.data.post,
                        postTitle:result.data.post.postTitle,
                        postDetailsMeta: result.data.post_details_meta,
                        moreLifeStyles: result.data.more_lifestyles,
                        awards: result.data.awards,
                        success: result.success,
                        loaded: true
                    });

                    console.log("LifeStyle Detail APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

    }

    componentDidMount() {


        this.fetchLifeStyleDetailsResults();
        CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loaded: false});

        this.props = nextProps;

        this.fetchLifeStyleDetailsResults();

    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    <Helmet>
                        <title>{this.state.postTitle}</title>
                        <body className="lifestyle-detail"/>
                    </Helmet>

                    {
                        this.state.success ? [
                            
                            // <Overlaychat />,

                            <h2 className="hide--visually">{this.state.post.post_title}</h2>,

                            <LifestyleGoBack/>,

                            this.state.postDetailsMeta.lifestyle_detail_top_video ? [

                                <LifestyleDetailTopGallery postDetailsMeta={this.state.postDetailsMeta}/>
   
                            ]: [''],

                            <LifestyleDetailHeader post={this.state.post}
                                                   postDetailsMeta={this.state.postDetailsMeta}/>,


                            <LifestyleDetailGallery postDetailsMeta={this.state.postDetailsMeta} awards={this.state.awards}/>,

                            this.state.moreLifeStyles.length > 0 ? [
                                <LifestylePosts lifestyles={this.state.moreLifeStyles} more={true}
                                                className="lifestyle-detail"
                                                title="You May Also Like"
                                />

                            ] : ['']



                        ] : ('')
                    }

            </div>
        )
    }
}

export default LifestyleDetail
