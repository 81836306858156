import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import fancybox from '@fancyapps/fancybox'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class VideoSlider extends React.Component {
  render () {
    const settings = {
      arrows: true,
      dots: false,
      draggable: false,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
    const videos = this.props.videos;
    const border = this.props.border == true ? "video_border" : ""
    return (
            <div className={"video_slider locations-slider collections-video "+border}>
                <Slider {...settings}>
                    { videos.length > 1 ? [
                        videos.map((gal)=>(
                            <div className="collections-video-holder">
                                <img src={gal.cover_image.url} className="collections-video__image" alt={gal.cover_image.alt}/>
                                { ( gal.vidyard != "" && gal.vidyard != null ) || ( gal.youtube_url != "" && gal.youtube_url != null ) ? [
                                        gal.vidyard != "" ? [
        
                                            <a href={gal.vidyard} data-type="iframe" data-fancybox data-iframe='{"preload":false}'
                                            data-fancybox-group="collections-video" className="collections-video__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
        
                                        ] : [
        
                                            <a href={gal.youtube_url} data-fancybox data-fancybox-group="collections-video"
                                            className="collections-video__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
                                        ] 
                                    ] : ['']}
                            </div>
                        ))
                    ] : [
                        <div className="collections-video-holder">
                            <img src={videos[0].cover_image.url} className="collections-video__image" alt={videos[0].cover_image.alt}/>
                            { ( videos[0].vidyard != "" && videos[0].vidyard != null ) || ( videos[0].youtube_url != "" && videos[0].youtube_url != null ) ? [
                                    videos[0].vidyard != "" ? [
                                        <a href={videos[0].vidyard} data-type="iframe" data-fancybox data-iframe='{"preload":false}'
                                        data-fancybox-group="collections-video" className="collections-video__link">
                                            <i className="icon icon--play">
                                                <span className="hide--visually">Play Video</span>
                                            </i>
                                        </a>
                                    ] : [
                                        <a href={videos[0].youtube_url} data-fancybox data-fancybox-group="collections-video"
                                        className="collections-video__link">
                                            <i className="icon icon--play">
                                                <span className="hide--visually">Play Video</span>
                                            </i>
                                        </a>
                                    ]
                                ] : ['']}
                        </div>
                    ]}               
                </Slider>
            </div>
    )
  }
}

export default VideoSlider
