import React from 'react'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars

class Posts extends React.Component {

    constructor(props) {
        super(props)

    }

    render() {

        const brand_images = this.props.brand_images;

        return (
            <section className="posts">
           { this.props.msg_info && this.props.msg_info.length > 2 ? (
                <div className="posts-shim"></div>
            ):('')}
                <h2 className="hide--visually">Posts</h2>

                <div className="wrap--posts">
                    <div className="posts-columns wrap-inner">
                        <div className="posts-column posts-column--main">
                            <div className="posts-main-articles">
                {this.props.posts.length > 0 && this.props.posts[0].post_thumbnail_url &&
                                <article className="posts-main__article posts-main__article--featured" itemScope
                                         itemType="http://schema.org/BlogPosting">
                                    <Link to={"/blog/"+this.props.posts[0].post_name} className="posts-main__link">
                                        <figure className="posts-main__figure">
                                            <img src={this.props.posts[0].post_thumbnail_url}
                                                 className="posts-main__image" itemProp="image" alt=""/>
                                            <figcaption className="posts-overlay">
                        <span className="posts-overlay-inner">
                            {this.props.posts[0].category.length > 0 ? <small className="title title--posts"
                                                                              itemProp="description">{this.props.posts[0].category[0].name.toUpperCase()}</small> : ''}
                            <h3 className="posts__title" itemProp="headline">{this.props.posts[0].post_title}</h3>
                        </span>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </article>
                }
                                {this.props.posts.length > 1 && this.props.posts[1].tile_image ? <article className="posts-main__article" itemScope
                                                                        itemType="http://schema.org/BlogPosting">
                                    <Link to={"/blog/"+this.props.posts[1].post_name} className="posts-main__link">
                                        <figure className="posts-main__figure">
                                            <img src={(this.props.posts[1].tile_image) ? this.props.posts[1].tile_image.url: ''} className="posts-main__image image_box"
                                                 itemProp="image" alt=""/>
                                            <figcaption className="posts-overlay">
                        <span className="posts-overlay-inner">
                           {this.props.posts[1].category.length > 0 ? <small className="title title--posts" itemProp="description">{this.props.posts[1].category[0].name.toUpperCase()}</small> : ''}
                          <h3 className="posts__title" itemProp="headline">{this.props.posts[1].post_title}</h3>
                        </span>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </article> : ''}


                        

                               {brand_images.length > 0 && brand_images[0].image.url ? (

                                    <article className="posts-main__article" itemScope
                                    itemType="http://schema.org/BlogPosting">
                                    <a className="posts-main__link">
                                    <figure className="posts-main__figure">
                                    <img src={brand_images[0].image.url} className="posts-main__image image_box"
                                            itemProp="image" alt=""/>
                                    <figcaption className="posts-overlay"></figcaption>
                                    </figure>
                                    </a>
                                    </article>



                               ): ('')}

                               
                                <div className="posts-main__article post-article">
                                    {/* <article class="posts-sidebar__article posts-sidebar__article--header">
                                        		<Link to={"/events"} className="posts-sidebar__link join_events_box">
                                                    <span className="posts-overlay">
                                                        <span className="posts-overlay-inner">
                                                            <small className="title title--posts">Events</small>
                                                            <h3 className="posts__title">Join Us for Events at Citro</h3>
                                                        </span>
                                                    </span>
                                                </Link>
                                    </article> */}

                                  {brand_images.length > 2 && brand_images[2].image.url ? (

                                        <article className="posts-main__article post_image" itemScope
                                            itemType="http://schema.org/BlogPosting">
                                        <a className="posts-main__link">
                                            <figure className="posts-main__figure">
                                                <img src={brand_images[2].image.url} className="posts-main__image image_box"
                                                    itemProp="image" alt=""/>
                                                <figcaption className="posts-overlay"></figcaption>
                                            </figure>
                                        </a>
                                        </article>
                                      ): ('')}
                                </div>
                                

                              

                               {this.props.posts.length > 2 && this.props.posts[2].tile_image ? (

                                        <article className="posts-main__article" itemScope
                                        itemType="http://schema.org/BlogPosting">
                                        <Link to={"/blog/"+this.props.posts[2].post_name} className="posts-main__link">
                                        <figure className="posts-main__figure">
                                        <img src={(this.props.posts[2].tile_image) ? this.props.posts[2].tile_image.url: ''}  className="posts-main__image image_box"
                                        itemProp="image" alt=""/>
                                        <figcaption className="posts-overlay">
                                        <span className="posts-overlay-inner">
                                        {this.props.posts[2].category.length > 0 ? <small className="title title--posts" itemProp="description">{this.props.posts[2].category[0].name.toUpperCase()}</small> : ''}
                                        <h3 className="posts__title" itemProp="headline">{this.props.posts[2].post_title}</h3>
                                        </span>
                                        </figcaption>
                                        </figure>
                                        </Link>
                                        </article>


                               ) : ("")}

                         

                                <header className="posts-main__article posts-main__article--header">
                                    <ScrollLink className="posts-main__link" to="newsletter"
                                                duration={750}
                                                offset={-60}
                                                smooth={true}
                                                spy={false}
                                    >
                    <span className="posts-overlay">
                      <span className="posts-overlay-inner">
                        <small className="title title--posts">Stay updated</small>
                        <h3 className="posts__title">Join the interest list</h3>
                      </span>
                    </span>
                                    </ScrollLink>
                                </header>
                            </div>
                        </div>

                        <div className="posts-column posts-column--sidebar">
                            <div className="posts-sidebar-articles">
                                <header className="posts-sidebar__article posts-sidebar__article--header">
                                    <ScrollLink className="posts-sidebar__link join_interest_box" to="newsletter"
                                                duration={750}
                                                offset={-60}
                                                smooth={true}
                                                spy={false}
                                    >
                    <span className="posts-overlay">
                      <span className="posts-overlay-inner">
                        <small className="title title--posts">Stay updated</small>
                        <h3 className="posts__title">Join the interest list</h3>
                      </span>
                    </span>
                                    </ScrollLink>
                                </header>
                                {brand_images.length > 1 ? (
                                <article className="posts-sidebar__article" itemScope
                                         itemType="http://schema.org/BlogPosting">
                                    <a className="posts-sidebar__link">
                                        <figure className="posts-sidebar__figure">
                                            <img src={brand_images[1].image.url} className="posts-sidebar__image"
                                                 itemProp="image" alt=""/>
                                            <figcaption className="posts-overlay"></figcaption>
                                        </figure>
                                    </a>
                                </article>

                                ):('') }

                                {this.props.posts.length > 3 ? <article className="posts-sidebar__article" itemScope
                                                                         itemType="http://schema.org/BlogPosting">
                                        <Link to={"/blog/"+this.props.posts[3].post_name} className="posts-sidebar__link">
                                            <figure className="posts-sidebar__figure">
                                                <img src={(this.props.posts[3].tile_image) ? this.props.posts[3].tile_image.url: ''} className="posts-sidebar__image"
                                                     itemProp="image" alt=""/>
                                                <figcaption className="posts-overlay">
                        <span className="posts-overlay-inner">
                            {this.props.posts[3].category.length > 0 ? <small className="title title--posts" itemProp="description">{this.props.posts[3].category[0].name.toUpperCase()}</small> : ''}
                          <h3 className="posts__title" itemProp="headline">{this.props.posts[3].post_title}</h3>
                        </span>
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    </article> : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Posts
