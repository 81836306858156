import React from 'react'
import $ from 'jquery'

class FaqMain extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        let $el = $(e.currentTarget)
        let $menu = $el.next('[data-faq-answer]')

        $el.toggleClass('active')
        $menu.slideToggle()
    }

    render() {
        return (
            <section className="faq-main">
                <div className="wrap wrap--faq-main">
                    <div className="faq-main-holder">
                        <i className="faq-main__icon"></i>

                        {
                            this.props.page.page_meta.accordion.length > 0 ? [

                                this.props.page.page_meta.accordion.map((a) => [

                                    <h3 className="faq-main__question" onClick={this.handleClick}>{a.heading}</h3>,
                                        <div data-faq-answer className="faq-main__answer">
                                           <p dangerouslySetInnerHTML={{__html: a.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                                        </div>
                                ])


                            ] : ('')
                        }


                        <hr className="faq-main-base"/>
                    </div>
                </div>
            </section>
        )
    }
}

export default FaqMain
