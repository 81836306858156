// Fix _blank target vulnerability
export function fixTargets () {
  let elements = document.querySelectorAll('a[target=_blank]')
  var rel

  for (let element of elements) {
    if (element.hasAttribute('rel')) {
      rel = element.getAttribute('rel')
      // Remove noopener noreferrer and any whitespace
      rel = rel.replace(/noopener/g, '').replace(/noreferrer/g, '').replace(/^\s+|\s+$/gm, '')
    }
    element.setAttribute('rel', 'noopener noreferrer' + (rel ? ' ' + rel : ''))
  }
}

// Breakpoints
export function getBreakpoint () {
  var breakpoint
  if (window.getComputedStyle) {
    breakpoint = window.getComputedStyle(
      document.querySelector('body'), ':before'
    ).getPropertyValue('content')

    breakpoint = parseInt(breakpoint.replace("'", '').replace('"', ''))
  } else {
    breakpoint = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
  return breakpoint
}

// Helpers for debugging
export function htmlData () {
  let doc = document.documentElement
  doc.setAttribute('data-useragent', navigator.userAgent)
  doc.setAttribute('data-platform', navigator.platform)
}

// Toggle class
export function toggleClass (className, transitionName, transitionDuration) {
  let element = document.querySelector('html')
  element.classList.toggle(className)

  if (transitionName && transitionDuration) {
    element.classList.add(transitionName)
    setTimeout(() => { element.classList.remove(transitionName) }, transitionDuration)
  }
}

export function apiConfig(){


   return {
        API_URL : REACT_CONFIG.API_URL,
        API_NEIGHBORHOODS : '49632,49631,49601,49627,49629,49633'    
    }

}

export function htmlEntityDecode(str){

    return str.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
    });
}

export function toVisualHtml(str){

   return {__html: str.replace(/(?:\r\n|\r|\n)/g, '<br />')}

}

