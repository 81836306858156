/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import QmiDetailSlider from 'components/Classes/QmiDetailSlider.jsx'
import CollectionsGoBack from 'components/Classes/CollectionsGoBack.jsx'
import CollectionsPosts from 'components/Classes/CollectionsPosts.jsx'
import CollectionsVideo from 'components/Classes/CollectionsVideo.jsx'
import CollectionsGallery from 'components/Classes/CollectionsGallery.jsx'
import FeaturedPlans from 'components/Classes/FeaturedPlans.jsx'
import Features from 'components/Classes/Features.jsx'
import Info from 'components/Classes/Info.jsx'
import NavigationMantle from 'components/Classes/NavigationMantle.jsx'
import SidebarSignUp from 'components/Classes/SidebarSignUp.jsx'
import {apiConfig,toVisualHtml} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import SingleCollectionQuick from 'components/Classes/SingleCollectionQuick'
import StickyToolbar from 'components/Classes/Sticky_toolbar.jsx'
import InteractiveFloorPlans from 'components/Classes/InteractiveFloorPlans.jsx'
import Breadcrumb from 'components/Classes/breadcrumb'
import Overlaychat from 'components/Classes/Overlaychat.jsx';
import VideoSlider from 'components/Classes/VideoSlider.jsx';
import InteractiveMediaTabs from 'components/Classes/InteractiveMediaTabs.jsx'
import InteractiveMediaIcons from 'components/Classes/InteractiveMediaIcons.jsx'

import $ from 'jquery'
class QmiDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            name: null,
            loaded: false,
            success: false,
            qmi: {},
            post_title:"",
        }

        this.activeQuickMoveIns = this.activeQuickMoveIns.bind(this)
        this.reloadTab = this.reloadTab.bind(this)
    }

    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "qmiBySlug/" + this.props.match.params.qmi)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        qmi: result.data,
                        loaded: true,
                        success: true
                    });



                },

                (error) => {

                }
            )

    }

    activeQuickMoveIns(){

        return false;
    }

    reloadTab(){

        return false;
    }


    componentDidMount() {

        dataLayer.push({
            'event': 'communityId',
            'e1CommunityId': this.state.qmi.community_id ? this.state.qmi.community_id : "",
            'salesforceId': this.state.qmi.communitySalesforceId ? this.state.qmi.communitySalesforceId : ""
        });

        this.fetchResult();
        CallTrk.swap();
        
    }

    componentDidUpdate(){

        
        if(this.state.success == true){
            dataLayer.push({
                'event': 'communityId',
                'e1CommunityId': this.state.qmi.community_id ? this.state.qmi.community_id : "",
                'salesforceId': this.state.qmi.communitySalesforceId ? this.state.qmi.communitySalesforceId : ""
            });
           }

        CallTrk.swap();

    }


    render() {
        const id = this.state.id ? this.state.id : 1
        const name = this.state.name ? this.state.name : 'Collections Detail'
        const slug = this.props.match.params.id
        const titleName = this.state.name ? `${this.state.name} | ` : ''
        const qmi = this.state.qmi;

        var window_width = screen.width;
        return (
            <div className="qmi-detail">
                {this.state.loaded == false ? <AltisLoader/> : ''}
               

                {
                    this.state.success === true ? [

                        <Helmet>
                            <title>{qmi.post_title}</title>
                            <body className="collections-detail"/>
                         </Helmet>,

                        <h2 className="hide--visually">{qmi.post_title}</h2>,

                        <NavigationMantle
                            active="left"
                            image={require('images/orange.jpg')}
                            message={<h2 dangerouslySetInnerHTML={toVisualHtml(  this.state.qmi.neighbourhood.post_title + " " + this.state.qmi.post_title)}></h2>}
                            navigation={
                                [
                                    {url: "#", label: 'Featured Home','reloadTab':this.reloadTab,show:false},
                                    {url: "/collection/", label: 'Featured Homes','activeQuickMoveIns':this.activeQuickMoveIns,show:false}
                                ]
                            }
                            title=""
                            customClass={"collection-detail-mantle"}
                        />,

                        <CollectionsGoBack/>,
                        <Breadcrumb
                        items={
                            [
                                {
                                    to:"/collections/?tab=feature_homes",
                                    title:"Quick Move-in"
                                },{
                                    to:this.props.location.pathname,
                                    title:qmi.post_title
                                }

                            ]
                        }
                      />,

                        <div className="sidebar-columns Qmi-detail-section">
                            <div className="wrap wrap--sidebar-columns">
                                <div className="sidebar-column sidebar-column--content">

                                    <QmiDetailSlider
                                        id={id}
                                        name={qmi.post_title}
                                        gallery={qmi.gallery_images}
                                        qmi_details={qmi}
                                        post_thumbnail={qmi.post_thumbnail}
                                        planInfo={qmi.planInfo}
                                        qmi_headline={qmi.qmi_marketing_headline}
                                        qmi_address={qmi.qmi_address}
										pricing_prefixes={qmi.pricing_prefixes}
										qmi_price={qmi.qmi_price}
                                />
                                <Features
                                    message1={<p
                                        dangerouslySetInnerHTML={{__html: qmi.description.replace(/(?:\r\n|\r|\n)/g, '')}}></p>}
                                    message2={qmi.Qmi_features}
                                    title2="Features"
                                    brochures=""
                                />
                                {qmi.interactiveFloorPlans != '' || qmi.Envision != '' ? [

                                <InteractiveMediaIcons interactive_media={qmi.interactiveFloorPlans} Envision={qmi.Envision} forqmi={true}  Show_Envision={qmi.Show_Envision}/>    

                                ]:['']}
                                {qmi.brochure && qmi.brochure[0].pdf != "" ?
                                (
                                   <section className="qmi_floor_btns">
                                         <ul>
                                             { qmi.brochure[0].pdf != "" ? (
                                                 <li><a className="header__join join_btn_new" href={qmi.brochure[0].pdf} target="_blank">View Brochure</a></li>
                                             ):('')
                                             }
                                             
                                        </ul>                           
                                   </section> 
                                )
                                :('')

                                }
                      </div>
                      {(window_width > 767 ) ? (
                                <aside className="sidebar-column sidebar-column--sidebar">
                                    
                                <div className="wrap--sidebar-column">
                                    <div className="sidebar-column-inner">
                                        <SidebarSignUp communityOfInterest={qmi.communitySalesforceId} referenceId={qmi.community_id} new_home_specialist={qmi.new_home_specialist} agent_dre={qmi.agent_dre} agent_name={qmi.agent_name} community_name={qmi.neighbourhood.post_title}  neigh_detail= {qmi.neighbourhood}/>
                                    </div>
                                </div>
                        </aside>) : (
                                    // <div className="chat_overlay"><Overlaychat cmId={qmi.community_id} /></div>   
                                    ''         
                        )}
                        </div>
                        {/* 5959 */}
                    <div className="wrap">
                    {qmi.gallery_images != "" ||  qmi.gallery_videos != "" ? (

                    <CollectionsGallery gallery_videos={qmi.gallery_videos} gallery_images={qmi.gallery_images}/>

                    ) : ('')}
                   
                        </div>
                        {/* { qmi.interactiveFloorPlans ? [

                        <InteractiveFloorPlans interactiveFloorPlans = {qmi.interactiveFloorPlans} forQMI={true}/>

                        ]: ['']} */}
                        { qmi.interactive_tab_details && qmi.interactive_tab_content_details ? [

                        <InteractiveMediaTabs interactive_tab_details={qmi.interactive_tab_details} interactive_tab_content_details={qmi.interactive_tab_content_details} />

                        ]: ['']}
                    </div>
                    
                    ] : ['']
                }


            </div>
        )
    }
}

export default QmiDetail
