import React from 'react'
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars

import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class LifestyleDetailTopGallery extends React.Component {
    render() {
        return (
            <section className="lifestyle-detail-gallery lifestyle-detail-gallery-top">
                <div className="wrap wrap--lifestyle-detail-gallery">
                    <div className="wrap-inner">


                                <div className="lifestyle-detail-gallery-video">

                                    {this.props.postDetailsMeta.lifestyle_detail_top_video[0].url_type == 1 && this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.url  ? [
                                        this.props.postDetailsMeta.lifestyle_detail_top_video[0].non_video_url ? (
                                        <a href = {this.props.postDetailsMeta.lifestyle_detail_top_video[0].non_video_url} target="_blank" ><img src ={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.url} className="lifestyle-detail-gallery__image"/></a>
                                        ):(<img src ={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.url} className="lifestyle-detail-gallery__image"/>)
                                    ] : [
                                        
                                            this.props.postDetailsMeta.lifestyle_detail_top_video[0].url_type == 2  && this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.url ? (
                                            <img src={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.url} alt={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image.alt}
                                            className="lifestyle-detail-gallery__image"/>
                                        ) :(''),
                                    this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_url && this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_image? (


                                        this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_url.includes('vidyard') ? [

                                            <a href={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_url} data-fancybox data-type="iframe" data-iframe='{"preload":false}'
                                               className="lifestyle-detail-gallery__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
                                        ] : [

                                            <a href={this.props.postDetailsMeta.lifestyle_detail_top_video[0].video_url} data-fancybox
                                               className="lifestyle-detail-gallery__link">
                                                <i className="icon icon--play">
                                                    <span className="hide--visually">Play Video</span>
                                                </i>
                                            </a>
                                        ]



                                    ) : ('')
                                    ]
                                    }
                                </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default LifestyleDetailTopGallery
