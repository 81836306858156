import React from 'react'
import { Element } from 'react-scroll' // eslint-disable-line no-unused-vars
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs' // eslint-disable-line no-unused-vars
import {
    isMobile,
    isTablet,
    deviceDetect
  } from "react-device-detect";
class InteractiveMediaTabs extends React.Component {
  constructor (props) {
    super(props)
    this.state = { tabIndex: 0 }
  }

  render () {

    const tab_content = this.props.interactive_tab_content_details;
    const tab_heading = this.props.interactive_tab_details;
    var window_width = screen.width;
    var iframe__url;
    var iframe_link;
 
    return (
      <section className="interactive_media_buttons">


          {tab_heading.length > 0 && tab_content.length > 0 ? [

            <div className="wrap">
                <Element id="interactive_tabs" className="inner_wrap">

                  <h2 class="featured-plans-header__title1 tab_heading">
                      Interactive Floor Plan
                    </h2>

                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>                     
                        {
                        tab_heading.length > 0 ? [
                            <TabList>
                                {tab_heading.map((fp,index)=>(
                                   
                                    <Tab>
                                        <button type="button" className="interactive_tab_button"><img src={fp.tab_image} alt={fp.tab_title} title={fp.tab_title}/><span>{fp.tab_title}</span></button>
                                    </Tab>
                                ))}
                            </TabList>
                        ] : [''] 
                        }
                        
                        <div className="react-tabs-holder">
                            {tab_content.map((fp,index)=>(
                            iframe__url = iframe_link = '',
                            fp.iframe_url && fp.iframe_url != '' ? [
                              iframe_link = fp.iframe_url, 
                              iframe_link.includes("&amp;") ? [
                                iframe__url = iframe_link.replace("&amp;", "&")
                              ]:[
                                iframe__url = iframe_link
                              ],
                            ]:[''], 
                            <TabPanel>
                                {fp.iframe_url && fp.iframe_url != '' ? [
                                <iframe className="interactive_media_iframe" src={iframe__url +(isMobile ? "?mode=mobile" : "")} width="100%" height={fp.custom_height && window_width >= 1280 ? fp.custom_height+'px' : '688px' }/>
                                ]:[
                                    fp.image_url != '' ? (
                                      <img src={fp.image_url.image.url} className="interactive_media_image" alt="Floor Plan" title="Floor Plan" />  
                                    ):('')                                                                                  
                                ]
                            }
                            </TabPanel>
                            ))}
                        </div>
                    </Tabs>
                </Element>
            </div>

          ] : ['']}

      </section>
    )
  }
}

export default InteractiveMediaTabs
