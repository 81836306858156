import React from 'react'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import {toVisualHtml} from 'components/Classes/Helpers.jsx'

class LifestyleAbout extends React.Component {
  render () {
    const content_position = this.props.content_position;
    return (
      <section className="lifestyle-about">
        <div className="lifestyle-about-holder">
          <div className="wrap wrap--lifestyle-about">
            <ul className="lifestyle-about-columns">
              <li className={content_position== "left" ? "lifestyle-about-column lifestyle-about-column--image" : "lifestyle-about-column lifestyle-about-column--image lifestyle-about-column--image-right"}>
                <div className="lifestyle-about-shim"></div>
                <img src={this.props.about.post_thumbnail_url ? this.props.about.post_thumbnail_url : require('media/default-160x307.png') } className="lifestyle-about__image" alt="The Spot" />
              </li>

              <li className={content_position == "left" ? "lifestyle-about-column lifestyle-about-column--content" : "lifestyle-about-column lifestyle-about-column--content lifestyle-about-column--content-right"}>
                <div className="lifestyle-about-info">
                  <h2 className="lifestyle-about__title">{this.props.about.post_title}</h2>
                  <p className="lifestyle-about__message" dangerouslySetInnerHTML={{__html: this.props.about.post_content.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
                  { this.props.about.lifestyle_link==true ? (
                  <Link to={"/lifestyle/"+this.props.about.post_name} className="title title--lifestyle-about">Learn More</Link>
                  ):('')}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default LifestyleAbout
