import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import BasicMantle from 'components/Classes/BasicMantle.jsx' // eslint-disable-line no-unused-vars
import BlogArchives from 'components/Classes/BlogArchives.jsx' // eslint-disable-line no-unused-vars
import BlogDetailContent from 'components/Classes/BlogDetailContent.jsx' // eslint-disable-line no-unused-vars
import BlogPosts from 'components/Classes/BlogPosts.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class BlogDetail extends React.Component {

    constructor(props) {
        super(props)
        console.log("Props for Blog Detail");
        console.log(props);

        this.state = {
            success: false,
            post: {},
            categories: [],
            relatedPosts: [],
            archives: [],
            message: "",
            loaded: false
        }
    }

    componentWillReceiveProps(nextProps) {

        console.log("updated props");
        console.log(nextProps);

        this.props = nextProps;

        this.fetchResult();

    }

    fetchResult() {
        this.setState({loaded: false});

        fetch(apiConfig().API_URL + "blog/" + this.props.match.params.id)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        post: result.data.post,
                        categories: result.data.categories,
                        relatedPosts: result.data.related_post,
						elementor_data: result.data.post_content,
						elementor_page_title: result.data.elementor_page_title,
                        archives: result.data.archives,
                        success: result.success,
                        message: result.message,
                        loaded: true
                    });


                },

                (error) => {

                }
            )

    }


    componentDidMount() {

        this.fetchResult();
        CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    {this.state.success ? [
                        <Helmet>
							{this.state.elementor_data != '' ? [
								<title>{this.state.elementor_page_title}</title>,
								<body className="blog-detail elementor-detail"/>
							] : [
								<title>{this.state.post.post_title}</title>,
								<body className="blog-detail"/>
							]}
                        </Helmet>,

                        // <Overlaychat/>,
						this.state.elementor_data != '' ? [
                            window.location.href.indexOf('elementor-preview') < 0 ? (
                                <div dangerouslySetInnerHTML={{__html: this.state.elementor_data.replace(/(?:\r\n|\r|\n)/g, '')}}></div>
                            ) : ('')
                        ] : [
                        
                        <h2 className="hide--visually">{this.state.post.post_title}</h2>,


                        (this.state.post.post_image) ? [

                            <BasicMantle
                                image={this.state.post.post_image}
                            />

                        ] : (''),


                        <BlogArchives archives={this.state.archives}
                                      back={true}
                        />,

                        <BlogDetailContent post={this.state.post} categories={this.state.categories}/>,

                        (this.state.post.post_type == 'post' ? (

                            <BlogPosts relatedPosts={this.state.relatedPosts} related={true}
                            />

                        ) : (''))

					]
                    ] : (this.state.message)}


            </div>
        )
    }
}

export default BlogDetail
