import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars

class LifestylePosts extends React.Component {
  render () {
    const className = this.props.className ? `lifestyle-posts lifestyle-posts--${this.props.className}` : 'lifestyle-posts'
    const title = this.props.title
    console.log('lifestyle_link',this.props.lifestyle_link);
    return (
      <section className={className}>
        <div className="wrap wrap--lifestyle-posts">
          {title &&
            <h2 className="title title--lifestyle-posts-header">{title}</h2>
          }

          <div className="lifestyle-posts-holder">

              {

                this.props.lifestyles ? (

                    this.props.lifestyles.map((lifestyle,index)=>(

                        (index != 0 || this.props.more == true ) ?
                            <figure className={(index%2 == 0 ? "lifestyle-posts-row lifestyle-posts-row--color-"+index+ " lifestyle-posts-row--alt" : "lifestyle-posts-row lifestyle-posts-row--color-"+index)} >
                                <ul className="lifestyle-posts__list">
                                    <li className="lifestyle-posts__item lifestyle-posts__item--image">
                                    {lifestyle.lifestyle_link==true ? (
                                        <Link to={"/lifestyle/"+lifestyle.post_name} className="lifestyle-posts__link">
                                            <div
                                                className="lifestyle-posts__image"
                                                style={{ backgroundImage: 'url(' + lifestyle.post_thumbnail_url + ')' }}
                                            ></div>
                                            <span className="lifestyle-posts-overlay"></span>
                                        </Link>
                                    ):(
                                        <div className="lifestyle-posts__link">
                                        <div
                                            className="lifestyle-posts__image"
                                            style={{ backgroundImage: 'url(' + lifestyle.post_thumbnail_url + ')' }}
                                        ></div>
                                        <span className="lifestyle-posts-overlay"></span>
                                    </div>
                                    )}</li>

                                    <li className="lifestyle-posts__item lifestyle-posts__item--content">
                                    {lifestyle.lifestyle_link==true ? (
                                        <Link to={"/lifestyle/"+lifestyle.post_name} className="lifestyle-posts__link">
                                            <figcaption className="lifestyle-posts-info">
                                                <h2 className="lifestyle-posts__title">{lifestyle.post_title}</h2>
                                                <div className="lifestyle-posts__message">
                                                    {lifestyle.tile_text ? [

                                                        <p dangerouslySetInnerHTML={{__html:lifestyle.tile_text.replace(/(?:\r\n|\r|\n)/g, '')}}></p>

                                                    ]: ['']}

                                                </div>
                                             
                                                <span className="title title--lifestyle-posts">Learn More</span>
                                                
                                            </figcaption>
                                        </Link>
                                    ):(
                                        <div className="lifestyle-posts__link">
                                        <figcaption className="lifestyle-posts-info">
                                            <h2 className="lifestyle-posts__title">{lifestyle.post_title}</h2>
                                            <div className="lifestyle-posts__message">
                                                {lifestyle.tile_text ? [

                                                    <p dangerouslySetInnerHTML={{__html:lifestyle.tile_text.replace(/(?:\r\n|\r|\n)/g, '')}}></p>

                                                ]: ['']}

                                            </div>        
                                        </figcaption>
                                    </div>
                                    )}
                                    </li>
                                </ul>
                            </figure> : ''


                    ))
                ) : ('')
              }


          </div>
        </div>
      </section>
    )
  }
}

LifestylePosts.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
}

export default LifestylePosts
