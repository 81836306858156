import React from 'react'
import PropTypes from 'prop-types'

class PlansDetailMantle extends React.Component {
  render () {
    const post_thumbnail = this.props.post_thumbnail

    const name = this.props.name
    const nbName = this.props.nbName

    return (
      <section className="plans-detail-mantle">
        <div className="plans-detail-mantle-shim"></div>
        <div className="wrap wrap--plans-detail-mantle">
          <div className="plans-detail-mantle-holder">
            {/* <img src={post_thumbnail} className="plans-detail-mantle__image" alt={name} /> */}
            {/* <span className="plans-detail-mantle-overlay">
              <h2 className="plans-detail-mantle__title">{nbName} {name}</h2>
            </span> */}
          </div>
        </div>
      </section>
    )
  }
}

PlansDetailMantle.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

export default PlansDetailMantle
