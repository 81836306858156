import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import NavigationMantle from 'components/Classes/NavigationMantle.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
import FeatureSection from 'components/Classes/FeatureSection.jsx';
import {toVisualHtml} from 'components/Classes/Helpers.jsx';
import $ from 'jquery';

class Realtors extends React.Component {

    constructor() {
        super()
        this.state = {
            success: false,
            realtors: [],
            loaded: false
        }
    }

    capitalizeFirstLetter(string) {
        if(string.indexOf(' ')>=0){
          var str = string.split(' ');
          for(var i = 0;i<str.length; i++){
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1).toLowerCase();
          }
          return str.join(' '); 
        }else{
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
        
    }
    submitForm()
    {				  
              var formData = $('#inputform').serialize();
              console.log(formData);
                $('.divLoading').show();
                //$("html, body").stop().animate({scrollTop:0}, '500', 'swing', function() {});

            var data = {
                action: 'postagentsalesforceform',
                data: formData
                };
                console.log(data);
        
                $.ajax({
                    type: 'POST',
                    data: data,
                    url: "https://citroliving.com/wp-admin/admin-ajax.php",
                    success: function(response) {
                        response=response.replace('"',"");
                        response=response.replace('"',"");
                        var res = response.match(/Agent Updated/gi);
                        var res2 = response.match(/Agent Created/gi);
                        if(res || res2)
                        {
                            $("#inputform").hide();
                            $(".errorDiv").html();
                            $('.divSuccess').show();
                        }
                        else
                        {
                            $('.divSuccess').hide();
                            $(".errorDiv").html(response.replace("1",""));
                        }
                        $('.divLoading').hide();
                    },
                    error:function(response){
                      $('.divLoading').hide();
                      $('.divSuccess').hide();
                      $(".errorDiv").html(response.replace("1",""));
                      
                    }
                });
                
    }
    checkemail() {
        var str = $("#email").val();
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (filter.test(str)) {
            return true;
        } else {
            
            return false;
        }

    }

    checkphone() {
        var inputtxt = $("#phone").val();
        var phoneno = /^\d{10}$/;
        if (inputtxt.match(phoneno)) {
            return true;
        } else {
             
            return false;
        }
    }
    componentDidMount() {

        fetch(apiConfig().API_URL + "get_realtors")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        realtors: result.data,
                        success: result.success,
                        loaded: true

                    });

                    console.log("Realtors APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

   
            CallTrk.swap();
                JQUERY("#inputform").parsley();  
    }



    componentDidUpdate(){

        JQUERY("#inputform").parsley();  

        var that = this;

        var fields_arr = ['firstname','lastName','Title'];

        $(fields_arr).each(function(index, element) {
            $('#' + element).blur(function(){ 
                            this.value = that.capitalizeFirstLetter(this.value);
            });
            });

        var check = function(event) {
                var phone = $(this).val();
                phone = phone.replace(/\D/g,"");
                if(phone.length>=9) {
                    phone = "("+ phone.slice(0,3)+") "+phone.slice(3,6)+"-"+phone.slice(6);
                }
                $(this).val(phone);
        };

        $('#Phone').on("blur", check);

       $("#inputform").submit(function(e) {
            if($('.parsley-errors-list ').hasClass('filled') === true){
                return false;  
            }
            else{
                e.preventDefault;
                that.submitForm();   
                return false; 
            }
       });

       $("#email").keypress(function () {
        if ($.trim($(this).val()).length==0) {
            $(".errorDiv").html("");
            $("#contact-error").html("");
            console.log("email key press")
        }

    });

    $("#phone").change(function () {
        if ($.trim($(this).val()).length==0) {
            $(".errorDiv").html("");
            $('#myfield').rules('remove', 'required');
            console.log("phone key press")
        }

    });

        CallTrk.swap();
    }

    render() {
        const realtors = this.state.realtors;
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    <Helmet>
                        <title>{this.state.postTitle}</title>
                        <body className="Realtors"/>
                    </Helmet>
{/* 
                    <Overlaychat /> */}

                    {this.state.success ? [

                        <h2 className="hide--visually">{this.state.post_title}</h2>,

                        <NavigationMantle
                            active="left"
                            image={realtors.page_meta.banner_image}
                            message={<p dangerouslySetInnerHTML={{__html: realtors.page_meta.banner_details[0].message.replace(/(?:\r\n|\r|\n)/g, ' ')}}></p>}
                            navigation={
                                [
                                    {url: '/lifestyles', label: 'Amenities'},
                                    {url: '/lifestyles/interests', label: 'Enthusiast Groups'}
                                ]
                            }
                            title={realtors.page_meta.banner_details[0].title}
                        />,
                        <FeatureSection about={realtors.page_meta.feature_section[0]} content_position={realtors.page_meta.feature_section[0].feature_message_position}/>,
                        <section className="rewards_section">
                            <div className="wrap wrap--lifestyle-about wrap--reward">
                                <div className="agent_form_section">
                                        <h2 className="agent_form_heading">Join Realtor Rewards</h2>
                                        { realtors.page_meta.form_message ? [
                                            <p className="agent_form_description" dangerouslySetInnerHTML={toVisualHtml(realtors.page_meta.form_message)}></p>
                                        ]:['']}
                                        <div className="divSuccess" style={{display:'none'}}><p>Thank you for registering.</p></div>
			                            <span className="divLoading login-status login-error" style={{display:'none'}}>loading...</span>
                                        <form id="inputform"  class="parsley-validate agent_form" method="post">                              
                                            <div class="errorDiv"></div>
                                            <input id="firstname" maxlength="80" name="firstname" required data-parsley-error-message="Please enter a first name" size="20" type="text" placeholder="FIRST NAME (REQUIRED)"/>
                                            <input id="lastName" maxlength="80" name="lastname"  required data-parsley-error-message="Please enter a last name" size="20" type="text" placeholder="LAST NAME (REQUIRED)" />
                                            <input id="email"  type="email"  maxlength="80" name="email" required data-parsley-error-message="Please enter a valid email" placeholder="EMAIL (REQUIRED)" />
                                            <input  id="Phone" maxlength="14" name="phone" type="text" required data-parsley-error-message="Please enter a valid phone no" placeholder="PHONE (REQUIRED)" />
                                            <input  id="licenseNo" maxlength="80" name="licenseno" type="text" required data-parsley-error-message="Please enter a valid License Number" placeholder="LICENCE (REQUIRED)" />      
                                            <input type='hidden' name="division" value=""/>   
                                            <input type='hidden' name="brand" value=""/>                        
                                            <button id="submitbutton" class="btn btn--medium-blue agent_submit" type="submit">Submit</button>
                                        </form> 
                                    <p className="agent_form_disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.</p> 
                                </div>
                                <div className="reward_details">
                                    <div className="reward_banner">
                                        <img src ={realtors.page_meta.rewards_section[0].reward_image.url} alt={realtors.page_meta.rewards_section[0].reward_image.alt}/>
                                    </div>
                                    <ul className="reward_points">
                                    {realtors.page_meta.rewards_section[0].reward_points.map((reward) => ( 
                                        <li><span>{reward.reward_point_heading}</span> - {reward.reward_point_message}</li>

                                    ))}
                                    </ul>
                                </div>
                            </div>    
                        </section> 
                    ] : ('')}

            </div>
        )
    }
}

export default Realtors
