import React from 'react'
import PropTypes from 'prop-types'
import {toVisualHtml} from 'components/Classes/Helpers.jsx'
class CategoryMantle extends React.Component {
  render () {
    //const image = this.props.image
    const message = this.props.message
    const title = this.props.title

    return (
      <section className="category-mantle">
        <div className="wrap wrap--basic-mantle">
          <div className="category-mantle-holder wrap--blog-posts">
               <h3 dangerouslySetInnerHTML={toVisualHtml(title)}></h3>
               <p dangerouslySetInnerHTML={{__html: message.replace(/(?:\r\n|\r|\n)/g, '')}}></p>
          </div>
        </div>
      </section>
    )
  }
}

CategoryMantle.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.object,
  title: PropTypes.string
}

export default CategoryMantle
