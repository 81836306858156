import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import BlogArchives from 'components/Classes/BlogArchives.jsx' // eslint-disable-line no-unused-vars
import BlogMantle from 'components/Classes/BlogMantle.jsx' // eslint-disable-line no-unused-vars
import BlogPosts from 'components/Classes/BlogPosts.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';

class Blog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

            categories: [],
            latestPost: {},
            recentPosts: [],
            archives: [],
            page_title: 'Blog',
            success: false,
            loaded: false
        };
    }

    componentDidMount() {

        fetch(apiConfig().API_URL + "blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        categories: result.data.categories,
                        page_title:result.data.page_title,
                        latestPost: result.data.posts[0],
                        recentPosts: result.data.posts,
                        archives: result.data.archives,
                        success: true,
                        loaded: true
                    });

                    console.log("Blog APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();

    }

    componentDidUpdate(){
        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}

                
                    <Helmet>
                        <title>{this.state.page_title}</title>
                        <body className="blog"/>
                    </Helmet>

                    {/* <Overlaychat/> */}

                    <h2 className="hide--visually">Blog</h2>

                    {this.state.categories.length > 0 ?
                        <BlogMantle latestPost={this.state.latestPost} categories={this.state.categories}/> : ''}
                    {this.state.success ? (

                        <BlogArchives archives={this.state.archives}/>

                    ) : ('')}

                    {this.state.recentPosts.length > 0 ? (

                        <BlogPosts recentPosts={this.state.recentPosts}/>

                    ) : ('')}

            </div>
        )
    }
}

export default Blog
