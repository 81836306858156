/* eslint-disable no-unused-vars */
import React from 'react'
import {Helmet} from 'react-helmet'
import Amenities from 'components/Classes/Amenities.jsx'
import FeaturedCollections from 'components/Classes/FeaturedCollections.jsx'
import Info from 'components/Classes/Info.jsx'
import Locator from 'components/Classes/Locator.jsx'
import MantleSlider from 'components/Classes/MantleSlider.jsx'
import Posts from 'components/Classes/Posts.jsx'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import BasicMantle from 'components/Classes/BasicMantle.jsx' // eslint-disable-line no-unused-vars
import $ from 'jquery';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Home extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            success: false,
            slider: [],
            info: [],
            amenities: [],
            locators: [],
            brand_images:[],
            show_amenities_nav_label:false,
            posts: [],
            neighbourhoods: [],
            loaded: false,
            page_title:'Citro',
            image_above_blogs: false,
            community_details:[],
            collection_bg:'',
        }

    }

    componentDidMount() {
    

        fetch(apiConfig().API_URL + "home_info?slug=home")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    this.setState({
                        page_title:result.data.page_title,
                        slider: result.data.slider,
                        info: result.data.info,
                        amenities: result.data.amenities,
                        locators: result.data.locators,
                        brand_images:result.data.brand_images,
                        posts: result.data.posts,
                        neighbourhoods:result.data.neighbourhoods,
                        neighborhood_card_color:result.data.neighborhood_card_color,
                        image_above_blogs:result.data.image_above_blog,
                        show_amenities_nav_label:result.data.show_amenities_nav_label,
                        community_details : result.data.community_details[0],
                        collection_bg:result.data.collection_background_image,
                        success: result.success,
                        loaded: true
                    });

                },

                (error) => {

                }
            )

            CallTrk.swap();
    }

    componentDidUpdate(){

        if(this.state.success == true){
            dataLayer.push({
                'event': 'communityId',
                'e1CommunityId': this.state.community_details.community_id ? this.state.community_details.community_id  : "",
                'salesforceId': this.state.community_details.community_of_interest ? this.state.community_details.community_of_interest : ""
            });
           }

        CallTrk.swap();
    }


    render() {

        return (


            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                <Helmet>
                    <title>{this.state.page_title}</title>
                    <body className="home"/>
                </Helmet>

                <h2 className="hide--visually">Home</h2>


                {(this.state.success === true) ? [
                        // <Overlaychat />,
                        <MantleSlider slider={this.state.slider}/>,

                        this.state.info.length > 0 ? (
                        <Info className="home-message-1"
                              message={this.state.info[0].info_description}
                              title={this.state.info[0].info_title}
                        />
                        ):(''),

                        <Amenities amenities={this.state.amenities} show_amenities_nav_label={this.state.show_amenities_nav_label} />,

                        this.state.info.length > 1 ? (
                        <Info className="home-message-2"
                        message={this.state.info[1].info_description}
                        title={this.state.info[1].info_title}
                        />
                        ):(''),

                        // <Locator locators={this.state.locators}/>,

                        <FeaturedCollections neighbourhoods={this.state.neighbourhoods} neighborhood_card_color={this.state.neighborhood_card_color}collection_bg={this.state.collection_bg} />,

                        this.state.info.length > 2 ? (
                            <Info className="home-message-3"
                            message={this.state.info[2].info_description}
                            title={this.state.info[2].info_title}
                            image={this.state.image_above_blogs.ID ? this.state.image_above_blogs.url :false}
                        />
                        ):(''),

                        // this.state.posts.length > 0 ? ( 
                            <Posts posts={this.state.posts} brand_images={this.state.brand_images} msg_info = {this.state.info}/>
                        //):('')
                       
                ] : ('')}

            </div>
        )
    }
}

export default Home
