import React from 'react'
import {toVisualHtml} from 'components/Classes/Helpers.jsx'
import ContactMapContainer from 'components/Classes/ContactMapContainer.jsx' // eslint-disable-line no-unused-vars

class ContactDirections extends React.Component {

    constructor(){
        super()
        this.handleNavClick = this.handleNavClick.bind(this);
    }

    handleNavClick(nav){
        window.location.href=nav;
    }
  render () {
    return (
      <section className="contact-directions">
        {/* <div className="contact-directions-shim"></div> */}
        <div className="wrap wrap--contact-directions">
          <div className="contact-directions-columns">
            <div className="contact-directions-column contact-directions-column--info">
                {(this.props.page.page_meta.contact_details.length > 0  && this.props.page.page_meta.contact_details!= 0) ? (
                    <ul className="contact-directions__list">
                                {this.props.page.page_meta.contact_details[0].email && <li className="contact-directions__item">
                                    <p className="contact-directions__title">Email</p>
                                    <a href={"mailto:" + this.props.page.page_meta.contact_details[0].email} className="contact-directions__link">{this.props.page.page_meta.contact_details[0].email}</a>
                                </li>}
                                {this.props.page.page_meta.contact_details[0].phone_number && <li className="contact-directions__item">
                                    <p className="contact-directions__title">Phone</p>
                                    <a href={"tel:" + this.props.page.page_meta.contact_details[0].phone_number} className="contact-directions__link">{this.props.page.page_meta.contact_details[0].phone_number}</a>
                                </li>}
                    </ul>


                ) : ('')}

                {(this.props.page.page_meta.contact_details.length > 0  && this.props.page.page_meta.contact_details!= 0) ? (
                    <div className="contact-directions-info">
                        <h2 className="contact-directions__subtitle">Location &amp; Directions</h2>

                        <div className="contact-directions__message"
                            dangerouslySetInnerHTML={toVisualHtml(this.props.page.page_meta.contact_details[0].directions)}>
                        </div>

                        <button type="button" className="contact-directions__button" onClick={()=>{this.handleNavClick(this.props.page.page_meta.contact_details[0].direction_link)}}>Directions</button>
                    </div>

                ) : ('')}

            </div>

            <div className="contact-directions-column contact-directions-column--map">
              <ContactMapContainer />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContactDirections
