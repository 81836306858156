import React from 'react'
import {Element} from 'react-scroll' // eslint-disable-line no-unused-vars
import $ from 'jquery'
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisJoinForm from 'components/Classes/AltisJoinForm.jsx'
import StickyToolbar from 'components/Classes/Sticky_toolbar.jsx'
import ScheduleTour from 'components/Classes/ScheduleTour.jsx'
class Newsletter extends React.Component {

    constructor(props){
        super(props)
        this.state ={

            reload:false,
            data:false,
            agent_info: [],
            success:false
        }


    }


    loadMarketoForm() {
        var that = this;

        if (MktoForms2 != "undefined") {

            MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 5235, function (form) {

                $('#mktoForm_5235 .mktoCheckboxList > input').each(function(){
					$(this).next('label').addBack().wrapAll('<div class="mktoCustomBox"/>');
                });

                form.onSuccess(function () {

                    dataLayer.push({'event':'interestList'});
                    // var popupid ="";
                    // var popvalues ="";
                    $("#mktoForm_5235").html("");
                    $('.Campaign__content').find('.optin_pop_up').length > 0 ? [
                        $(".optin_pop_up .newsletter__disclaimer").hide(),
                        $(".optin_pop_up").append("<small class='thankyou_message'>  Thanks for signing up to hear all the latest on Atwell as it happens. Together we’re creating a new kind of community, so stay tuned! </small>"),
                        // popupid = jQuery( ".CampaignType--popup" ).attr('id'),
                        // popvalues=popupid.split('-'),
                        omnyqo8a7gs9b1netfxrob.Listeners.convert()
                    ]:[];
                    that.setState({reload:true});


                    return false;

                });



            });

            MktoForms2.whenReady(function () {
                var j=1;
                $('#mktoForm_5235 .mktoFormRow').each(function(i,e) {
                    $(this).addClass('row-class-'+j);
                    j++;
                });  
                
                $("#mktoForm_5235").find('[name="formURL"]').val(window.location.href);
                $("#mktoForm_5235").find('[name="Division__c"]').val("Tri Pointe Homes San Diego");
                $('.btn-right-text').remove();
                $('.optin_pop_up .mktoButtonRow').append('<span class="btn-right-text">Maybe later</span>');
            });
        }

    }

  

    fetchNewsletterInfo(){


        fetch(apiConfig().API_URL + "newsletter_info")
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({

                    data: result.data,
                    success: result.success,
                    agent_info: result.data.agent_info[0],
                    neighbourhoods: result.data.neighbourhoods,
                    loaded:true
                });

                console.log("NEwsletter APi Response");
                console.log(result);

            },

            (error) => {

            }
        )

        CallTrk.swap();



    }

    formClose = () =>{

        var that = this;

        var i = setInterval(optin_pop_up, 1000);
        function optin_pop_up() {
            $('.Campaign__content').find('.optin_pop_up').length > 0 ? [
                myStopFunction(),
                $(".newsletter-form form").html(""),
                 $("body").addClass("body_overflow1"),
                $('.newsletter-form form').removeAttr('id'),
                $('.optin_pop_up form').attr('id', 'mktoForm_5235'),
                that.loadMarketoForm(),
                that.setState({reload: false})
            ]:[''];
          }

        // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
            var modal = document.querySelector(".CampaignType--popup");
            if (event.target == modal) {
                $(".optin_pop_up form").html("");
                $('.optin_pop_up form').removeAttr('id');
                $("body").removeClass("body_overflow1");
                $('.newsletter-form form').attr('id', 'mktoForm_5235');
                that.loadMarketoForm();
                that.setState({reload: false});
        } } 

        function myStopFunction() {
        clearInterval(i);
        }
        $(document).on("click",".Atwell-close,.btn-right-text",function(){
            $("body").removeClass("body_overflow1");
            $(".optin_pop_up form").html("");
            $('.optin_pop_up form').removeAttr('id');
            $('html').removeClass('om-position-popup');
            $('.CampaignType--popup').css('display', 'none');
            $('.newsletter-form form').attr('id', 'mktoForm_5235');
            that.loadMarketoForm();
            that.setState({reload: false}); 
        });
        $('.join_btn_header,.join_interest').on('click',function(){

            $(".newsletter-form form").html("");

            $('.newsletter-form form').removeAttr('id');

            $('#form-box-div form').attr('id', 'mktoForm_5235');

            that.loadMarketoForm();

            that.setState({reload: false}); 
        });

        
        $("#form-box-div .signup_close").on('click',function(){

           // alert("Form Close");
     
            $("#form-box-div form").html("");

            $('#form-box-div form').removeAttr('id');

            $('.newsletter-form form').attr('id', 'mktoForm_5235');

            that.loadMarketoForm();

            that.setState({reload: false}); 
        }); 

    };


       

    componentDidMount() {

        var that = this;

        this.loadMarketoForm();

        this.fetchNewsletterInfo();

        setTimeout(function(){
            that.formClose();
            
        },2000);

        

           

    }

    componentWillReceiveProps() {

        var that = this;
        if(this.state.reload === true){

            this.loadMarketoForm();
            this.setState({reload:false});
        }

    }


    render() {

        const data = this.state.data
        var window_width = screen.width;
        var page_slug;
        console.log(data);
        return (
            <React.Fragment>
                {this.state.success ? [
                    //  (window_width > 767 ) ? [   
            <section className="newsletter pattern-width">
                <Element id="newsletter">
                    <div className="wrap wrap--newsletter">
                        <div className="newsletter-logo">
                            <div className="newsletter__logo"></div>
                        </div>

                        <div className="newsletter-holder">
                            <div className="newsletter-image">
                            
                                {data.form_image && <img src={data.form_image.url} className="newsletter__image"
                                alt="Newsletter"/> }
                            </div>

                            <div className="newsletter-form">
                                <div className="newsletter-form-holder">
                                        {/* <div className="newsletter-form-column newsletter-form-image">
                                            {data && data.interest_form_image ? [

                                                <div class="contact-form-image">
                                                    <img src={data.interest_form_image.url} class="contact-form__image" alt="Contact"/>
                                                </div>


                                            ] : [

                                                <div class="contact-form-image">
                                                    <img src="http://pardeehomes.tpgwebsites.wpengine.com/wp-content/uploads/sites/3/2019/03/cara_nhs_400x400.jpg" class="contact-form__image" alt="Contact"/>
                                                </div>

                                            ]}
                                          
                                        </div> */}
                                        <div className="newsletter-form-column newsletter-form-wrap">
                                            <h2 className="title title--newsletter">Join our mailing list</h2>
                                            <p className="newsletter__subtitle">Keep Up with Citro</p>
                                            <p className="newsletter__message newsletter__form_desc">This is the time you've been waiting for. Saving
                                                for. Training for. Join the Citro interest list to get the latest news
                                                first.</p>
                                                {data.interest_form_agent && data.interest_form_dre_number ? [
                                                    <p className="newsletter__agent newsletter__message">
                                                        <strong>{data.interest_form_agent}</strong>
                                                        <span>{data.interest_form_dre_number}</span>
                                                    </p>  
                                                ]:['']}
                                        {this.state.agent_info.agent_contact_number &&
                                            <p className="newsletter__message">
                                                <strong>PHONE :</strong> {this.state.agent_info.agent_contact_number}
                                            </p>
                                        }
                                            <div className="marketo_form">
                                                <form id="mktoForm_5235" className="altis-form"></form>

                                                {this.state.reload === false ? (

                                                    <small className="newsletter__disclaimer" >By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.
                                                    </small>

                                                ) : ('')}

                                            </div>
                                            {this.state.reload === true ? (

                                                <small className="thankyou_message">
                                                    Thanks for signing up to hear all the latest on Citro as it happens. Together we’re creating a new kind of community, so stay tuned!.
                                                </small>
                                            ) : ('')}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
            </section>
            ]:['']} 
            <AltisJoinForm reload={this.state.reload } formdetails={data} communityOfInterest={this.state.agent_info.community_of_interest} referenceId={this.state.agent_info.community_id} agent_info={this.state.agent_info}/>
          
        </React.Fragment>
        )
    }
}

export default Newsletter
