import React from 'react'
import {toVisualHtml} from 'components/Classes/Helpers.jsx'
import EventRsvp from 'components/Classes/EventRsvp.jsx';
import PostDetailSlider from 'components/Classes/PostDetailSlider.jsx'
class EventDetailContent extends React.Component {


    constructor(props){

        super(props)

        console.log("Props for Event detail");
        console.log(props);

        this.state ={

            isResidentQuery:false

        }

       

    }

    componentDidMount(){

        $('.EventDetail__hero_slider').each(function(index,value){
            $(value).find('.slick-arrow').wrapAll('<div class="slider_navigation"></div>');
        });

        
    }

    
    render() {

        const {event} = this.props;

        var isResidentQuery = false;

        let queryString = location.search;

        if(queryString != ""){


            if(queryString.indexOf("resident=true") > -1){

                isResidentQuery = true;
            }

        }


        return (
            <section className="event-detail-content">
                <div className="wrap wrap--event-detail-content">
                    <div className="event-detail-content-columns">
                        <div className="event-detail-content-column event-detail-content-column--main">
                            <article className="event-detail-content__article">
                                <h2 className="event-detail-content__title">{event.post_title}</h2>
                                {event.startDate ? (

                                        <h2 className="event-detail-content__date"><strong>{event.startDate.monthFull+" "+event.startDate.day+", "+event.startDate.year}</strong></h2>

                                ): ('')}

                                <PostDetailSlider gallery = {event.event_metas.event_gallery}  showDefaultImage={false} />
                                <p  dangerouslySetInnerHTML={toVisualHtml(event.post_content)}></p>
                            </article>
                        </div>
                    
                            {  ((event.event_metas.rsvp_options && event.event_metas.rsvp_options[0].show_rsvp && event.event_metas.event_type=="public") || (event.event_metas.rsvp_options && event.event_metas.rsvp_options[0].show_rsvp && event.event_metas.event_type=="resident" && isResidentQuery)) ? (

                                <EventRsvp event={event} />

                            ) : (
                                   (event.event_metas.rsvp_options && event.event_metas.rsvp_options[0].show_event_closed_messsage == true && event.event_metas.rsvp_options[0].show_rsvp != true && event.event_metas.rsvp_options[0].event_closed_messsage.length > 0) ? (
                                    <div className="event-detail-content-column event-detail-content-column--form">
                                          <p className="rsvp-event__closed-message" dangerouslySetInnerHTML={toVisualHtml(event.event_metas.rsvp_options[0].event_closed_messsage)}>
                                          </p>

                                    </div>
                                   ) :('')

                            )}

                    </div>
                </div>
            </section>
        )
    }
}

export default EventDetailContent
