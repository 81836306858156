import React from 'react'
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format'
class PlansDetailOptions extends React.Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)

    }

    handleClick(e) {
        e.preventDefault()
    }


    render() {

        const neighbourhood = this.props.neighbourhood
        const nextPlanPost = this.props.nextPlanPost
        return (
            <aside className="plans-detail-options">
                <div className="wrap wrap--plans-detail-options">
                    <ul className="plans-detail-options__list inner_wrap">
                        <li className="plans-detail-options__item">
                            <Link to={"/collection/" + neighbourhood.post_name} className="plans-detail-options__link">
                <span className="plans-detail-options-overlay plans-detail-options-overlay--content">
                  <span className="plans-detail-options-info">
                    <h3 className="plans-detail-options__title">{neighbourhood.post_title}</h3>
                    <p className="plans-detail-options__description">Go back</p>
                    <i className="plans-detail-options__icon icon--arrow-left"></i>
                  </span>
                </span>
                            </Link>
                        </li>

                        {nextPlanPost.ID ? [
                            <li className="plans-detail-options__item">
                                <Link to={"/collection/"+nextPlanPost.neighbourhood.post_name+"/"+nextPlanPost.post_name} className="plans-detail-options__link">
                <span
                    className="plans-detail-options__image"
                    style={{backgroundImage: 'url(' + neighbourhood.post_thumbnail + ')'}}
                ></span>
                                    <span className="plans-detail-options-overlay plans-detail-options-overlay--image">
                  <span className="plans-detail-options-info">
                    <h3 className="plans-detail-options__title">{nextPlanPost.neighbourhood.post_title} {nextPlanPost.post_title}</h3>
                    {nextPlanPost.base_price || nextPlanPost.pricing_prefixes == "Pricing...We're working on it." ? (
                    <p className="plans-detail-options__description">{nextPlanPost.pricing_prefixes != "" && nextPlanPost.pricing_prefixes != "None" ? nextPlanPost.pricing_prefixes : ''} {nextPlanPost.base_price && nextPlanPost.pricing_prefixes != "Pricing...We're working on it." ? <NumberFormat value={nextPlanPost.base_price.slice(0,-3)} displayType={'text'} thousandSeparator={false} prefix={'$'} suffix={'s'} /> : ''}</p>
                    ):('') }
                <i className="plans-detail-options__icon icon--arrow-right"></i>
                  </span>
                </span>
                                </Link>
                            </li>

                        ] : ['']}

                    </ul>
                </div>
            </aside>
        )
    }
}

export default PlansDetailOptions
