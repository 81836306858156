import React from 'react'
import { withRouter } from 'react-router-dom'

class LifestyleGoBack extends React.Component {
  constructor (props) {
    super(props)
    this.handleGoBack = this.handleGoBack.bind(this)
  }

  handleGoBack () {
    this.props.history.goBack()
  }

  render () {
    return (
      <section className="lifestyle-back">
        <div className="wrap wrap--lifestyle-back">
          <div className="lifestyle-back-holder">
            <button type="button" onClick={this.handleGoBack} className="lifestyle-back__button">Back</button>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(LifestyleGoBack)
