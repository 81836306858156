import React from 'react'
import {Link} from 'react-router-dom' // eslint-disable-line no-unused-vars
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'
import $ from 'jquery'
import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class Collections extends React.Component {


    componentDidMount(){

        $('.featured-collections-column').each(function(index,value){
            $(value).find('.slick-arrow').wrapAll('<div class="slider_navigation"></div>');
        });

        
    }
    render() {
        const settings = {
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        }
        if(this.props.collection_bg){
            var background_image = {backgroundImage:'url('+this.props.collection_bg.url+')',}
        }
        var card_color = this.props.neighborhood_card_color;
        return (
            <section className="featured-collections">
                <div className="wrap--featured-collections">
                    <div className="featured-collections-holder" style={background_image}>
                        <header className="featured-collections-header">
                            <h2 className="featured-collections-header__title">Citro Home Collections</h2>
                            {/* <Link to="/collections" className="featured-collections-header__link">View</Link> */}
                        </header>

                        <div className="featured-collections-columns">

                            {this.props.neighbourhoods.length > 0 ? [

                                this.props.neighbourhoods.map((neighbourhood, index) => (

                                    <div className="featured-collections-column">
                                        <Slider {...settings}>

                                            {neighbourhood.gallery_images.length > 0 ? [

                                                neighbourhood.gallery_images.map((gallery, i) => (

                                                    <div>
                                                        <div className="featured-collections-container">
                                                        { gallery.image != false ? [
                                                            <Link to={"/collection/" + neighbourhood.post_name}
                                                                 style={card_color[index] ?  { backgroundColor:card_color[index].card_bottom_color}:{}} className="featured-collections__link"
                                                                  draggable="true">
                                                                <img src={gallery.image.url}
                                                                     className="featured-collections__image"
                                                                     alt={neighbourhood.post_title}/>
                                                            </Link>
                                                            ]:['']
                                                        }
                                                                <h3 className="featured-collections__title">{neighbourhood.post_title}</h3>
                                                                <p className="neigh_status">{neighbourhood.neighborhood_status}</p>
                                                            <div className="featured-collections-info-box">
                                                                <div className="featured-collections-info">
                                                                    <ul className="featured-collections-info__list">
                                                                    {neighbourhood.min_bed != '' || neighbourhood.max_bed != "" ? [
                                                                            <li className="featured-collections-info__item">
                                                                                {neighbourhood.min_bed > 1 || neighbourhood.max_bed  > 1 ? ( 
                                                                                    <p className="featured-collections-info__title">Beds</p>
                                                                                ):(
                                                                                    <p className="featured-collections-info__title">Bed</p>
                                                                                )}
                                                                                {neighbourhood.min_bed == neighbourhood.max_bed ? [

                                                                                    <p className="featured-collections-info__info">{neighbourhood.min_bed}</p>

                                                                                ] : [

                                                                                    <p className="featured-collections-info__info">
                                                                                        <span>{neighbourhood.min_bed}</span>{neighbourhood.min_bed && neighbourhood.max_bed  ? ("-"):('')}<span>{neighbourhood.max_bed  ? (neighbourhood.max_bed ) : ('')}</span>
                                                                                    </p>
                                                                                ]}
                                                                                
                                                                            </li>
                                                                        ] : ['']}
                                                                        
                                                                        {neighbourhood.min_bath != '' || neighbourhood.max_bath != "" ? [
                                                                            <li className="featured-collections-info__item">
                                                                                {neighbourhood.max_bath > 1 || neighbourhood.max_bath  > 1 ? ( 
                                                                                    <p className="featured-collections-info__title">Baths</p>
                                                                                ):(
                                                                                    <p className="featured-collections-info__title">Bath</p>
                                                                                )}
                                                                                {neighbourhood.min_bath == neighbourhood.max_bath ? [
                                                                                    <p className="featured-collections-info__info">{neighbourhood.min_bath}</p>
                                                                                ] : [
                                                                                    <p className="featured-collections-info__info">  
                                                                                    <span>{neighbourhood.min_bath}</span>{neighbourhood.min_bath && neighbourhood.max_bath   ? ("-") :('')}
                                                                                    <span>{neighbourhood.max_bath  ? ( neighbourhood.max_bath ) : ('')}</span></p>
                                                                                ]}
                                                                            </li>
                                                                        ] : ['']}

                                                                        {neighbourhood.min_sqft != '' || neighbourhood.max_sqft != "" ? [

                                                                            <li className="featured-collections-info__item">
                                                                                <p className="featured-collections-info__title">Sq
                                                                                    Ft</p>
                                                                                <p className="featured-collections-info__info"><NumberFormat value={neighbourhood.min_sqft} displayType={'text'} thousandSeparator={true} />{neighbourhood.min_sqft && neighbourhood.max_sqft   ? ( "-") :('')}<NumberFormat value={neighbourhood.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                                                                            </li>

                                                                        ] : ['']}
                                                                        {(neighbourhood.pricing_prefixes != 'None' && neighbourhood.display_price) || neighbourhood.pricing_prefixes == "Pricing...We're working on it."  ? [

                                                                            <li className="featured-collections-info__item">
                                                                                <p className="featured-collections-info__title">{(neighbourhood.pricing_prefixes != 'None' ? neighbourhood.pricing_prefixes : '')}</p>
                                                                                <p className="featured-collections-info__info"><span>{neighbourhood.display_price && neighbourhood.pricing_prefixes != "Pricing...We're working on it." ? neighbourhood.display_price : ''}</span></p>
                                                                            </li>

                                                                        ] : ['']}

                                                                    </ul>
                                                                    {/* <p className="feature-collections-info-price">
                                                    
                                                                    {neighbourhood.display_price != '' ? [
                                                                        <span >{(neighbourhood.pricing_prefixes != 'None' ? neighbourhood.pricing_prefixes : '')} </span>,
                                                                        <span>{neighbourhood.display_price}</span>
                                                                

                                                                    ] : ('')}
                                                                </p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                ))


                                            ] : [<div>
                                                <div className="featured-collections-container">
                                                    <Link to={"/collection/" + neighbourhood.post_name}
                                                          style={card_color[index] ?  { backgroundColor:card_color[index].card_bottom_color}:{}} className="featured-collections__link" draggable="true">
                                                        <img
                                                            src={neighbourhood.post_thumbnail ? neighbourhood.post_thumbnail : require('media/default-160x307.png')}
                                                            className="featured-collections__image"
                                                            alt={neighbourhood.post_title}/>
                                                    </Link>
                                                    <h3 className="featured-collections__title">{neighbourhood.post_title}</h3>
                                                    <p className="neigh_status">{neighbourhood.neighborhood_status}</p>
                                                    <div className="featured-collections-info-box">
                                                    <div className="featured-collections-info">
                                                        <ul className="featured-collections-info__list">
                                                            {neighbourhood.min_bed != '' || neighbourhood.max_bed != "" ? [
                                                                <li className="featured-collections-info__item">
                                                                 {neighbourhood.min_bed > 1 || neighbourhood.max_bed  > 1 ? ( 
                                                                    <p className="featured-collections-info__title">Beds</p>
                                                                ):(
                                                                    <p className="featured-collections-info__title">Bed</p>
                                                                )}
                                                                    {neighbourhood.min_bed == neighbourhood.max_bed ? [

                                                                    <p className="featured-collections-info__info">{neighbourhood.min_bed}</p>

                                                                    ] : [

                                                                        <p className="featured-collections-info__info"> <span>{neighbourhood.min_bed}</span>{neighbourhood.min_bed && neighbourhood.max_bed  ? ("-"):('')}<span>{neighbourhood.max_bed  ? (neighbourhood.max_bed ) : ('')}</span></p>

                                                                        ]}
                                                                </li>
                                                            ] : ['']}
                                                            {neighbourhood.min_bath != '' || neighbourhood.max_bath != "" ? [
                                                                <li className="featured-collections-info__item">
                                                                {neighbourhood.min_bath > 1 || neighbourhood.max_bath  > 1 ? ( 
                                                                    <p className="featured-collections-info__title">Baths</p>
                                                                ):(
                                                                    <p className="featured-collections-info__title">Bath</p>
                                                                )}
                                                                    {neighbourhood.min_bath == neighbourhood.max_bath ? [
                                                                        <p className="featured-collections-info__info">{neighbourhood.min_bath}</p>
                                                                    ] : [
                                                                        <p className="featured-collections-info__info">  <span>{neighbourhood.min_bath}</span>{neighbourhood.min_bath && neighbourhood.max_bath   ? ("-") :('')}
                                                                        <span>{neighbourhood.max_bath  ? ( neighbourhood.max_bath ) : ('')}</span></p>
                                                                    ]}
                                                                    
                                                                </li>
                                                            ] : ['']}

                                                            {neighbourhood.min_sqft != '' || neighbourhood.max_sqft != "" ? [

                                                                <li className="featured-collections-info__item">
                                                                    <p className="featured-collections-info__title">Sq
                                                                        Ft</p>
                                                                    <p className="featured-collections-info__info"><NumberFormat value={neighbourhood.min_sqft} displayType={'text'} thousandSeparator={true} />
                                                                    {neighbourhood.min_sqft != '' && neighbourhood.max_sqft != "" ? ('-'):('')}
                                                                    <NumberFormat value={neighbourhood.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                                                                </li>

                                                            ] : ['']}

                                                            { ( neighbourhood.pricing_prefixes != 'None' && neighbourhood.display_price ) || neighbourhood.pricing_prefixes == "Pricing...We're working on it." ? [

                                                                <li className="featured-collections-info__item">
                                                                    <p className="featured-collections-info__title">{(neighbourhood.pricing_prefixes != 'None' ? neighbourhood.pricing_prefixes : '')}</p>
                                                                    <p className="featured-collections-info__info"><span>{neighbourhood.display_price && neighbourhood.pricing_prefixes != "Pricing...We're working on it." ? neighbourhood.display_price : ''}</span></p>
                                                                </li>

                                                            ] : ['']}

                                                            {/* {neighbourhood.display_price != '' ? (
                                                                <li className="featured-collections-info__item">
                                                                    <p className="featured-collections-info__title">{neighbourhood.pricing_prefixes != 'None' ? neighbourhood.pricing_prefixes : ''}</p>
                                                                    <p className="featured-collections-info__info"><NumberFormat value={neighbourhood.display_price.slice(0,-3)} displayType={'text'} thousandSeparator={false} prefix={'$'} suffix={'s'} /></p>
                                                                </li>

                                                            ) : ('')} */}
                                                        </ul>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>]}


                                        </Slider>
                                    </div>


                                ))


                            ] : ['']}


                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Collections
