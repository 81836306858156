import React from 'react'

class VirtualList extends React.Component {

    componentDidMount() {
        if(screen.width > 480){
            var card_section1 = [];
            var card_section2 = [];
            
            $(document).find(".virtual_box").each(function() {
                card_section1.push($(this).find(".virtual_title").outerHeight());
                card_section2.push($(this).find(".virtual_btn").outerHeight());
            });
            
            var card_maxheight1 = Math.max.apply(Math, card_section1);
            var card_maxheight2 = Math.max.apply(Math, card_section2);
            $(document).find(".virtual_title").css("min-height", card_maxheight1);
            $(document).find(".virtual_btn").css("min-height", card_maxheight2);
        }
        }

  render () {
 
    const virtual_tours = this.props.virtual_tours;

    return (
      <section className="virtual">
        <h2 className="hide--visually">Virtual Tours</h2>

        <div className="wrap wrap--locations">
            <div className="wrap-inner">
                    <div className="virtual_wrapper">

                        {virtual_tours.map((vtour) => (
                            <div className="virtual_box">
                                {vtour.post_title != '' &&  vtour.post_title != null  ? [
                                    <h2 className="virtual_title">{vtour.post_title}</h2>
                                ]:['']
                                }
                                <div className="virtual_image">
                                    <img src={vtour.video_image.url ? vtour.video_image.url : require('media/default-160x307.png')} />
                                    {
                                        vtour.asset_url != "" && vtour.asset_url != null ? [

                                            vtour.asset_type == 'video' ? [
                                            
                                            vtour.asset_url.includes('vidyard') ? [

                                                <a href={vtour.asset_url} data-type="iframe" data-fancybox data-iframe='{"preload":false}'
                                                data-fancybox-group="collections-video" className="virtual_video__link">
                                                    <i className="icon icon--play">
                                                        <span className="hide--visually">Play Video</span>
                                                    </i>
                                                </a>

                                            ] : [

                                                <a href={vtour.asset_url} data-fancybox data-fancybox-group="collections-video"
                                                className="virtual_video__link">
                                                    <i className="icon icon--play">
                                                        <span className="hide--visually">Play Video</span>
                                                    </i>
                                                </a>
                                            ]

                                        ] : [
                                            <a href={vtour.asset_url} className="virtual_video__link" target="_blank">
                                                <img src={require('images/360_virtual_tour.png')} className="virtual_icon_btn"/>
                                            </a>
                                        ]


                                        ] : ['']
                                    }
                                </div>
                                { vtour.detail_btn_url != "" && vtour.detail_btn_url != null ? [
                                    <div className="virtual_btn">
                                        <a href={vtour.detail_btn_url}>Details</a>
                                    </div> 
                                ]:[''] }   
                            </div>
                        ))}
                    </div>
            </div>
        </div>
      </section>
    )
  }
}

export default VirtualList
