import React from 'react'
import {Link as ScrollLink} from 'react-scroll' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import NumberFormat from 'react-number-format'

import '/node_modules/slick-carousel/slick/slick.scss'
import '/node_modules/slick-carousel/slick/slick-theme.scss'

class CollectionsDetailSlider extends React.Component {
    direction_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='drivingDirections') {
            window.dataLayer.push({'event':'drivingDirections'});
        }
     }
    render() {
        const id = this.props.id
        const images = [
            {image: require('media/location-1.jpg')},
            {image: require('media/location-2.jpg')},
            {image: require('media/location-3.jpg')},
            {image: require('media/location-4.jpg')},
            {image: require('media/location-5.jpg')},
            {image: require('media/location-6.jpg')}
        ]
        const name = this.props.name
        const settings = {
            arrows: true,
            dots: false,
            draggable: false,
            fade: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        const neighborhood_details = this.props.post;
     
        return (
            <section className="collections-detail-slider">
                <Slider {...settings}>

                    {this.props.gallery.length > 0 ? [

                        this.props.gallery.map((g) => (


                            <div>
                                <div className="collections-detail-slider-holder">
                              
                                    <img src={g.image.url}
                                         className="collections-detail-slider__image"
                                         alt={name}/>
                                    <span className="collections-detail-slider-overlay">
                                        <h3 className="collections-detail-slider__title">{name}</h3>
                                    </span>
                                    {g.image_caption && <div className="collections-caption"><p>{g.image_caption}</p></div>}
                                </div>
                            </div>


                        ))


                    ] : [


                        <div>
                            <div className="collections-detail-slider-holder">
                                <img
                                    src={this.props.post_thumbnail ? this.props.post_thumbnail : require('media/default-699x447.png')}
                                    className="collections-detail-slider__image"
                                    alt={name}/>
                                <span className="collections-detail-slider-overlay">
                <h3 className="collections-detail-slider__title">{name}</h3>
              </span>
                            </div>
                        </div>


                    ]}


                </Slider>
                {/* 5959 */}
                <div className="collections-detail-address-info">
                    <ul>
                        {this.props.post.physical_address ? [
                            <li class="address">
                            {this.props.post.physical_address_heading ?(
                            <strong>{this.props.post.physical_address_heading}</strong>
                            ):('')}
                            {this.props.post.physical_address  ?(
                                 <p>{this.props.post.physical_address}</p>
                            ):('')}
                           {this.props.post.time_hour !='' && this.props.post.time_hour !=null ?(
                                <p>{this.props.post.time_hour}</p> 
                            ):('')}
                            {this.props.post.show_appointment ?(
                                 <p>{this.props.post.show_appointment}</p>
                            ):('')}
                            {this.props.post.map_directions_link ?(
                                <a href={this.props.post.map_directions_link} target="_blank" className="driving-directions"  onClick={() => this.direction_data_layer_push('drivingDirections')}>Get Directions</a>
                            ):('')}
                            </li>
                        ]:['']}
                        
                       
                        {this.props.post.sales_address ? [
                             <li class="sales_address">
                                {this.props.post.sales_address_heading ?(
                                <strong>{this.props.post.sales_address_heading}</strong>
                                ):('')}
                                {this.props.post.sales_address ?(
                                    <p>{this.props.post.sales_address}</p>
                                ):('')}
                                {this.props.post.time_hour !='' && this.props.post.time_hour !=null ?(
                                    <p>{this.props.post.time_hour}</p> 
                                ):('')}
                                {this.props.post.custom_direction_link ?(
                                    <a href={this.props.post.custom_direction_link} target="_blank" className="driving-directions"  onClick={() => this.direction_data_layer_push('drivingDirections')}>Get Directions</a>
                                ):('')}
                            </li>
                        ]:['']}
                        
                    </ul>
                </div>
                <div className="collections-detail-slider-info">
                    <ul className="collections-detail-slider-info__list">
                        {neighborhood_details.min_bed != "" || neighborhood_details.max_bed != "" ? [

                            <li className="collections-detail-slider-info__item">
                             {neighborhood_details.min_bed > 1 || neighborhood_details.max_bed  > 1 ? (  
                                <p className="collections-detail-slider-info__title">Beds</p>
                            ):(
                                <p className="collections-detail-slider-info__title">Bed</p>
                            )}
                                {neighborhood_details.min_bed == neighborhood_details.max_bed ? [

                                    <p className="collections-detail-slider-info__info">{neighborhood_details.min_bed}</p>

                                ] : [

                                    <p className="collections-detail-slider-info__info">
                                        <span>{neighborhood_details.min_bed}</span>
                                        {neighborhood_details.min_bed && neighborhood_details.max_bed ? ("-") :('')}
                                        <span>{neighborhood_details.max_bed  ? ( neighborhood_details.max_bed ) : ('')}</span>
                                    </p>
                                ]}
                                
                            </li>

                        ] : ['']}

                        {neighborhood_details.min_bath != "" || neighborhood_details.max_bath != "" ? [

                            <li className="collections-detail-slider-info__item">
                            {neighborhood_details.min_bath > 1 || neighborhood_details.max_bath  > 1 ? (   
                                <p className="collections-detail-slider-info__title">Baths</p>
                            ):(
                                <p className="collections-detail-slider-info__title">Bath</p>
                            )}
                                {neighborhood_details.min_bath == neighborhood_details.max_bath ? [

                                    <p className="collections-detail-slider-info__info">{neighborhood_details.min_bath}</p>
                                ] : [
                                    <p className="collections-detail-slider-info__info">  
                                        <span>{neighborhood_details.min_bath}</span>
                                        {neighborhood_details.min_bath && neighborhood_details.max_bath ? ("-") :('')}
                                        <span>{neighborhood_details.max_bath  ? ( neighborhood_details.max_bath ) : ('')}</span>
                                    </p>
                                ]}
                                
                            </li>
                        ] : ['']}

                        {neighborhood_details.min_sqft != "" || neighborhood_details.max_sqft != "" ? [

                            <li className="collections-detail-slider-info__item">
                                <p className="collections-detail-slider-info__title">Sq Ft</p>
                                <p className="collections-detail-slider-info__info"><NumberFormat value={neighborhood_details.min_sqft} displayType={'text'} thousandSeparator={true} />{neighborhood_details.min_sqft && neighborhood_details.max_sqft ? ("-") :('')}<NumberFormat value={neighborhood_details.max_sqft} displayType={'text'} thousandSeparator={true} /></p>
                            </li>

                        ] : ['']}

                        {neighborhood_details.display_price != '' || neighborhood_details.pricing_prefixes == "Pricing...We're working on it." ? (
                            <li className="collections-detail-slider-info__item">
                                <p className="collections-detail-slider-info__title">{neighborhood_details.pricing_prefixes != 'None' ? neighborhood_details.pricing_prefixes : ''}</p>
                                <p className="collections-detail-slider-info__info">{neighborhood_details.display_price && neighborhood_details.pricing_prefixes != "Pricing...We're working on it." ? neighborhood_details.display_price : ''}</p>
                            </li>
                        ) : ('')}
                    </ul>
              {neighborhood_details.plans.length > 0 && 
                    <ScrollLink className="collections-detail-slider__link" to="plans"
                                duration={750}
                                offset={-60}
                                smooth={true}
                                spy={false}
                    >View Floor Plans</ScrollLink>
              }
                </div>
            </section>
        )
    }
}

CollectionsDetailSlider.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
}

export default CollectionsDetailSlider
