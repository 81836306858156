import React from 'react'
import { getBreakpoint } from 'components/Classes/Helpers.jsx'
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react' // eslint-disable-line no-unused-vars
import active from 'images/altis-map.png'
import marker from 'images/map-marker.png'
import {toVisualHtml} from 'components/Classes/Helpers.jsx'

export class ContactMapContainer extends React.Component {
  constructor (props) {
    super(props)

    console.log("Locators Map Props");
    console.log(props);

  
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this)
    this.onMapClicked = this.onMapClicked.bind(this)
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      initialCenter:{
        lat: Array.isArray(this.props.locators.locator_results) && this.props.locators.locator_results[0].latitude != "" ? this.props.locators.locator_results[0].latitude : '33.953222',
        lng: Array.isArray(this.props.locators.locator_results) && this.props.locators.locator_results[0].longitude != "" ? this.props.locators.locator_results[0].longitude : '-116.948808'
        
      }
    }
  }

  componentWillReceiveProps(nextProps){


    this.setState({
      showingInfoWindow: false
    })

  }

  onMarkerClick (props, marker) {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
  }

  onInfoWindowClose () {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    })
  }

  onMapClicked () {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      })
    
    }
  }

  render () {

    const initialCenter = {
      lat: Array.isArray(this.props.locators.locator_results) && this.props.locators.locator_results[0].latitude != "" ? this.props.locators.locator_results[0].latitude : '33.953222',
      lng: Array.isArray(this.props.locators.locator_results) && this.props.locators.locator_results[0].longitude != "" ? this.props.locators.locator_results[0].longitude : '-116.948808'
      
    }
    const locators = this.props.locators
    const iconHeight = getBreakpoint() < 1400 ? 29 : 58
    const iconWidth = getBreakpoint() < 1400 ? 21 : 41
    let icon = {
      url: '/wp-json/altis/v1/assets/map-icon',
      anchor: new google.maps.Point(iconWidth / 2, iconHeight / 2),
      scaledSize: new google.maps.Size(iconWidth, iconHeight)
    }
    const markerSize = getBreakpoint() < 1400 ? 70 : 140
    const zoom = getBreakpoint() < 1400 ? 15 : 16

    return (
      <Map
        className={'map'}
        google={this.props.google}
        onClick={this.onMapClicked}
        initialCenter={initialCenter}
        center={initialCenter}
        zoom={10}
      >

      {Array.isArray(locators.locator_results) ? [

        locators.locator_results.map((result,index)=>(

          result.latitude != "" &&  result.latitude != null ? [


            <Marker
              address={<p dangerouslySetInnerHTML={toVisualHtml(result.address)}></p>}
              icon={{url:'/wp-json/altis/v1/assets/map-icon?number='+(index+1), anchor: new google.maps.Point(iconWidth / 2, iconHeight / 2),scaledSize: new google.maps.Size(iconWidth, iconHeight) }}
              name={result.name}
              onClick={this.onMarkerClick}
              position={{ lat: result.latitude, lng: result.longitude }}
              title={result.name}
              
              />


          ] : ['']

              
          
        ))
                


      ] : ['']}
       

        

        <Marker
          icon={{
            url: active,
            anchor: new google.maps.Point(48,69),
            scaledSize: new google.maps.Size(48,69)
          }}
          position={{ lat: 33.9514037, lng: -116.9479285 }}  
        />

        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}>
          <div className="maps-info-window">
            <h2>{this.state.selectedPlace.name}</h2>
            <address>{this.state.selectedPlace.address}</address>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC-ILs_PxdVHTRQqgudhC8lydTXH_5PyGQ'
})(ContactMapContainer)
