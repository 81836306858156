import React from 'react'
import PropTypes from 'prop-types'

class SplitMantle extends React.Component {
  render () {
    const images = this.props.images
    const message = this.props.message
    const title = this.props.title

    return (
      <section className="split-mantle">
        <div className="wrap--split-mantle">
          <div className="split-mantle-holder">
            <ul className="split-mantle__list">
              <li className="split-mantle__item split-mantle__item--1">
                <img src={images[0].image} className="split-mantle__image" alt="" />
              </li>
              <li className="split-mantle__item split-mantle__item--2">
                <img src={images[1].image} className="split-mantle__image" alt="" />
              </li>
            </ul>

            <div className="split-mantle-info">
              <div className="split-mantle-info-inner">
                {title && title.length &&
                  <h2 className="title title--split-mantle">{title}</h2>
                }
                {message &&
                  <div className="split-mantle__message">
                      <p>{message}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

SplitMantle.propTypes = {
  images: PropTypes.array.isRequired,
  message: PropTypes.object,
  title: PropTypes.string
}

export default SplitMantle
