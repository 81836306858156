import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import BasicMantle from 'components/Classes/BasicMantle.jsx' // eslint-disable-line no-unused-vars
import ContactDirections from 'components/Classes/ContactDirections.jsx' // eslint-disable-line no-unused-vars
import ContactForm from 'components/Classes/ContactForm.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Contact extends React.Component {

    constructor() {
        super()
        this.state = {
            page: {},
            success: false,
            loaded:false
        }
    }

    componentDidMount() {
        fetch(apiConfig().API_URL + "contact_info/")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        page: result.data,
                        success: true,
                        loaded:true
                    });

                    console.log("Contact APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

           CallTrk.swap();
    }

    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                <Helmet>
                    <title>{this.state.success ? this.state.page.post_title : ''}</title>
                    <body className="contact"/>
                </Helmet>

                <h2 className="hide--visually">Contact</h2>

                {
                    this.state.success ? [
                        // <Overlaychat />,
                        
                        <BasicMantle
                            image={this.state.page.image}
                            message={<p>{this.state.page.page_meta.contact_details[0].banner_heading}</p>}
                            title="Contact"
                        />,
                        <ContactForm page={this.state.page}/>,
                        <ContactDirections page={this.state.page}/>
                       
                    ] : ('')
                }


            </div>
        )
    }
}

export default Contact
