import React from 'react'
import $ from 'jquery'
import AltisChat from 'components/Classes/AltisChat.jsx';
import {withRouter} from 'react-router-dom'
class AltisJoinForm extends React.Component {

    constructor(props) {

        super(props)
        this.state = {

            reload: props.reload,
            formshow: false,
           // form_reload:"12121"
            // referenceId: this.props.referenceId ? this.props.referenceId : false

        }

    }

    // joinreloadMarketoForm() {

    //     $("#form-box-div #mktoForm_3655").find('[type="submit"]').prop('disabled', false);
    //     $("#form-box-div #mktoForm_3655").find('[type="submit"]').text("SUBMIT >");
    //     // this.setState({reload: false});
    //     // this.loadMarketoForm();
    // }

    // joinloadMarketoForm() {
    //     var that = this;

    //     if (MktoForms2 != "undefined") {

    //         MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 3655, function (form) {

    //             form.onSuccess(function () {
    //                 dataLayer.push({'event':'interestList'});
    //                 if ($("#openQuestion1").is(":checked")) {

    //                     let t = new Date();
    //                     t.setDate(t.getDate() + 2);
    //                     let month = "0" + (t.getUTCMonth() + 1);
    //                     let date = "0" + t.getUTCDate();
    //                     month = month.slice(-2);
    //                     date = date.slice(-2);
    //                     date = t.getUTCFullYear() + "-" + month + "-" + date;
    //                     let todayToDate = Date.parse(date);


    //                     if ($("#requestedDate").val() == "") {

    //                         alert("Please select request date.");
    //                         that.joinreloadMarketoForm();
    //                         return false;
    //                     } else if (Date.parse($("#requestedDate").val()) <= todayToDate) {

    //                         alert("Please select a date at least three days from today. Call to schedule earlier.");
    //                         that.joinreloadMarketoForm();
    //                         return false;

    //                     } else {

    //                         $("#form-box-div #mktoForm_3655").html("");
    //                         that.setState({reload: true});
    //                         return false;
    //                     }


    //                 } else {

    //                     $("#form-box-div #mktoForm_3655").html("");
    //                     that.setState({reload: true});
    //                     return false;
    //                 }


    //             });

    //             $('#Brand__c').on('input', function(evt) {

    //                 console.log("input");

    //                 var inputValue = $("#Brand__c").val();
    //                 inputValue = inputValue.replace(/[^a-zA-Z ]/g, "");
    //                 $("#Brand__c").val(inputValue);
                    
    //                 var withoutSpclChar = new RegExp(/^[\w| |_-]+$/);
    //                 if (!inputValue.match(withoutSpclChar)) {
    //                 var newInputWithHas = $("#Brand__c").val();
    //                 var newInputWithoutHas = newInputWithHas.substring(0, newInputWithHas.length - 1);
    //                 return $("#Brand__c").val(newInputWithoutHas);
    //                 }
    //                 if (inputValue.match(/\s+/g)) {
    //                   $("#Brand__c").val(spaceHandle(inputValue))
    //                 }
                    
    //                 if ((inputValue.match(new RegExp(" ", "g")) || []).length > 1) {
    //                 console.log();
    //                 $("#Brand__c").val(spaceHandle(inputValue))
    //                 var newInputValue = $("#Brand__c").val();
    //                 //var noDoubleSpace = newInputValue.replace(/ +/g, '');
    //                 //var NoSpaceinEnd = noDoubleSpace.replace(/ $/, "");
    //                 return $("#Brand__c").val(newInputValue);
    //                 }
    //                 if (/\d/.test(inputValue)) {
    //                 console.log("it has number");
    //                 var noNumber = inputValue.replace(/[0-9]/g, '');
    //                 return $("#Brand__c").val(noNumber);
    //                 }
    //                 });
                    
    //                 function spaceHandle(s) {
    //                 console.log(s);
    //                 var replaced = s.split(' ');
    //                 console.log("Replaced------------>");
    //                 console.log(replaced);
    //                 var final_string = '';
    //                 $.each(replaced, function(index, value) {
    //                     console.log("value length------------>");
    //                     console.log(value.length);
    //                 if (index == 0 && value.length > 1) {
    //                     final_string = final_string + value + ' ';
    //                 } else {
    //                      final_string = final_string + value;
    //                 }
    //                 })
    //                 console.log("Final String");
    //                 console.log(final_string);
                    
    //                 return final_string;
    //                 }
                    
    //                 $('.mktoFormCol').addClass(function(i){
    //                 return 'Col'+(i+1);
    //                 });
    //                 form.onValidate(function() {
    //                 var vals = form.vals();
    //                 var email = form.vals().Email;
    //                 var fname = form.vals().FirstName;
    //                 var lname = form.vals().LastName;
    //                 var phone = form.vals().Phone;
    //                 var maxLength = 2;
    //                 var ContactName = form.vals().Brand__c;
    //                 var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@test.", "@rediff."];
    //                 var ContactFirstName = ContactName.substr(0, ContactName.indexOf(' '));
    //                 var ContactLastName = ContactName.substr(ContactName.indexOf(' ') + 1); 
    //                 if (/\w+\s+\w+/.test($("#Brand__c").val())) {
    //                 // Enable submission for those who met the criteria
    //                 $('input[name="FirstName"]').val(ContactFirstName);
    //                 $('input[name="LastName"]').val(ContactLastName); 
                    
    //                 var lname = $("input[name='LastName']");
    //                 var lvalue = lname[0].value.length;
    //                 if(lvalue < maxLength){
    //                 form.submittable(false);
    //                 var CcontactNameElem = form.getFormElem().find("#Brand__c");
    //                 form.showErrorMessage("Please enter at least 2 character of your last name", CcontactNameElem);
    //                 return false;
    //                 }else{
    //                 form.submittable(true);
    //                 }
    //                 } else {
    //                 form.submittable(false);
    //                 // Show error message, pointed at VehicleSize element
    //                 var CcontactNameElem = form.getFormElem().find("#Brand__c");
    //                 form.showErrorMessage("Please enter first and last name only.", CcontactNameElem);
    //                 return false;
    //                 }
    //                 });


    //         });

    //         MktoForms2.whenReady(function () {


    //             var j = 1;
    //             $('#form-box-div #mktoForm_3655 .mktoFormRow').each(function (i, e) {
    //                 $(this).addClass('row-class-' + j);
    //                 j++;
    //             });


    //             $("#form-box-div #mktoForm_3655").find('[name="Communities_of_Interest__c"]').val(that.props.communityOfInterest);
    //             $("#form-box-div #mktoForm_3655").find('[name="formURL"]').val(window.location.href);
    //             $("#form-box-div #mktoForm_3655").find('[name="Division__c"]').val("Tri Pointe Homes San Diego");
    //             $("#form-box-div #mktoForm_3655").find('[name="communityName"]').val(that.props.community_name);

    //             // $("#form-box-div #mktoForm_3655").find('.row-class-6').hide();

    //         });

    //     }

    // }

    componentDidMount() {
        

        // var that = this;

        // console.log("path::");
        // console.log(this.props.location.pathname);

        // if(this.props.location.pathname.indexOf("collection/") == -1 && this.props.location.pathname.indexOf("plan-") == -1 && this.props.location.pathname.indexOf("homesite-") == -1 ){
        //     this.joinloadMarketoForm();
           
        // }else if(screen.width <= 767){
        //     this.joinloadMarketoForm();
        // }
        

        this.setState({
            referenceId: this.props.referenceId
        });

        /*----SCRIPT for stay informed starts----*/

            if(screen.width < 767){
                //Stay Informed Mobile View
                $("#form-box-div").addClass("stay_informed_mobile");
                $("#form-box-div").removeClass("stay_informed_desktop");

                $("#form-box-div .signup_close").click(function(){
                    $("#form-box-div").hide();
                    $("body").removeClass("body_overflow");
                });
            }else{
                // Stay Informed Desktop View
                $("#form-box-div").addClass("stay_informed_desktop");
                $("#form-box-div").removeClass("stay_informed_mobile");
                $("#form-box-div").show();

                $("#form-box-div .signup_close").click(function(){
                    $("#form-box-div").removeClass("stay_informed_desktop_show");
                })
                if( $(".stay_informed_desktop").hasClass("stay_informed_desktop_show")){
                    $(document).on("click", function(event){
                        if(!$(event.target).closest(".new_stay_informed").length){
                        $("#form-box-div").removeClass("stay_informed_desktop_show");
                        }
                    });
                }
            }


            $(".join_btn_header").click(function(){
                $("#form-box-div").addClass("stay_informed_desktop_show");
            })

            if(screen.width <= 1024 && screen.width >= 768  ){
                $(".join_btn_header").click(function(){
                    $("body").css('-webkit-overflow-scrolling','touch');
                })
                $("#form-box-div .signup_close").click(function(){
                    $("body").css('-webkit-overflow-scrolling','unset');
                })
            }
            // $("#form-box-div .signup_close").click(function(){
                
            //     if($('.pop_up_btn_close').length > 0){

            //         $('.pop_up_btn_close').trigger('click');

            //          //this.setState({reload:true});

            //        // this.setState({reload:true});
            //     }
            // })

            // if(screen.width <= 1190 && screen.width >= 768  ){
            //     $(".join_btn_header").click(function(){
            //         $("body").css('-webkit-overflow-scrolling','touch');
            //     })
            //     $("#form-box-div .signup_close").click(function(){
            //         $("body").css('-webkit-overflow-scrolling','unset');
            //     })
            // }

            /*----SCRIPT for stay informed ends----*/





    }


    componentWillReceiveProps(nextProps) {


        // console.log("Recieve Porps WOrking");

        // var that = this;

       // console.log(nextProps.location.pathname);

      

    //    console.log("recieve props");
    //    console.log(nextProps);

    //    console.log('prevProps');
    //    console.log(this.props);
    //    if(nextProps.location.pathname.indexOf("collection/") == -1 && nextProps.location.pathname.indexOf("plan-") == -1 && nextProps.location.pathname.indexOf("homesite-") == -1 ){
    //         if(this.props.location.pathname != nextProps.location.pathname){
    //                  //this.setState({reload:true});
    //                 console.log("Join Props------------->");
    //                 console.log(this.state.reload);
                
    //                if($('#form-box-div form input').length < 1){

    //                       console.log("Changing state to true----------->");

    //                         this.setState({reload:true},function(){

    //                             console.log("Calling function again---------->");


    //                             that.joinloadMarketoForm();
 
    //                             that.setState({reload: false});

    //                         });
                    
    //                         // if (this.state.reload === true) {

    //                         //     console.log("------------>");
                               
    //                         // }
    //                }
 
    //                 this.setState({
    //                     referenceId: this.props.referenceId
    //                 });
    //         }  
           
    //     }
    //     else{   

    //         if( $(".pop_up_btn_open").attr("data-submit") == 'false' ){
    //             that.setState({reload:false});
    //         }
    //         else if( $(".pop_up_btn_open").attr("data-submit") == 'true' ){
    //             that.setState({reload:true});
    //         }
    //     }

    //     if(this.props.location.pathname != nextProps.location.pathname){
    //         if(this.props.location.pathname.indexOf("homesite") > -1 && nextProps.location.pathname.indexOf("collections") > -1){
    //                 location.replace("http://citroprod.wpengine.com/collections/?tab=feature_homes");
    //         }
    //         if(this.props.location.pathname.indexOf("homesite") > -1 && nextProps.location.pathname.indexOf("collection/") > -1){
    //                 location.replace("http://citroprod.wpengine.com/"+nextProps.location.pathname+"?tab=feature_homes");
    //             }
    //     }

    //     console.log("Previous page");
    //     console.log(this.props.location.pathname);

    //     console.log("Next page");
    //     console.log(nextProps.location.pathname);
   
    //     console.log("Form Reload Status");
    //     console.log(this.state.reload);

    //     console.log("************");
    this.setState({
        reload: nextProps.reload
    });

    if(this.props.location.pathname != nextProps.location.pathname){

            // if (this.state.reload === true) {
            //     //this.joinloadMarketoForm();
            //     this.setState({reload: false});
            // }

            this.setState({
                referenceId: this.props.referenceId
            });

    }
 
    }
    calltoaction_data_layer_push(param) {
        this.setState({ value:param })
        if(param && param==='clickToCall') {
            window.dataLayer.push({'event':'clickToCall'});
        }
     }

    render() {

        const agent_info = this.props.agent_info;
        const formdetails = this.props.formdetails;
        //const agent_dre = this.props.agent_dre


        // console.log(this.state.formshow)

        return (
            <section id="form-box-div">
               <div className="sidebar-signup new_stay_informed">
                    <div className="sidebar-signup-panel">
                        <div className="formHeader">
                            <p className="signup_close">X</p>
                            <div className="form_cover">
                                <img src={require('images/line-pattern.svg')} alt=""/>
                            </div>
                            {agent_info.agent_pic && 
                            <div className="agent_dp">
                                <img src={agent_info.agent_pic} alt="agent"/>
                            </div>
                            }
                            <div className="sidebar-signup-header-info1">
                                <p className="sidebar-signup__agent1">{agent_info.agent_name}</p>
                                <h2 className="title title--sidebar-signup1">{agent_info.agent_title}</h2>
                            </div>
                            <div className="sidebar-signup-contact1">
                                <ul className="sidebar-signup-contact__list1">
                                    <li className="sidebar-signup-contact__item1">
                                        <a href={"tel:"+agent_info.bre_number}
                                            className="sidebar-signup-contact__link1">{agent_info.bre_number}</a>
                                    </li>
                                    {agent_info.agent_contact_number && 
                                    <li className="sidebar-signup-contact__item1 sidebar-signup-contact__phone1">
                                        <a href={"tel:"+agent_info.agent_contact_number}
                                                className="sidebar-signup-contact__link1 sidebar-signup-contact__link--phone1" onClick={() => this.calltoaction_data_layer_push('clickToCall')}>{agent_info.agent_contact_number}</a>
                                    </li> }
                                </ul>
                            </div>
                        </div>
                        {agent_info.agent_name != '' && agent_info.agent_title != ''  ?  [

                            <div className="sidebar_agent">
                                <ul className="agent_details">
                                    <li className="sidebar_agent_name">{agent_info.agent_name}</li>
                                    {/* <li className="sidebar_agent_label sidebar_text">New Home Specialist</li> */}
                                    <li className="sidebar_agent_number sidebar_text">{agent_info.agent_title}</li>
                                </ul>
                            </div>

                        ]:['']}
                        <p className="sidebar-signup__title1">Keep Up with Citro</p>
                        <p className="sidebar-signup__info">This is the time you've been waiting for. Saving for. Training for. Join the Citro interest list to get the latest news first.</p>


                        <div className="nhs-form">
                                <form className="altis-nhs-form popup_form"></form>
                       
                        </div>

                        {this.state.reload === false ? (
                            <div className="sidebar-signup__disclaimer">By providing your name and contact information and clicking the submission button, you consent and agree to receive marketing communications from us or made on our behalf, including emails and calls or text messages using an automatic telephone dialing system or an artificial or prerecorded voice. Your consent and agreement to the foregoing, including the receipt of autodialed marketing messages, is not a condition of purchasing any property, goods, or services from us, and you may call us instead of submitting your information online. Your consent herein also applies to any future registration on national or state Do Not Call lists. For mobile phones, standard text message and data charges apply. Consult our <a href="https://www.tripointehomes.com/privacy-policy/" className="form_privacy" target="_blank">Privacy Policy</a> for additional information, including unsubscribe options.
                                <div class="sidebar_broker_agent">Broker or Agent? Stay <a href="https://www.tripointehomes.com/brokers/" target="_blank" >Informed Here</a></div>
                            </div>
                            
                        ) : ('')}

                        {this.state.reload === true ? (
                            <small className="thankyou_message">
                                Thanks for signing up to hear all the latest on Citro as it happens. Together we’re creating
                                a new kind of community, so stay tuned!
                            </small>
                        ) : ('')}
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(AltisJoinForm)
