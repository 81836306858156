import React from 'react'
import { Link } from 'react-router-dom' // eslint-disable-line no-unused-vars
import { Link as ScrollLink } from 'react-scroll' // eslint-disable-line no-unused-vars

class CollectionsPosts extends React.Component {
  render () {
    return (
      <section className="collections-posts">
        <div className="collections-posts-shim"></div>
        <h2 className="hide--visually">Featured Posts</h2>

        <div className="wrap wrap--collections-posts">
          <article className="collections-posts__article collections-posts__article--featured" itemScope itemType="http://schema.org/BlogPosting">
            <Link to="/blog/moving-checklist-and-timeline" className="collections-posts__link">
              <figure className="collections-posts__figure">
                <img src={require('media/collections-post-1.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                <figcaption className="collections-posts-overlay">
                  <span className="collections-posts-overlay-inner">
                    <small className="title title--collections-posts" itemProp="description">Culture</small>
                    <h3 className="collections-posts__title" itemProp="headline">Discovering Small Town Charm</h3>
                  </span>
                </figcaption>
              </figure>
            </Link>
          </article>

          <div className="collections-posts-columns">
            <div className="collections-posts-column">
              <article className="collections-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                <a className="collections-posts__link">
                  <figure className="collections-posts__figure">
                    <img src={require('media/collections-post-2.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                    <figcaption className="collections-posts-overlay"></figcaption>
                  </figure>
                </a>
              </article>

              <article className="collections-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                <Link to="/blog/moving-checklist-and-timeline" className="collections-posts__link">
                  <figure className="collections-posts__figure">
                    <img src={require('media/collections-post-3.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                    <figcaption className="collections-posts-overlay">
                      <span className="collections-posts-overlay-inner">
                        <small className="title title--collections-posts" itemProp="description">Culture</small>
                        <h3 className="collections-posts__title" itemProp="headline">Fits Your Lifestyle</h3>
                      </span>
                    </figcaption>
                  </figure>
                </Link>
              </article>

              <article className="collections-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                <a className="collections-posts__link">
                  <figure className="collections-posts__figure">
                    <img src={require('media/collections-post-4.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                    <figcaption className="collections-posts-overlay"></figcaption>
                  </figure>
                </a>
              </article>
            </div>

            <div className="collections-posts-column">
              <article className="collections-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                <a className="collections-posts__link">
                  <figure className="collections-posts__figure">
                    <img src={require('media/collections-post-5.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                    <figcaption className="collections-posts-overlay"></figcaption>
                  </figure>
                </a>
              </article>

              <article className="collections-posts__article" itemScope itemType="http://schema.org/BlogPosting">
                <a className="collections-posts__link">
                  <figure className="collections-posts__figure">
                    <img src={require('media/collections-post-6.jpg')} className="collections-posts__image" itemProp="image" alt="" />
                    <figcaption className="collections-posts-overlay"></figcaption>
                  </figure>
                </a>
              </article>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CollectionsPosts
