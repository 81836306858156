import React from 'react'
import PropTypes from 'prop-types'

class Altisappointment extends React.Component {

    constructor(){

        super();
        this.state = {

            show:true
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){

        this.setState({
            show:false
        });
    }

    render(){

        const message = this.props.message;

        return(
            this.props.show_covid == true ? [

                <div>
                    {this.state.show == true ? [

                    <div className="altis_appointment_container">
                        <div className="appointmentMessage">{message}</div>
                        <span className="close_altis_appointment" onClick={this.handleClick}>X</span>
                    </div>
                    ]: ['']}
                </div>

            ]:['']                
       )
    }

   
}

export default Altisappointment