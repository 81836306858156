import React from 'react'
import Slider from 'react-slick' // eslint-disable-line no-unused-vars
import fancybox from '@fancyapps/fancybox' // eslint-disable-line no-unused-vars
import {Element} from 'react-scroll' // eslint-disable-line no-unused-vars
import $ from 'jquery';
// import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
// import "lightgallery.js/dist/css/lightgallery.css";
import '/node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css'

class ScheduleTour extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            post: this.props.post
        }

    }
 
    TourloadMarketoForm() {

        var that = this;
        const zillow_hour = that.props.post.zillow_hour;
        const title = that.props.post.post_title;
        const map_directions_link = that.props.post.map_directions_link;
        const community_id = that.props.post.community_id;
        const crm_salesforce_id = that.props.communitySalesforceId;
        const post_type = that.props.post.post_type;
        const holidays = that.props.post.holidays;
        const ID =that.props.post.ID;

        if (MktoForms2 != "undefined") {
            MktoForms2.loadForm("//app-sj10.marketo.com", "167-BTV-942", 4381, function(form) {
                JQUERY('.headtxt').html(title);
                JQUERY('#tour_form .logo > a:first-child').attr({href:"https://citroliving.com/",title:"Citro", alt:"Citro"});
                JQUERY('#tour_form .logo img').attr({src:require('images/logo-top.png'),title:"Citro", alt:"Citro"});
           
                if(map_directions_link != ""){
                    JQUERY('#DriveDir a').attr("target","_blank");
                    JQUERY('#DriveDir a').attr("href",map_directions_link);
                }else{
                    JQUERY('#DriveDir').hide();
                }
                
                JQUERY('a#PrivacyLink').attr("href","https://www.tripointehomes.com/privacy-policy/");
                JQUERY('input[name="External_Lead_Id__c"]').val(community_id);
                
               if(post_type == 'collection'){
                setTimeout(function(){ JQUERY( "#mktoForm_4381 #requestedDate" ).trigger( "change" ); }, 10000);
                // JQUERY(document).on("change", "#mktoForm_4381 #requestedDate", function()
                JQUERY("#mktoForm_4381 #requestedDate").change(function(){
                    
                    if(holidays.length > 0){
                        JQUERY('#mktoForm_4381 #requestedDate').datepicker('option', 'beforeShowDay', function(date){
                            var string = JQUERY.datepicker.formatDate('dd/mm/yy', date);
                            if(holidays.indexOf(string) == -1){
                                return [true, ""];
                            }
                            else {
                                return [false, 'national_holiday_date'];
                            }
                        });
                    }

                    JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function() {
                        JQUERY(this).remove();
                    });
                    var addHour = ["10:00 am", "11:00 am" , "12:00 pm" , "01:00 pm", "02:00 pm", "03:00 pm", "04:00 pm", "05:00 pm"];
                    JQUERY.each(addHour, function(index, value){
                        JQUERY("#mktoForm_4381 #apptRequestedTime").append(JQUERY("<option>",{
                            value: value,
                            text: value
                        }));
                    });
                    var requestedDate = JQUERY('#mktoForm_4381 #requestedDate').val();
                    var data ="";
                    var data = {
                        action: 'get_shedule_hour',
                        requestedDate: requestedDate,
                        n_id :ID,
                        result : zillow_hour
                    };
                   
                    if(requestedDate !=''){
                        JQUERY.ajax({
                            type: 'POST',
                            data: data,
                            url: "/wp-admin/admin-ajax.php",
                            success:function(response){
                           
                                response = JSON.parse(response);

                                if(response.close_day == 'true'){
                                    if(JQUERY('#mktoForm_4381 .Row_5:has(p)')){
                                        JQUERY('#mktoForm_4381 .Row_5 p').remove();
                                    }
                                    JQUERY('#mktoForm_4381 .Row_5').append('<p class="red-error">Appointments are not available for this date.</p>');
                                    JQUERY('#mktoForm_4381 .Row_7 a').css('pointer-events','none');
                                }else{
                                    JQUERY('#mktoForm_4381 .Row_7 a').css('pointer-events','unset');
                                    JQUERY('#mktoForm_4381 .Row_5 p').remove();
                                }

                                var startTime = 0;
                                var option_length = JQUERY('#mktoForm_4381 #apptRequestedTime > option').length;
                                JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function() {
                                   
                                    if(JQUERY(this).val() !=response.start_time){
                                        JQUERY(this).remove();
                                        startTime++;
                                        if(startTime==option_length){
                                            JQUERY.each(addHour, function(index, value){
                                                JQUERY("#mktoForm_4381 #apptRequestedTime").append(JQUERY("<option>",{
                                                    value: value,
                                                    text: value
                                                }));
                                            });
                                            JQUERY("#mktoForm_4381 #apptRequestedTime").val('10:00 am');
                                        }
                                    }else{								
                                        JQUERY("#mktoForm_4381 #apptRequestedTime").val(response.start_time);
                                        return false;
                                    }
                                });
                                var endtime = false;var k =1;
                                JQUERY("#mktoForm_4381 #apptRequestedTime option").each(function() {
                                    if(JQUERY(this).val() ==response.end_time || endtime==true){
                                        endtime = true;
                                        k++;
                                        if(k!=2){JQUERY(this).remove();}
                                    }
                                });
                            }
                        });
                    }
                });
               }
              
            });
            
            MktoForms2.whenReady(function () {

                JQUERY("#mktoForm_4381").find('[name="Communities_of_Interest__c"]').val(crm_salesforce_id);
                JQUERY("#mktoForm_4381").find('[name="formURL"]').val(window.location.href);
                JQUERY("#mktoForm_4381").find('[name="Division__c"]').val("Tri Pointe Homes San Diego");
                JQUERY("#mktoForm_4381").find('[name="communityName"]').val(title);

            });
        }

    }

    componentDidMount() {

        this.TourloadMarketoForm();
 
    }
    componentWillUnmount(){         
        $('#ui-datepicker-div').remove();          
    }
    render() {
        const pattern_image = this.props.post.pattern_image
        const tour_post_thumbnail = this.props.post.tour_post_thumbnail;
      
        return (
            
            <div id="tour_form">
                    <div class="tourpop_form" >
                        <div class="form-column">
                            <form id="mktoForm_4381"></form>		
                        </div>	
                        <div class="form-image">
                            
                                {pattern_image != "" ? (
                                    <div class="two_image">
                                        <img src={pattern_image.url}/>	
                                    </div>
                                ):('')}
                               
                            <picture> <img src={tour_post_thumbnail}/></picture>
                        </div>	
                    </div>
            </div>
        )
    }
}

export default ScheduleTour