import React from 'react'
import {Helmet} from 'react-helmet' // eslint-disable-line no-unused-vars
import Info from 'components/Classes/Info.jsx'
import LifestyleAbout from 'components/Classes/LifestyleAbout.jsx' // eslint-disable-line no-unused-vars
import LifestylePosts from 'components/Classes/LifestylePosts.jsx' // eslint-disable-line no-unused-vars
import NavigationMantle from 'components/Classes/NavigationMantle.jsx' // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class Lifestyle extends React.Component {

    constructor() {
        super()
        this.state = {
            success: false,
            lifestyles: [],
            page: {},
            postTitle:'',
            lifestyle_link:'',
            loaded: false
        }
    }

    componentDidMount() {

        fetch(apiConfig().API_URL + "lifestyle_info")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        lifestyles: result.data.lifestyles,
                        postTitle:result.data.page.postTitle,
                        page: result.data.page,
                        success: result.success,
                        loaded: true

                    });

                    console.log("Lifestyle APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )

            CallTrk.swap();


    }


    componentDidUpdate(){

        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    <Helmet>
                        <title>{this.state.postTitle}</title>
                        <body className="lifestyle"/>
                    </Helmet>

                    {/* <Overlaychat /> */}
                    
                    {this.state.success ? [

                        <h2 className="hide--visually">{this.state.page.post_title}</h2>,

                        <NavigationMantle
                            active="left"
                            image={this.state.page.thumbnail_url ? this.state.page.thumbnail_url : ''}
                            message={this.state.page.header_message.message ? this.state.page.header_message.message : ''}
                            navigation={
                                [
                                    {url: '/lifestyles', label: 'Amenities',show:false},
                                    {url: '/lifestyles/interests', label: 'Enthusiast Groups',show:false}
                                ]
                            }
                            title={this.state.page.header_message.text ? this.state.page.header_message.text : ''}
                        />,
                            <Info
                                className="lifestyle-interests"
                                message={<p dangerouslySetInnerHTML={{__html:this.state.page.info_message.message.replace(/(?:\r\n|\r|\n)/g, '')}}></p>}
                                title={this.state.page.info_message.text}
                            />,
                        <LifestyleAbout about={this.state.lifestyles[0]} content_position={this.state.page.first_lifestyle_content} lifestyle_link={this.state.page.lifestyle_link}/>,
                        <LifestylePosts lifestyles={this.state.lifestyles} more={false} lifestyle_link={this.state.page.lifestyle_link}/>


                    ] : ('')}

            </div>
        )
    }
}

export default Lifestyle
