import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import BlogPosts from "../../Classes/BlogPosts"; // eslint-disable-line no-unused-vars
import {apiConfig} from 'components/Classes/Helpers.jsx'
import AltisLoader from 'components/Classes/AltisLoader.jsx';
import Overlaychat from 'components/Classes/Overlaychat.jsx';
class ArchiveDetail extends React.Component {

    constructor() {
        super()
        this.state = {
            posts: [],
            success: false,
            loaded: false
        }
    }

    componentDidMount() {

        fetch(apiConfig().API_URL + "archive_info/" + this.props.match.params.year + "/" + this.props.match.params.month)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({

                        posts: result.data,
                        success: true,
                        loaded: true
                    });

                    console.log("Blog APi Response");
                    console.log(result);

                },

                (error) => {

                }
            )


            CallTrk.swap();


    }


    componentDidUpdate(){
        
        CallTrk.swap();
    }

    render() {
        return (
            <div>
                {this.state.loaded == false ? <AltisLoader/> : ''}
                    <Helmet>
                        <title>Blog | Citro</title>
                        <body className="blog"/>
                    </Helmet>


                    {
                        (this.state.success && this.state.posts.length > 0) ? [

                            // <Overlaychat/>,

                            <BlogPosts relatedPosts={this.state.posts} related={true}/>

                         ] : ('')

                    }


            </div>
        )
    }

}

ArchiveDetail.propTypes = {
    related: PropTypes.bool
}

export default ArchiveDetail
