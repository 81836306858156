/* eslint-disable no-unused-vars */
import React from 'react'
import { fixTargets, htmlData } from './Classes/Helpers.jsx'
import Footer from './Footer/Footer.jsx'
import Header from './Header/Header.jsx'
import Main from './Main/Main.jsx'
import Newsletter from './Classes/Newsletter.jsx'
import AltisSupport from './Classes/AltisSupport.jsx'
import $ from 'jquery'
class App extends React.Component {

    constructor(props){

        super(props);
    }

  componentDidMount () {
    fixTargets()
    htmlData();

    CallTrk.swap();


    console.log(REACT_CONFIG);
    if(location.hash.indexOf("#newsletter") > -1){

        window.addEventListener('load',function(){

              var elmnt = document.getElementById("newsletter");
              setTimeout(function(){
                  elmnt.scrollIntoView();
              },2000);
            
          
        });
    }
	var hide_elementor_content = document.getElementsByClassName('hide_elementor_content');
	if (hide_elementor_content.length > 0) {
		if(window.location.href.indexOf('elementor-preview') < 0){
		  document.getElementById("elementor_div").style.display = "none";
		}else{
		  document.getElementById("elementor_div").style.display = "block";
		}
	}

  }


  componentDidUpdate(){

    CallTrk.swap();
	var hide_elementor_content = document.getElementsByClassName('hide_elementor_content');
	if (hide_elementor_content.length > 0) {
		if(window.location.href.indexOf('elementor-preview') < 0){
		  document.getElementById("elementor_div").style.display = "none";
		}else{
		  document.getElementById("elementor_div").style.display = "block";
		}
	}

   
    
  }



  render () {
    return (
      <div>
        <Header title="Citro" />
        <Main />
        <Newsletter />
        <Footer />

        {navigator.userAgent.search("Trident") >= 0 ? [
            <AltisSupport message="The Citro website is best viewed on Microsoft Edge, Firefox, Safari or Chrome."/>
        ] : ['']}
       
      </div>
    )
  }
}

export default App
