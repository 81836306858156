import React from 'react'
import PropTypes from 'prop-types'

class UpcomingEvents extends React.Component {
    render () {
      const message = this.props.message
      const title = this.props.title
  
      return (
          <section className="upcoming-events">
             <div className="wrap wrap-upcoming-event-post">
                    <div className="upcoming-event-title-wrapper">
                    {title && title.length &&
                    <h2 className="upcoming-event-title">{title}</h2>
                    }
                    </div>
                    
                    {message &&
                    <div className="upcoming-event-description">
                        {message}
                    </div>   
                    }
            </div>    
          </section>
      )
    }
    }

    UpcomingEvents.propTypes = {
        message: PropTypes.object,
        title: PropTypes.string
      }
      
      export default UpcomingEvents    