import React from 'react'
import { getBreakpoint } from 'components/Classes/Helpers.jsx'
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react' // eslint-disable-line no-unused-vars
import active from 'images/map-active.png'
import marker from 'images/map-marker.png'

export class ContactMapContainer extends React.Component {
  constructor (props) {
    super(props)
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this)
    this.onMapClicked = this.onMapClicked.bind(this)
    this.state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false
    }
  }

  onMarkerClick (props, marker) {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    })
  }

  onInfoWindowClose () {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    })
  }

  onMapClicked () {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      })
    }
  }

  render () {
    const iconHeight = getBreakpoint() < 1400 ? 29 : 58
    const iconWidth = getBreakpoint() < 1400 ? 21 : 41
    const icon = {
      url: marker,
      anchor: new google.maps.Point(iconWidth / 2, iconHeight / 2),
      scaledSize: new google.maps.Size(iconWidth, iconHeight)
    }
    const markerSize = getBreakpoint() < 1400 ? 70 : 140
    const zoom = getBreakpoint() < 1400 ? 15 : 16

    return (
      <Map
        className={'map'}
        google={this.props.google}
        initialCenter={{
          lat: 33.954006,
          lng: -116.954954
        }}
        onClick={this.onMapClicked}
        zoom={zoom}
      >
        <Marker
          address={<p>1643 Kendrick Dr,<br />Beaumont, CA<br />92223</p>}
          icon={icon}
          name="Abrio"
          onClick={this.onMarkerClick}
          position={{ lat: 33.95665, lng: -116.9599737 }}
          title={'Abrio'}
        />

        <Marker
          address={<p>1674 Ferron Lane<br />Beaumont, CA,<br />92223</p>}
          icon={icon}
          name="Flagstone"
          onClick={this.onMarkerClick}
          position={{ lat: 33.9569274, lng: -116.9514231 }}
          title={'Flagstone'}
        />

        <Marker
          address={<p>1661 Croton Street<br />Beaumont, CA,<br />92223</p>}
          icon={icon}
          name="Skycrest"
          onClick={this.onMarkerClick}
          position={{ lat: 33.9561287, lng: -116.9499282 }}
          title={'Skycrest'}
        />

        <Marker
          address={<p>1472 Marble Way<br />Beaumont, CA,<br />92223</p>}
          icon={icon}
          name="Elara"
          onClick={this.onMarkerClick}
          position={{ lat: 33.9514337, lng: -116.9587143 }}
          title={'Elara'}
        />

        <Marker
          icon={{
            url: active,
            anchor: new google.maps.Point(markerSize / 2, markerSize / 2),
            scaledSize: new google.maps.Size(markerSize, markerSize)
          }}
        />

        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}>
          <div className="maps-info-window">
            <h2>{this.state.selectedPlace.name}</h2>
            <address>{this.state.selectedPlace.address}</address>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC-ILs_PxdVHTRQqgudhC8lydTXH_5PyGQ'
})(ContactMapContainer)
